import React, {ReactNode, useEffect, useState} from 'react'
import {Box, Text} from "grommet";
import {RiskDirection} from "../types";
import {ImageContainer} from "./Icon";
import {ReactComponent as ArrowSmallImg} from '../assets/images/arrow_small.svg';
import styled from "styled-components";

export interface SwitchOption {
  id: string
  borderColor?: string
  text?: string | ReactNode
  description?: string | ReactNode
}

export interface SwitchProps {
  options: SwitchOption[]
  optionId?: string
  align?: string
  onChange?: (option: SwitchOption) => void
  height?: string
}

export const Switch = (props: SwitchProps) => {
  const {
    options,
    align = 'start',
    optionId,
    height = 'auto'
  } = props

  const [selectedOption, setSelectedOption] = useState(props.options.find(op => op.id === optionId) || props.options[0])

  useEffect(() => {
    if(optionId && optionId !== selectedOption.id) {
      const newOption = options.find(option => option.id === optionId)
      if(newOption) {
        setSelectedOption(newOption)
      }
    }
  }, [optionId]);

  const onOptionClicked = (option: SwitchOption) => {
    setSelectedOption(option)
    if(props.onChange) {
      props.onChange(option)
    }
  }

  return <Box>
    <Box direction={'row'} gap={'2px'} height={height}>
      {options.map((option, index) => {
        const isSelected = selectedOption.id === option.id
        const background = isSelected ? 'filterActiveBg' : 'unset'
        const color = isSelected
          ? 'accentLabel'
          : 'labelInactive'
        const borderColor = option.borderColor || 'transparent'

        return <Box
          key={index}
          background={background}
          border={{ color: borderColor }}
          round={'4px'}
          width={'50%'}
          pad={'6px 8px'}
          align={align}
          justify={'center'}
          onClick={() => onOptionClicked(option)}
        >
          {typeof option.text === 'string'
            ?
            <Text size={'16px'} weight={500} color={color}>
              {option.text}
            </Text>
            : option.text
          }
          {typeof option.description === 'string'
            ? <Text color={color}>{option.description}</Text>
            : option.description
          }
        </Box>
      })}
    </Box>
  </Box>
}


const RiskDirectionOptionContainer = styled(Box)<{ activeColor: string }>`
    //span {
    //    transition: color 250ms;
    //}

    &:hover {
        border-color: ${props => props.activeColor};
        
        span {
            color: ${props => props.activeColor};
        }

        svg {
            path {
                fill: ${props => props.activeColor}
            }
        }
    }
`

export const RiskDirectionSwitch = (props: SwitchProps) => {
  const { optionId, options} = props

  const selectedOption = options.find(option => option.id === optionId)
  const activeColor = selectedOption!.id === RiskDirection.payer ? 'ratePositive' : 'rateNegative'
  const activeColorCode = selectedOption!.id === RiskDirection.payer ? '#5ABF7D' : '#DD6E6E'

  const onOptionClicked = (option: SwitchOption) => {
    if(props.onChange) {
      props.onChange(option)
    }
  }

  return <Box>
    <Box direction={'row'} gap={'10px'}>
      {options.map((option, index) => {
        const isSelected = selectedOption!.id === option.id
        const itemColor = option.id === RiskDirection.payer ? '#5ABF7D' : '#DD6E6E'
        const background = isSelected ? 'filterActiveBg' : 'unset'
        const color = isSelected ? activeColor : 'labelInactive'
        const iconColor = isSelected ? activeColorCode : '#818298'
        const borderColor = isSelected ? activeColor : 'transparent'

        return <RiskDirectionOptionContainer
          key={index}
          activeColor={itemColor}
          background={background}
          border={{ color: borderColor }}
          round={'4px'}
          width={'50%'}
          pad={'6px 8px'}
          justify={'center'}
          onClick={() => onOptionClicked(option)}
        >
          <Box
            direction={'row'}
            align={'center'}
            gap={'4px'}
          >
            <Text color={color} size={'18px'} weight={500}>
              {option.id === RiskDirection.payer ? 'Long' : 'Short'}
            </Text>
            <Box>
              <ImageContainer color={iconColor}>
                <ArrowSmallImg
                  style={{
                    rotate: option.id === RiskDirection.receiver ? '90deg' : 'unset'
                }}
                />
              </ImageContainer>
            </Box>
          </Box>
          <Box margin={{ top: '4px' }}>
            <Text size={'13px'} color={color}>
              {option.description}
            </Text>
          </Box>
        </RiskDirectionOptionContainer>
      })}
    </Box>
  </Box>
}

export interface SwitchSmallProps {
  options: string[]
  defaultSelectedIndex: number
  onChange: (value: string, index: number) => void
}

export const SwitchSmall = (props: SwitchSmallProps) => {
  const { defaultSelectedIndex = 0 } = props
  const [selectedIndex, setSelectedIndex] = useState(defaultSelectedIndex)

  return <Box direction={'row'}>
    {props.options.map((item, index) => {
      const isSelected = selectedIndex === index
      return <Box
        key={index}
        margin={{ left: index === 1 ? '-2px': 'unset' }}
        pad={'4px 2px'}
        background={'#303340'}
        round={'3px'}
        border={{
          size: '1px',
          color: isSelected ? '#707ED6' : 'transparent'
      }}
        style={{ minWidth: '58px', textAlign: 'center', zIndex: isSelected ? 10 : 1 }}
        onClick={() => {
          setSelectedIndex(index)
          props.onChange(item, index)
        }}
      >
        <Text color={isSelected ? 'white' : '#999DB3'} size={'12px'}>
          {item}
        </Text>
      </Box>
    })}
  </Box>
}

export interface HeaderSwitchOption {
  id: string
  text: string
}

const HeaderSwitchText = styled(Text)`
    font-weight: 500;
    &:hover {
        color: ${props => props.theme.global.colors.accentWhite2}
    }
`

export const HeaderSwitch = (props: {
  height?: string
  size?: string
  options: HeaderSwitchOption[]
  selectedOptionId: string
  onChange: (type: string) => void
}) => {
  const { height = '24px', size = '18px' } = props

  return <Box direction={'row'} gap={'24px'}>
    {props.options.map((option) => {
      const isActive = props.selectedOptionId === option.id

      return <Box
        key={option.id}
        style={{ position: 'relative' }}
        onClick={() => props.onChange(option.id)}
      >
        {isActive && <Box
          height={'3px'}
          width={'100%'}
          background={{ color: 'brandRho' }}
          style={{
            position: 'absolute',
            top: `-${height}`
          }} />
        }
        <HeaderSwitchText
          size={size}
          color={isActive ? 'accentWhite2' : 'textSecondary'}
        >
          {option.text}
        </HeaderSwitchText>
      </Box>
    })}
  </Box>
}
