import {useEffect, useMemo, useState} from "react";
import {FutureMarketStats, getFutureStats} from "../api/dataService";
import {MarketInfo} from "../types";

export interface FutureStats {
  futureId: string
  stats: FutureMarketStats
}

export const useFutureStats = (params: {
  markets: MarketInfo[]
}) => {
  const { markets } = params

  const [futuresStats, setFuturesStats] = useState<FutureStats[]>([])

  useEffect(() => {
    const loadData = async () => {
      try {
        const allFutures = markets.flatMap(market => market.futures)
        const data = await Promise.allSettled(allFutures.map(async (future) => {
          const stats = await getFutureStats({
            futureId: future.id,
            interval: '1d'
          })
          return {
            futureId: future.id,
            stats
          }
        }))
        const statsData = data.map(item => {
          if(item.status === 'fulfilled') {
            return {
              futureId: item.value.futureId,
              stats: item.value.stats
            }
          }
          return null
        }).filter(item => item !== null)
        setFuturesStats(statsData as FutureStats[])
        console.log('useFutureStats: data updated', statsData)
      } catch (e) {
        console.error('Cannot load future stats', e)
      }
    }
    loadData()
  }, [markets.length]);

  return {
    futuresStats
  }
}
