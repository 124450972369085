import {WidgetContainer} from "../../components";
import styled from "styled-components";
import React from "react";
import {Box, Text} from "grommet";
import {VaultInfo} from "../../types";
import {VaultPortfolio} from "../../api/vault-api";

export interface VaultProps {
  vaults: VaultInfo[]
  vault?: VaultInfo
  vaultPortfolio?: VaultPortfolio
  isVaultLoading: boolean
  refetchVaultBalance: () => void
}

export const EarnWidget = styled(WidgetContainer)`
    padding: 24px;
`

type TableRowItem = string | React.ReactNode

const RowContainer = styled(Box)`
    &:hover {
        background: #2E2E38;
    }
`

export const TableRow = (props: {
  items: TableRowItem[]
}) => {
  const { items } = props

  return <RowContainer
    direction={'row'}
    justify={'between'}
    align={'center'}
    pad={'8px 24px'}
  >
    {items.map((item, index) => {
      return <Box key={index}>
        {
          typeof item === 'string'
            ? <Text size={'14px'} color={'accentWhite'}>{item}</Text>
            : item
        }
      </Box>
    })}
  </RowContainer>
}
