import {BigNumber} from "ethers";

export const enrichResponse = (data: any): any => {
  if(typeof data === 'bigint') {
    return BigNumber.from(data.toString())
  } else if(Array.isArray(data)) {
    return data.map(enrichResponse)
  }

  const items = Object.entries(data)
  for(let i = 0; i < items.length; i++) {
    const item = items[i]
    const [key, value] = item

    if(typeof value === 'bigint') {
      data[key] = BigNumber.from(value.toString())
    } else if(Array.isArray(value)) {
      data[key] = value.map(enrichResponse)
    } else if(typeof value === 'object') {
      data[key] = enrichResponse(value)
    }
  }
  return data
}
