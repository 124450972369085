import React from 'react'
import {Box, Text} from "grommet";
import styled from "styled-components";
import {NavLink, useLocation} from "react-router-dom";

interface MenuItemProps {
  children: React.ReactNode
  route: string
}

const MenuItemContainer = styled(Box)<{ isActive?: boolean; isEnabled?: boolean; }>`
  position: relative;
  padding: 23px 0;
  color: white;

  &:hover .menu-item-line {
      width: 100%;
  }
`

const MenuItemLine = styled(Box)<{ isActive: boolean }>`
    position: absolute;
    height: 3px;
    bottom: 0;
    transition: width 0.35s;
    width: ${(props) => props.isActive ? '100%' : '0'};
`

const MenuItem = (props: MenuItemProps) => {
  const { route } = props
  const { pathname } = useLocation()
  const activeRoute = pathname === '/' ? '/trade' : pathname
  const isActive = !!route && activeRoute.startsWith(route)

  return <NavLink to={props.route}>
    <MenuItemContainer>
      <Text weight={500} size={'12px'} color={isActive ? 'textHeader' : 'textSecondary'}>
        {props.children}
      </Text>
      <MenuItemLine className={'menu-item-line'} isActive={isActive} background={'#4852FF'} />
    </MenuItemContainer>
  </NavLink>
}

export const HeaderMenu = () => {
  return <Box direction={'row'} gap={'50px'}>
    {/*<MenuItem route={'/markets'}>MARKETS</MenuItem>*/}
    <MenuItem route={'/trade'}>TRADE</MenuItem>
    <MenuItem route={'/portfolio'}>PORTFOLIO</MenuItem>
    <MenuItem route={'/history'}>HISTORY</MenuItem>
    {/*<MenuItem route={'/earn'}>EARN</MenuItem>*/}
    <MenuItem route={'/stats'}>STATS</MenuItem>
  </Box>
}
