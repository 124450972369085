import {prepareFormNumber} from "../../utils";
import {FormError, FormErrors} from "../../types";
import {MarginTransferParams} from "./index";

export const getFormError = (params: MarginTransferParams): FormError | null => {
  const {
    formValues,
    market,
    withdrawableMargin,
    marketId,
    destinationMarketId,
    destinationMarket
  } = params

  const formAmount = prepareFormNumber(formValues.amount, market.descriptor.underlyingDecimals)

  if(withdrawableMargin.sub(formAmount).isNegative()) {
    return {
      field: 'amount',
      text: 'Insufficient margin to withdraw',
      type: FormErrors.insufficientFunds
    }
  }

  if(destinationMarketId && marketId === destinationMarketId) {
    return {
      field: 'destinationMarket',
      text: 'Select different markets',
      type: FormErrors.wrongValue
    }
  }

  if(destinationMarketId && market.descriptor.underlying !== destinationMarket?.descriptor.underlying) {
    return {
      field: 'destinationMarket',
      text: 'Select markets with same underlying token',
      type: FormErrors.wrongValue
    }
  }

  return null
}
