import {ContractInterface, ethers} from "ethers";
import config from "../config";
import {Box, Text} from "grommet";
import { Typography } from 'antd'
import React from "react";
import RouterABI from '../abi/RouterABI.json'
import QuoteABI from '../abi/QuoterABI.json'
import styled from "styled-components";

const ErrorMessageContainer = styled(Text)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`

interface ExtendedABI extends Omit<ethers.utils.Fragment, 'type'> {
  type: ethers.utils.Fragment | 'error'
}

const parseErrorMessage = (error: Error | null, contractAddress?: string, abi?: ContractInterface) => {
  if(!error) {
    return ''
  }
  // Get error message from error signature
  // https://dev.to/george_k/embracing-custom-errors-in-solidity-55p8
  if(contractAddress && abi) {
    try {
      const contract = new ethers.Contract(contractAddress, abi);
      // @ts-ignore
      const reasonBytes = error.data;
      const errorSelector = ethers.utils.hexDataSlice(reasonBytes, 0, 4);

      const abiErrors = (abi as ExtendedABI[])
        .filter(item => item.type === 'error')

      const abiError = abiErrors.find((abiError: ExtendedABI) => {
        const methodInputs = (abiError.inputs || [])
          .map(input => input.type)
          .join(',')
        const methodName = `${abiError.name}(${methodInputs})` // Example: InsufficientLiquidity()
        const sig = contract.interface.getSighash(methodName)
        return sig === errorSelector
      })

      if(abiError && abiError.name) {
        return abiError.name
          .split(/(?=[A-Z])/) // split by capital letters
          .map(item => item.toLowerCase())
          .join(' ')
      }
    } catch (e) {}
  }

  try {
    // Try to get error from error message
    const [, possibleValue] = error.message.split('errorName="')
    if(possibleValue) {
      const methodName = possibleValue.substring(0, possibleValue.indexOf('"'))
      if(methodName) {
        return methodName
      }
    }
  } catch (e) {}

  try {
    const [errorMessage] = (error?.message || '').split(/[;[]/gi)
    return errorMessage || 'Unknown error'
  } catch (e) {}

  return ''
}

export interface BaseTxErrorProps {
  error: Error | null
  prefix?: string
}

export interface TransactionErrorProps extends BaseTxErrorProps {
  contractAddress?: string
  abi?: any
}

export const QuoteErrorMessage = (props: BaseTxErrorProps) => {
  return <TransactionErrorMessage
    {...props}
    contractAddress={config.quoterContractAddress}
    abi={QuoteABI}
  />
}

export const RouterErrorMessage = (props: BaseTxErrorProps) => {
  return <TransactionErrorMessage
    {...props}
    contractAddress={config.routerContractAddress}
    abi={RouterABI}
  />
}

export const TransactionErrorMessage = (props: TransactionErrorProps) => {
  const { prefix = '' } = props

  const errorMessage = parseErrorMessage(props.error, props.contractAddress, props.abi)

  return <Box justify={'center'} style={{ maxWidth: '310px', position: 'relative' }}>
    <Box style={{ position: 'absolute', left: '-24px', top: '2px' }}>
      <Typography.Text copyable={{ text: errorMessage }} />
    </Box>
    <ErrorMessageContainer color={'errorMessage'}>
      {prefix ? prefix + ': ' : ''}{errorMessage}
    </ErrorMessageContainer>
  </Box>
}
