import React from 'react'
import {Box, Text} from "grommet";
import {HalfPieChart} from "../charts/half-pie";
import {getIconByName, Number, QuestionMark, ValuesChangeBadge} from "../";
import tooltips from "../../utils/tooltips";
import {marginTotal} from "../../utils/mappers";
import {BigNumber} from "ethers";
import {Tooltip} from "react-tooltip";
import {useProtocolData} from "../../providers/ProtocolDataProvider";
import {abbreviateNumber, bnToDecimal, getLeverage} from "../../utils";

const zero = BigNumber.from(0)

export const MarketMargin = (props: {
  marketId?: string
  marginTo?: BigNumber,
  showLMR?: boolean
}) => {
  const { markets, portfolio } = useProtocolData()

  const market = markets.find(item => item.descriptor.id === props.marketId)
  const marketPortfolio = portfolio.find(item => item.descriptor.id === props.marketId)
  const underlyingDecimals = market?.descriptor.underlyingDecimals || 6
  const underlyingName = market?.descriptor.underlyingName

  const marginBN = marketPortfolio ? marginTotal(marketPortfolio.marginState.margin) : zero
  const margin = bnToDecimal(marginBN, underlyingDecimals)
  const marginResulted = bnToDecimal(props.marginTo || marginBN, underlyingDecimals)
  const lt = bnToDecimal(marketPortfolio?.marginState.liquidationMarginThreshold || zero, underlyingDecimals)
  const imr = bnToDecimal(marketPortfolio?.marginState.initialMarginThreshold || zero, underlyingDecimals)
  const lmr = bnToDecimal(marketPortfolio?.marginState.lpMarginThreshold || zero, underlyingDecimals)

  const activeFutures = marketPortfolio?.futures.filter((future) => {
    return future.termStart.add(future.termLength).mul(1000).gt(BigNumber.from(Date.now()))
  }) || []

  const isNonEmptyProvisions = marketPortfolio?.futureMakerProvisions
      .find(f =>
          activeFutures.find(activeFuture => activeFuture.id === f.futureId) &&
          f.provisions.find(p => p.notional.total.gt(zero))
      ) !== undefined

  const showLMR = props.showLMR && isNonEmptyProvisions

  const { leveragePercentage} = getLeverage({
    market,
    marketPortfolio,
    margin: props.marginTo
  })

  const redStartValue = 0
  const redPercentage = marginResulted.gte(lt) ? 1 : marginResulted.div(lt).toNumber()
  const redEndValue = redStartValue + 19 * redPercentage

  const yellowStartValue = 25
  const yellowPercentage = marginResulted.gte(imr)
    ? 1
    : marginResulted.lt(lt)
      ? 0
      : (
        (marginResulted.sub(lt)).div(imr.sub(lt))
      ).toNumber()
  const yellowEndValue = yellowStartValue + (46 - yellowStartValue) * yellowPercentage

  const green1StartValue = 52
  const green1Percentage = showLMR && marginResulted.gte(lmr)
    ? 1
    : marginResulted.gte(imr) ? 1 - leveragePercentage * 0.9 : 0

  const green1EndValue = showLMR
      ? green1StartValue + (74 - green1StartValue) * green1Percentage
      : green1StartValue + (100 - green1StartValue) * green1Percentage

  const green2StartValue = 79
  const green2Percentage = marginResulted.gte(lmr) ? 1 - leveragePercentage * 0.9 : 0
  const green2EndValue = green2StartValue + (100 - green2StartValue) * green2Percentage

  const items = [
    {
      start: redStartValue,
      end: redEndValue,
      color: '#ED5F5F'
    },
    {
      start: yellowStartValue,
      end: yellowEndValue,
      color: '#C8AE51'
    },
    {
      start: green1StartValue,
      end: green1EndValue,
      color: showLMR ? '#78AC53' : '#3CB465'
    }
  ]

  if (showLMR) {
    items.push({
      start: green2StartValue,
      end: green2EndValue,
      color: '#4DB26F'
    })
  }

  let color = 'text'
  if(margin.eq(0)) {
    color = 'text'
  } else if(margin.lt(lt)) {
    color = 'rateNegative'
  } else if(margin.lt(imr)) {
    color = '#C8AE51'
  } else {
    color = 'ratePositive'
  }

  const { icon: iconInfo } = getIconByName('info', '10px')

  const tooltipsDecimalPlaces = underlyingName?.includes('WETH') ? 5 : 2

  return <Box style={{ position: 'relative', width: '250px' }}>
      <HalfPieChart items={items} />
      <Box
        width={'100%'}
        height={'100%'}
        align={'center'}
        justify={'center'}
        pad={{ top: '48px' }}
        gap={'2px'}
        style={{
          position: 'absolute'
        }}
      >
        <Box direction={'row'} align={'center'} gap={'2px'}>
          <Text size={'13px'} color={'textSecondary'}>Current margin</Text>
          <QuestionMark tooltipId={'bottom_current_margin'} tooltipText={tooltips.currentMargin} />
        </Box>
        {props.marginTo && props.marginTo.gt(0)
          ? <ValuesChangeBadge
            from={marginBN}
            to={props.marginTo}
            decimals={underlyingDecimals}
            fontSize={'18px'}
            arrowIconSize={'11px'}
          />
          : <Text color={color}>
            <Number
              value={marginBN}
              fontSize={'20px'}
              decimals={underlyingDecimals}
              decimalPlaces={2}
              name={underlyingName}
            />
          </Text>
        }
        <Text size={'13px'}>{underlyingName}</Text>
      </Box>
    <Box
        data-tooltip-id={'margin_management_lt'}
        data-tooltip-content={tooltips.liquidationThreshold}
        style={{
          position: 'absolute',
          bottom: '64px',
          left: '40px',
          zIndex: 1,
        }}
    >
      <Box direction={'row'} align={'center'} gap={'2px'} style={{ transform: 'rotate(-51deg)' }}>
        <Text size={'13px'} color={'textSecondary'}>LT</Text>
        <Box>
          {iconInfo}
        </Box>
      </Box>
      <Tooltip
          id={'margin_management_lt'}
          place={'bottom-end'}
          border={'1px solid #454A55'}
          opacity={1}
          render={({ content }) => {
            return <Box gap={'8px'}>
              <Text>Liquidation Threshold: {abbreviateNumber(lt.toString(), tooltipsDecimalPlaces)} {underlyingName}</Text>
              <Text>{content}</Text>
            </Box>
          }}
          style={{
            minWidth: '280px',
            fontSize: '14px',
            fontWeight: 'normal',
            textAlign: 'left',
          }}
      />
    </Box>
    <Box
        data-tooltip-id={'margin_management_imr'}
        data-tooltip-content={tooltips.initialMargin}
        style={{
          position: 'absolute',
          top: '18px',
          left: '110px',
          zIndex: 1,
        }}
    >
      <Box direction={'row'} align={'center'} gap={'2px'}>
        <Text size={'13px'} color={'textSecondary'}>IMR</Text>
        <Box>
          {iconInfo}
        </Box>
      </Box>
      <Tooltip
          id={'margin_management_imr'}
          place={'bottom-start'}
          border={'1px solid #454A55'}
          opacity={1}
          render={({ content }) => {
            return <Box gap={'8px'}>
              <Text>Initial Margin Requirement: {abbreviateNumber(imr.toString(), tooltipsDecimalPlaces)} {underlyingName}</Text>
              <Text>{content}</Text>
            </Box>
          }}
          style={{
            minWidth: '300px',
            fontSize: '14px',
            fontWeight: 'normal',
            textAlign: 'left'
          }}
      />
    </Box>
    {showLMR &&
        <Box
            data-tooltip-id={'margin_management_lmr'}
            data-tooltip-content={tooltips.liquidityMargin}
            style={{
              position: 'absolute',
              top: '50px',
              right: '34px',
            }}
        >
          <Box direction={'row'} align={'center'} gap={'2px'}  style={{ transform: 'rotate(49deg)' }}>
            <Text size={'13px'} color={'textSecondary'}>LMR</Text>
            <Box>
              {iconInfo}
            </Box>
          </Box>
          <Tooltip
              id={'margin_management_lmr'}
              place={'bottom-start'}
              border={'1px solid #454A55'}
              opacity={1}
              render={({ content }) => {
                return <Box gap={'8px'}>
                  <Text>Liquidity Margin Requirement: {abbreviateNumber(lmr.toString(), tooltipsDecimalPlaces)} {underlyingName}</Text>
                  <Text>{content}</Text>
                </Box>
              }}
              style={{
                minWidth: '300px',
                fontSize: '14px',
                fontWeight: 'normal',
                textAlign: 'left',
              }}
          />
        </Box>
    }
    </Box>
}
