import moment from "moment/moment";
import {getFutureRates} from "../../../api/dataService";
import {DatafeedConfiguration, PeriodParams} from "../../../charting_library";
import {SupportedResolutions} from "./constants";
import {getFloatingRates} from "../../../api/oracleService";

export interface DatafeedSymbolInfo {
  symbol: string;
  full_name: string;
  description?: string;
  exchange?: string;
  ticker: string;
  type?: string;
}

const configurationData: DatafeedConfiguration = {
  // Represents the resolutions for bars supported by your datafeed
  supported_resolutions: SupportedResolutions,
  // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
  exchanges: [
    { value: 'Rho', name: 'Rho', desc: 'Rho'},
  ],
  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  symbols_types: [
    { name: 'crypto', value: 'crypto'}
  ]
};

export interface DatafeedParams {
  marketId: string
  futureId: string
  timestampFrom: number
  symbols: DatafeedSymbolInfo[]
}

const subscribeIntervals: Record<string, NodeJS.Timeout> = {}
let isSubscribeRequestRunning = false

const getFutureRateBars = async (
  from: number,
  to: number,
  futureId: string,
  interval: string
) => {
  const params = {
    futureId,
    from,
    to,
    interval
  }
  // console.log('[getFutureRateBars] request params:', params)
  const candlesData = await getFutureRates(params)

  return candlesData.filter(item => {
    return item.open !== null && item.close !== null
  }).map(item => {
    return {
      close: +(item.close / 10 ** 16).toFixed(4),
      high: +(item.high / 10 ** 16).toFixed(4),
      low: +(item.low / 10 ** 16).toFixed(4),
      open: +(item.open / 10 ** 16).toFixed(4),
      time: moment(item.timestamp).unix() * 1000
    }
  })
}

const getFloatingRateBars = async (
  marketId: string,
  from: number,
  to: number,
  interval: string
) => {
  const barsRaw = await getFloatingRates({
    marketId,
    from,
    to,
    // @ts-ignore
    interval
  })
  return barsRaw.map((item) => {
    return {
      close: (+item.close) / 10**16,
      high: +item.high / 10**16,
      low: +item.low / 10**16,
      open: +item.open / 10**16,
      time: +item.timestamp * 1000
    }
  })
}

export const CreateDatafeed = (params: DatafeedParams) => {
  const { marketId, futureId, timestampFrom, symbols } = params

  const getAllSymbols = () => {
    return symbols
  }

  return {
    onReady: (callback: (options: {}) => void) => {
      console.log('[onReady]: Method call');
      setTimeout(() => callback(configurationData));
    },
    searchSymbols: async (
      userInput: string,
      exchange: string,
      symbolType: string,
      onResultReadyCallback: (
        options: DatafeedSymbolInfo[]
      ) => void
    ) => {
      console.log('[searchSymbols]: Method call');
      const symbols = getAllSymbols();
      const newSymbols = symbols.filter(symbol => {
        const isExchangeValid = exchange === '' || symbol.exchange === exchange;
        const isFullSymbolContainsInput = symbol.full_name
          .toLowerCase()
          .indexOf(userInput.toLowerCase()) !== -1;
        return isExchangeValid && isFullSymbolContainsInput;
      });
      onResultReadyCallback(newSymbols);
    },
    resolveSymbol: async (
      symbolName: string,
      onSymbolResolvedCallback: (symbolInfo: ISymbolInfo) => void,
      onResolveErrorCallback: (error: any) => void,
      extension: string
    ) => {
      console.log('[resolveSymbol]: Method call', symbolName);
      const symbols = getAllSymbols();
      const symbolItem = symbols.find(({ full_name }) => full_name === symbolName);
      if (!symbolItem) {
        console.log('[resolveSymbol]: cannot resolve symbol:', symbolName, 'all symbols:', symbols);
        onResolveErrorCallback('Cannot resolve symbol');
        return;
      }
      // Symbol information object
      const symbolInfo = {
        ticker: symbolItem.full_name,
        name: symbolItem.symbol,
        description: symbolItem.description,
        type: symbolItem.type,
        session: '24x7',
        timezone: 'Etc/UTC',
        exchange: symbolItem.exchange,
        minmov: 1,
        pricescale: 100,
        has_intraday: true,
        visible_plots_set: 'ohlc',
        has_weekly_and_monthly: true,
        supported_resolutions: configurationData.supported_resolutions,
        volume_precision: 2,
        data_status: 'streaming',
      };
      console.log('[resolveSymbol]: Symbol resolved', symbolName);
      // @ts-ignore
      onSymbolResolvedCallback(symbolInfo);
    },
    getBars: async (
      symbolInfo: ISymbolInfo,
      resolution: string,
      periodParams: PeriodParams,
      onHistoryCallback: (bars: IBar[], options: { noData?: boolean }) => void,
      onErrorCallback: (error: string) => void
    ) => {
      const { to } = periodParams;
      console.log('[getBars]: Method call', symbolInfo, 'resolution:', resolution, 'periodParams:', periodParams);

      let bars: IBar[] = []

      if(timestampFrom < to) {
        try {
          const from = Math.max(periodParams.from, timestampFrom)
          const interval = Number(resolution) ? `${Number(resolution) / 60}h` : resolution.toLowerCase()

          if(symbolInfo.name.toLowerCase().includes('rate')) {
            bars = await getFloatingRateBars(
              marketId,
              from,
              to,
              interval
            )
          } else {
            bars = await getFutureRateBars(from, to, futureId, interval)
          }
        } catch (e) {
          console.log('[getBars]: historical data error:', e);
          onErrorCallback('Unable to load historical data');
          return false
        }
      }

      console.log(`[getBars]: returned ${bars.length} bar(s)`, bars);
      onHistoryCallback(bars, { noData: bars.length === 0 });
    },
    subscribeBars: async (
      symbolInfo: ISymbolInfo,
      resolution: string,
      onRealtimeCallback: (ohlc: IBar) => void,
      subscriberUID: string,
      onResetCacheNeededCallback: () => void
    ) => {
      console.log('[subscribeBars]: symbolInfo', symbolInfo);

      const runSubscribeUpdate = async () => {
        try {
          let bars: IBar[] = []
          const params = {
            futureId,
            from: Math.round((Date.now() - 60_000) / 1000),
            to: Math.round(Date.now() / 1000),
            interval: Number(resolution) ? `${Number(resolution) / 60}h` : resolution.toLowerCase()
          }
          isSubscribeRequestRunning = true
          if(symbolInfo.name.toLowerCase().includes('rate')) {
            bars = await getFloatingRateBars(marketId, params.from, params.to, params.interval)
          } else {
            bars = await getFutureRateBars(params.from, params.to, futureId, params.interval)
          }
          // console.log('[subscribeBars] bars result: ', bars)
          bars.forEach((bar) => {
            onRealtimeCallback(bar);
          })
        } catch (error) {
          console.log('[subscribeBars]: Failed to fetch:', error);
        } finally {
          isSubscribeRequestRunning = false
        }
      }

      subscribeIntervals[subscriberUID] = setInterval(() => {
        if(!isSubscribeRequestRunning) {
          // console.log('runSubscribeUpdate: update')
          runSubscribeUpdate()
        } else {
          // console.log('runSubscribeUpdate: already running')
        }
      }, 5 * 1000)
    },
    unsubscribeBars: (subscriberUID: string) => {
      console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
      const timerId = subscribeIntervals[subscriberUID]
      if(timerId) {
        clearInterval(timerId)
        console.log('[unsubscribeBars]: Clear interval', subscriberUID);
      }
    },
  };
}
