import React from 'react'
import {Box, Text} from "grommet";
import {Button} from "antd";
import {useNavigate} from "react-router-dom";

export const PageNotFound = () => {
  const navigate = useNavigate()

  return <Box margin={{ top: '48px' }}>
    <Box align={'center'} gap={'16px'}>
      <Text size={'72px'}>404</Text>
      <Text size={'xxlarge'}>Page Not Found</Text>
    </Box>
    <Box margin={{ top: '48px' }}>
      <Button type={'primary'} size={'large'} onClick={() => {
        navigate('/trade')
      }}>
        Go to Trade
      </Button>
    </Box>
  </Box>
}
