import {Box, Table, TableBody, TableCell, TableRow, Text} from "grommet";
import {Switch, SwitchOption, SwitchSmall} from '../../components';
import {FormErrorMessage, NumberInput} from "../../components";
import React, {useState} from "react";
import styled from "styled-components";
import {LiquidityProvisionProps} from "./index";
import {Arrow, ValuesChangeBadge} from "../../components";
import {FormErrors, IntervalLiquidity, LiquidityOperation} from "../../types";
import {prepareFormNumber} from "../../utils";
import TreeView from "../../components/TreeView";
import bn from "bignumber.js";
import {fromBn} from "evm-bn";
import {BigNumber} from "ethers";
import {RatesInput} from "../../components";
import {QuestionMark} from "../../components";
import {LiquidityDistributionChart} from "../../components/charts/lp-distribution/LiquidityDistribution";
import {CoinName} from "../../components";
import {BalanceChange} from "../../components";
import {Select, Space} from "antd";
import {Number} from "../../components";
import tooltips from "../../utils/tooltips";

const TableContainer = styled(Table)`
  tr td:nth-child(2) {
    width: 50%;
  }
`

interface ProvisionsReportProps {
  from: BigNumber
  to: BigNumber
  fromDv01?: BigNumber | null
  toDv01?: BigNumber | null
  underlyingName: string
  underlyingDecimals: number
  height?: string
}

const ProvisionReportTableRow = (props: ProvisionsReportProps) => {
  const {
    from,
    to,
    fromDv01,
    toDv01,
    underlyingName,
    underlyingDecimals,
    height = '53px',
  } = props

  return <TableRow>
    <TableCell pad={'0x'} height={height}>
      <Box direction={'row'} justify={'end'} align={'center'} gap={'8px'}>
        <ValuesChangeBadge
          from={from}
          to={to}
          decimals={underlyingDecimals}
          name={underlyingName}
        />
        <CoinName name={underlyingName} />
      </Box>
      {(fromDv01 && toDv01) &&
          <Box style={{ position: 'relative' }}>
              <Box style={{ position: 'absolute', right: 0, top: '4px' }}>
                  <Box direction={'row'} gap={'6px'}>
                      <Box direction={'row'} align={'center'} gap={'2px'}>
                          <Text color={'textSecondary'} size={'12px'} weight={'bold'}>DV01</Text>
                          <QuestionMark tooltipId={'dv01_notional'} tooltipText={tooltips.dv01} size={'10px'} />
                      </Box>
                      <Text color={'textSecondary'}>
                          <ValuesChangeBadge
                              from={fromDv01}
                              to={toDv01}
                              decimals={underlyingDecimals}
                              fontSize={'12px'}
                          />
                      </Text>
                  </Box>
              </Box>
          </Box>
      }
    </TableCell>
  </TableRow>
}

export const Request = (props: LiquidityProvisionProps) => {
  const {
    futureId,
    market,
    marketPortfolio,
    currentSwap,
    formValues,
    formError,
    liquidityQuote,
    underlyingBalance,
    poolLiquidityDistribution,
    nativeTokenBalance,
    onChangeFormValue,
  } = props
  const { underlyingName, underlyingDecimals } = market.descriptor

  const zero = BigNumber.from(0)
  const nativeTokenName = 'ETH' // chain?.nativeCurrency.symbol
  const [selectedTokenName, setSelectedTokenName] = useState(underlyingName)
  const selectedTokenBalance = selectedTokenName === underlyingName ? underlyingBalance : nativeTokenBalance
  const [isUserLPShown, setUserLpShown] = useState(true)

  const switchOptions = [
    { id: LiquidityOperation.provide, text: LiquidityOperation.provide },
    { id: LiquidityOperation.remove, text: LiquidityOperation.remove }
  ]

  const onChange = (option: SwitchOption) => {
    props.onChangeFormValue({ 'operation': option.id})
  }

  let totalProvisionFrom = zero, totalProvisionTo = zero
  let receiverFrom = zero, receiverTo = zero
  let payerFrom = zero, payerTo = zero
  let payerDv01From = zero, payerDv01To = zero, receiverDv01From = zero, receiverDV01To = zero

  if(marketPortfolio) {
    payerDv01From = (marketPortfolio.futureMakerProvisions
      .filter(item => item.futureId === futureId)
      .reduce((acc, value) => {
        const total = value.provisions.reduce((provisionAcc, provision) => provisionAcc.add(provision.payerDv01), zero)
        return acc.add(total)
      }, zero)) || zero
    receiverDv01From = (marketPortfolio.futureMakerProvisions
      .filter(item => item.futureId === futureId)
      .reduce((acc, value) => {
        const total = value.provisions.reduce((provisionAcc, provision) => provisionAcc.add(provision.receiverDv01), zero)
        return acc.add(total)
      }, zero)) || zero
  }

  if (liquidityQuote) {
    const makerProvisions = marketPortfolio
      ? marketPortfolio.futureMakerProvisions
        .filter(pos => pos.futureId === futureId)
        .map(item => item.provisions)
        .flat()
      : []
    const makerTotal = makerProvisions.reduce((acc, provisionInfo) => acc.add(provisionInfo.notional.total), BigNumber.from(0))
    const makerReceiver = makerProvisions.reduce((acc, provisionInfo) => acc.add(provisionInfo.notional.receiver), BigNumber.from(0))
    const makerPayer = makerProvisions.reduce((acc, provisionInfo) => acc.add(provisionInfo.notional.payer), BigNumber.from(0))

    totalProvisionFrom = makerTotal
    receiverFrom = makerReceiver
    payerFrom = makerPayer

    totalProvisionTo = liquidityQuote.totalFutureProvisionNotional.total
    receiverTo =  liquidityQuote.totalFutureProvisionNotional.receiver
    payerTo = liquidityQuote.totalFutureProvisionNotional.payer
    receiverDV01To = liquidityQuote.totalFutureProvisionReceiverDv01
    payerDv01To = liquidityQuote.totalFutureProvisionPayerDv01

    console.log('makerProvisions', makerProvisions)
    console.log('payerFrom', payerFrom, payerFrom.toString())
    console.log('payerTo', payerTo, payerTo.toString())
  }

  const onAdditionalCollateralClicked = () => {
    if (formError && formError?.value) {
      const value = bn((formValues.collateral || '0').toString())
        .plus(fromBn(formError?.value, underlyingDecimals))
        .dp(2, bn.ROUND_UP)
      props.onChangeFormValue({ 'collateral': value.toString() })
    }
  }

  const ratesInputProps = {
    lowerBoundRate: currentSwap
      ? +fromBn(currentSwap.vAMMParams.lowerBoundRate, 16).toString()
      : undefined,
    upperBoundRate: currentSwap
      ? +fromBn(currentSwap.vAMMParams.upperBoundRate, 16).toString()
      : undefined,
    intervalLength: currentSwap
      ? +fromBn(currentSwap.vAMMParams.intervalLength, 16).toString()
      : undefined,
  }

  const userIntervalLiquidity: IntervalLiquidity[] = poolLiquidityDistribution.intervalLiquidity.map(item => {
    const existedItem = props.makerLiquidityDistribution.intervalLiquidity
      .find(makerItem => makerItem.bounds.lower.eq(item.bounds.lower))
    if(existedItem) {
      return existedItem
    }
    return {
      ...item,
      notional: BigNumber.from(0)
    }
  })

  const userLiquidityDistribution = {
    ...poolLiquidityDistribution,
    intervalLiquidity: userIntervalLiquidity
  }

  const depositAddonAfter = <Box direction={'row'} align={'center'} gap={'10px'}>
    {!market!.descriptor.underlyingIsWrappedNativeToken &&
        <CoinName name={underlyingName} />
    }
    {market!.descriptor.underlyingIsWrappedNativeToken &&
        <Box width={'75px'}>
            <Select
                className={'antd_token_select'}
                size={'large'}
                defaultValue={underlyingName}
                suffixIcon={<Arrow direction={'down'} width={'10px'} />}
                dropdownStyle={{ backgroundColor: '#222530', border: '1px solid #383D57', borderRadius: '3px' }}
                onChange={(value: string) => {
                  setSelectedTokenName(value)
                  onChangeFormValue({ isNativeTokenSelected: value === nativeTokenName })
                }}
            >
                <Select.Option value={underlyingName}>
                    <Space>
                        <CoinName name={underlyingName} />
                    </Space>
                </Select.Option>
                <Select.Option value={nativeTokenName}>
                    <Space>
                        <CoinName name={nativeTokenName} />
                    </Space>
                </Select.Option>
            </Select>
        </Box>
    }
  </Box>

  return <Box border={{ size: '1px', color: 'borderListItem' }} round={'3px'}>
    <Box pad={'18px 20px'}>
      <Text size={'16px'} weight={500} color={'textSecondary'}>Liquidity provision request</Text>
    </Box>
    <Box pad={'16px 20px'} border={{ size: '1px', color: 'borderListItem', side: 'top' }}>
      <TableContainer>
        <TableBody>
          <TableRow>
            <TableCell>
              <Box margin={{ top: '8px' }}>
                <Text weight={500} color={'textTitle'}>Operation</Text>
              </Box>
            </TableCell>
            <TableCell>
              <Box
                border={{ color: 'borderListItem' }} pad={'6px'} round={'2px'}>
                <Switch options={switchOptions} align={'center'} onChange={onChange} />
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Box direction={'row'} margin={{ top: '0px' }} gap={'6px'} align={'center'}>
                <Text weight={500} color={'textTitle'}>Rate range</Text>
                <QuestionMark tooltipId={'request_rate_range'} tooltipText={tooltips.rateRange} />
              </Box>
            </TableCell>
            <TableCell>
              <Box>
                <Box border={{ color: 'borderListItem' }} round={'2px'} pad={'8px'}>
                  <Box
                    direction={'row'}
                    justify={'between'}
                    align={'center'}
                    margin={{ top: '8px' }}
                    pad={{ left: '4px', right: '4px' }}
                  >
                    <Box>
                      <Text weight={'bold'} color={'#BDC2DB'}>Liquidity overview</Text>
                    </Box>
                    <SwitchSmall
                      options={['Trader', 'Market']}
                      defaultSelectedIndex={0}
                      onChange={(value, index) => {
                        setUserLpShown(index === 0)
                      }}
                    />
                  </Box>
                  <Box margin={{ top: '16px'}}>
                    <LiquidityDistributionChart
                      data={isUserLPShown
                        ? userLiquidityDistribution
                        : poolLiquidityDistribution
                      }
                      underlyingName={underlyingName}
                      underlyingDecimals={underlyingDecimals}
                      userRatesBounds={{
                        lower: prepareFormNumber(formValues.swapRateMin, 16),
                        upper: prepareFormNumber(formValues.swapRateMax, 16),
                      }}
                      vAMMParams={currentSwap?.vAMMParams}
                    />
                  </Box>
                </Box>
                <Box
                  direction={'row'}
                  justify={'between'}
                  align={'center'}
                  margin={{ top: '8px' }}
                >
                  <Box align={'center'} gap={'2px'}>
                    <RatesInput
                      {...ratesInputProps}
                      value={formValues.swapRateMin}
                      onChangeValue={(value) => onChangeFormValue({ 'swapRateMin': value })}
                    />
                    <Box direction={'row'} gap={'4px'} align={'center'}>
                      <Text size={'12px'} color={'textSecondary'}>Lower bound</Text>
                      {ratesInputProps.lowerBoundRate &&
                          <QuestionMark
                              tooltipId={'lower_bound'}
                              tooltipText={`Min: ${ratesInputProps.lowerBoundRate.toString()}%`}
                              place={'bottom'}
                          />
                      }
                    </Box>
                  </Box>
                  <Box align={'center'} gap={'2px'}>
                    <RatesInput
                      {...ratesInputProps}
                      value={formValues.swapRateMax}
                      onChangeValue={(value) => onChangeFormValue({ 'swapRateMax': value })}
                    />
                    <Box direction={'row'} gap={'4px'} align={'center'}>
                      <Text size={'12px'} color={'textSecondary'}>Upper bound</Text>
                      {ratesInputProps.upperBoundRate &&
                          <QuestionMark
                              tooltipId={'upper_bound'}
                              tooltipText={`Max: ${ratesInputProps.upperBoundRate.toString()}%`}
                              place={'bottom'}
                          />
                      }
                    </Box>
                  </Box>
                </Box>
                {formError && formError.field === 'swapRateMin' &&
                    <FormErrorMessage formError={formError} />
                }
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Box direction={'row'} gap={'8px'} align={'center'} margin={{ top: '16px', bottom: '16px' }}>
                <Text weight={500} color={'textTitle'}>Notional</Text>
                <QuestionMark tooltipId={'request_notional'} tooltipText={tooltips.notional} />
              </Box>
            </TableCell>
            <TableCell>
              <Box margin={{ top: '16px', bottom: '16px' }}>
                <NumberInput
                  addonAfter={<CoinName name={underlyingName} />}
                  max={1_000_000_000}
                  value={formValues.notional}
                  onChange={(value) => onChangeFormValue({ 'notional': (value || 0).toString() })}
                />
                {formError && formError.field === 'notional' &&
                    <FormErrorMessage formError={formError} />
                }
                {liquidityQuote &&
                    <Box style={{ position: 'relative' }}>
                        <Box style={{ position: 'absolute', right: 0, top: '4px' }}>
                            <Box direction={'row'} gap={'6px'}>
                                <Box direction={'row'} align={'center'} gap={'2px'}>
                                    <Text color={'textSecondary'} size={'12px'} weight={'bold'}>DV01</Text>
                                    <QuestionMark tooltipId={'dv01_notional'} tooltipText={tooltips.dv01} size={'10px'} />
                                </Box>
                                <Text color={'textSecondary'}>
                                    <Number
                                        value={liquidityQuote.newProvisionNotionalDv01}
                                        decimals={underlyingDecimals}
                                        name={underlyingName}
                                        fontSize={'12px'}
                                    />
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                }
              </Box>
            </TableCell>
          </TableRow>
          {formValues.operation === LiquidityOperation.provide &&
              <TableRow>
                  <TableCell margin={{ bottom: '24px' }}>
                      <Box direction={'row'} gap={'6px'} align={'center'} margin={{ top: '8px' }}>
                          <Text weight={500} color={'textTitle'}>
                            {formValues.operation === LiquidityOperation.provide ? 'Deposit' : 'Withdraw'}
                          </Text>
                          <QuestionMark tooltipId={'request_deposit'} tooltipText={tooltips.deposit} />
                      </Box>
                  </TableCell>
                  <TableCell margin={{ bottom: '24px' }}>
                      <Box margin={{ top: '8px' }}>
                          <NumberInput
                              max={1_000_000_000}
                              addonAfter={depositAddonAfter}
                              value={formValues.collateral}
                              onChange={(value) => onChangeFormValue({ 'collateral': (value || 0).toString() })}
                          />
                      </Box>
                    {!(formError) &&
                        <Box style={{ position: 'relative' }}>
                            <Box style={{ position: 'absolute', right: 0, top: '4px' }}>
                                <BalanceChange
                                    balance={selectedTokenBalance}
                                    decimals={underlyingDecimals}
                                    value={prepareFormNumber(formValues.collateral, underlyingDecimals)}
                                    name={selectedTokenName}
                                />
                            </Box>
                        </Box>
                    }
                    {formError && formError.field === 'collateral' &&
                        <FormErrorMessage
                            formError={formError}
                            onClick={formError.type === FormErrors.collateralRequired
                              ? onAdditionalCollateralClicked
                              : undefined}
                        />
                    }
                  </TableCell>
              </TableRow>
          }
          <TableRow style={{ border: '1px solid #383D57' }} />
        </TableBody>
      </TableContainer>
      <Box margin={{ top: '22px' }} direction={'row'} gap={'4px'}>
        <Box width={'42%'}>
          <TreeView
            title={{ text: 'Total provision', tooltipText: tooltips.totalProvision }}
            items={[
              { text: 'Receiver provision', tooltipText: tooltips.receiverProvision },
              { text: 'Payer provision', tooltipText: tooltips.payerProvision}
            ]}
          />
        </Box>
        <Box width={'58%'}>
          <Table style={{ width: '100%' }}>
            <TableBody>
              <ProvisionReportTableRow
                from={totalProvisionFrom}
                to={totalProvisionTo}
                underlyingName={underlyingName}
                underlyingDecimals={underlyingDecimals}
              />
              <ProvisionReportTableRow
                from={receiverFrom}
                to={receiverTo}
                fromDv01={liquidityQuote ? receiverDv01From : null}
                toDv01={liquidityQuote ? receiverDV01To : null}
                underlyingName={underlyingName}
                underlyingDecimals={underlyingDecimals}
              />
              <ProvisionReportTableRow
                from={payerFrom}
                to={payerTo}
                fromDv01={liquidityQuote ? payerDv01From : null}
                toDv01={liquidityQuote ? payerDv01To : null}
                underlyingName={underlyingName}
                underlyingDecimals={underlyingDecimals}
              />
            </TableBody>
          </Table>
        </Box>
      </Box>
      {/*<Box direction={'row'} gap={'28px'} margin={{ top: '4px' }}>*/}
      {/*  <Box><Text weight={500}>Total LP margin required</Text></Box>*/}
      {/*  <Box direction={'row'} gap={'4px'}>{formatNumber(fromBn(totalLPMarginRequired, underlyingDecimals))} {coinSuffix}</Box>*/}
      {/*</Box>*/}
    </Box>
  </Box>
}
