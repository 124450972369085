import {FutureInfo, MarketInfo, MarketPortfolio} from "../types";
import {Trade} from "../api/dataService";

const underlyingNameMap: Record<string, string> = {
  'Tether USD': 'USDT',
  'Wrapped Ether': 'WETH'
}

const instrumentNameMap: Record<string, string> = {
  'BTCUSDT Perpetual': 'BTCUSDT Funding',
  'ETHUSDT Perpetual': 'ETHUSDT Funding',
}

export const fullMarketNameMap: Record<string, string> = {
  'Binance BTCUSDT Perpetual': 'Binance BTCUSDT Funding',
  'Binance ETHUSDT Perpetual': 'Binance ETHUSDT Funding',
}

const tagsMap: Record<string, string> = {
  'funding rate': 'funding'
}

const sortFutures = (a: FutureInfo, b: FutureInfo) => {
  const maturityA = a.termStart.add(a.termLength).toNumber()
  const maturityB = b.termStart.add(b.termLength).toNumber()

  if(a.marketId === b.marketId) {
    return maturityA - maturityB
  }
  return 0
}

export const sortMarkets = (a: MarketInfo, b: MarketInfo) => {
  const { descriptor: { sourceName: sourceA } } = a

  if(sourceA === 'Rho') {
    return -1
  }
  return 1
}

export const mapMarket = (market: MarketInfo): MarketInfo => {
  const { underlyingName, tag, instrumentName } = market.descriptor

  return {
    ...market,
    futures: market.futures.sort(sortFutures),
    descriptor: {
      ...market.descriptor,
      instrumentName: instrumentNameMap[instrumentName] || instrumentName,
      tag: tagsMap[tag] || tag,
      underlyingName: underlyingNameMap[underlyingName] || underlyingName
    }
  }
}

export const mapPortfolio = (portfolio: MarketPortfolio): MarketPortfolio => {
  const { underlyingName, tag, instrumentName } = portfolio.descriptor
  return {
    ...portfolio,
    descriptor: {
      ...portfolio.descriptor,
      instrumentName: instrumentNameMap[instrumentName] || instrumentName,
      tag: tagsMap[tag] || tag,
      underlyingName: underlyingNameMap[underlyingName] || underlyingName
    }
  }
}

export const mapHistoryTrade = (
  trade: Trade
) => {
  const { market } = trade
  return {
    ...trade,
    market: fullMarketNameMap[market] || market
  }
}


