import {useEffect, useMemo, useState} from 'react';
import {
  getActiveMarketsInfo,
  getMarketsOraclePackages,
  getPortfolio,
} from "../api/viewContract";
import {MarketInfo, MarketPortfolio} from "../types";
import {marginTotal} from "../utils/mappers";
import {useTokenPrice} from "../providers/PriceProvider";
import {bnToDecimal} from "../utils";

const usePortfolio = (params: {
  userAddress?: string,
  refetch?: () => void
}) => {
  const { userAddress } = params

  const { ethereum: ethPrice, tetherPrice } = useTokenPrice()

  const [isInitialFetching, setInitialFetching] = useState(true)
  const [isFetched, setFetched] = useState(false)
  const [isFetching, setFetching] = useState(false)
  const [portfolio, setPortfolio] = useState<MarketPortfolio[]>([]);
  const [markets, setMarkets] = useState<MarketInfo[]>([]);

  const loadData = async () => {
    try {
      setFetching(true)
      // setFetched(false)

      const marketItems = await getActiveMarketsInfo()
      setMarkets(marketItems)

      if(userAddress) {
        const oraclePackages = await getMarketsOraclePackages()
        const portfolioItems = await getPortfolio(userAddress, oraclePackages)
        setPortfolio(portfolioItems)
        if(portfolio.length === 0) {
          console.log('usePortfolio: portfolio loaded', portfolioItems)
        }
      } else {
        setPortfolio([])
      }
    } catch (e) {
      console.error('usePortfolio: failed to load data: ', e)
    } finally {
      setFetching(false)
      setFetched(true)
      setInitialFetching(false)
    }
  }

  useEffect(() => {
    setInitialFetching(true)
    loadData()
  }, [userAddress]);

  return useMemo(() => {
    let accountTotalMarginUSD = 0
    if(userAddress) {
      accountTotalMarginUSD = portfolio.reduce((acc, portfolioItem) => {
        const { descriptor: { underlyingName: name, underlyingDecimals } } = portfolioItem
        const margin = marginTotal(portfolioItem.marginState.margin)

        let tokenPrice = 0
        if(ethPrice && name.toLowerCase().endsWith('eth')) {
          tokenPrice = ethPrice
        } else if(tetherPrice && name.toLowerCase().includes('usd')) {
          tokenPrice = tetherPrice
        }
        return acc += tokenPrice * bnToDecimal(margin, underlyingDecimals).toNumber()
      }, 0)
    }

    return {
      isInitialFetching,
      isFetching,
      isFetched,
      markets,
      portfolio,
      accountTotalMarginUSD,
      refetch: loadData
    }
  }, [userAddress, portfolio, isFetching])
};

export default usePortfolio;
