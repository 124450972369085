import {useEffect, useMemo, useState} from "react";
import {getFutureUserData, UserFutureData} from "../api/dataService";
import {useAccount} from "wagmi";
import usePoller from "./usePoller";

export const useFutureUserData = (futureId: string) => {
  const { address: userAddress } = useAccount()
  const [userFutureData, setUserFutureData] = useState<UserFutureData>()

  const loadData = async () => {
    try {
      if(userAddress) {
        const data = await getFutureUserData(futureId, userAddress)
        // console.log('Update user future data:', data.averageRatePosition.toString())
        setUserFutureData(data)
      } else {
        setUserFutureData(undefined)
      }
    } catch(e) {
      console.error('useFutureUserData: failed to get user future data:', e)
      setUserFutureData(undefined)
    }
  }

  useEffect(() => {
    loadData()
  }, [userAddress, futureId])

  usePoller(() => {
    if(userAddress && futureId) {
      loadData()
    }
  }, 5000)

  return useMemo(() => {
    return {
      userFutureData
    }
  }, [userFutureData])
}
