import {readContract} from "wagmi/actions";
import RouterABI from "../abi/RouterABI.json";
import config from '../config'

const { routerContractAddress } = config

export const getContractProvider = async (): Promise<string> => {
  return await readContract({
    address: routerContractAddress,
    abi: RouterABI,
    functionName: 'getContractProvider',
    args: [],
    chainId: config.chainId
  }) as string
}

export const getACMAddress = async (contractProviderAddress: string): Promise<string> => {
  return await readContract({
    address: contractProviderAddress as `0x${string}`,
    abi: [{
      "inputs": [],
      "name": "getACM",
      "outputs": [
        {
          "internalType": "contract IAccessControlManager",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    functionName: 'getACM',
    // args: [],
    chainId: config.chainId
  }) as string
}

export const isProtocolUser = async (acmAddress: string, userAddress: `0x${string}`): Promise<boolean> => {
  return await readContract({
    address: acmAddress as `0x${string}`,
    abi: [{
      "inputs": [
        {
          "internalType": "address",
          "name": "user",
          "type": "address"
        }
      ],
      "name": "isProtocolUser",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }],
    functionName: 'isProtocolUser',
    args: [userAddress],
    chainId: config.chainId
  }) as boolean
}

export const isWhitelistEnabled = async (): Promise<boolean> => {
  return await readContract({
    address: routerContractAddress,
    abi: RouterABI,
    functionName: 'isWhitelistEnabled',
    args: [],
    chainId: config.chainId
  }) as boolean
}

