import React, {useEffect} from 'react'
import {Anchor, Box, Text} from "grommet";
import {notification} from "antd";

const lsKeyWelcome = 'rho_welcome_shown_v1'

export const GlobalNotifications = () => {
  const [api, contextHolder] = notification.useNotification();

  const openWelcomeNotification = () => {
    api.info({
      message: `Welcome to Rho Protocol, a professional grade non-custodial rates market.`,
      description: <Text>
        This version is our Private Beta, which is intended for professional investor use, and some things may yet not work as smoothly as others. If you’d like to get whitelisted or share your feedback with us, please reach out at hello [at] rholabs.io.
      </Text>,
      placement: 'bottomRight',
      style: {
        border: '1px solid #4852FF',
        borderRadius: '5px'
      },
      onClose: () => {
        localStorage.setItem(lsKeyWelcome, 'true')
      },
      duration: 10000000,
    });
  };

  useEffect(() => {
    if(!localStorage.getItem(lsKeyWelcome)) {
      openWelcomeNotification()
    }
  }, []);

  return <Box>
    {contextHolder}
  </Box>
}
