import React, {useState} from 'react'
import {Box, Text} from "grommet";
import {QuestionMark} from "./QuestionMark";

export interface TreeItem {
  text: string
  tooltipText: string
  value?: string | React.ReactNode
}

export interface TreeViewProps {
  title: TreeItem
  items: TreeItem[]
}

const TreeView = (props: TreeViewProps) => {
  const { title, items} = props

  const [id] = useState(Math.random().toString(36)[2])

  return <Box>
    <Box direction={'row'} align={'center'} gap={'4px'}>
      <Text weight={500} color={'textTitle'}>{title.text}</Text>
      <QuestionMark tooltipId={id + 'tooltip_title'} tooltipText={title.tooltipText} />
    </Box>
    <Box
      margin={{ left: '10px' }}
      border={{ size: '2px', side: 'left', color: 'borderListItem' }}
    >
      {items.map((item, index) =>
        <Box key={index} direction={'row'} height={index === 0 ? '40px' : '55px'} style={{ position: 'relative' }}>
          <Box width={'12px'} border={{ size: '2px', side: 'bottom', color: 'borderListItem' }} />
          <Box direction={'row'} align={'center'} gap={'4px'} style={{ position: 'absolute', bottom: '-10px', left: '16px' }}>
            <Text weight={500} color={'textTitle'}>{item.text}</Text>
            <QuestionMark tooltipId={id + 'tree_provision_' + index} tooltipText={item.tooltipText} />
          </Box>
      </Box>)}
    </Box>
  </Box>
}

export default TreeView
