import axios from 'axios'
import config from '../config'
import {OraclePackage} from "../types";

export interface OracleRecord {
  oraclePackage: OraclePackage
  latestRate: string
  rateDelta: string
  indexValueRay: string
  rateTimestamp: number
}

export const getOracleRates = async (): Promise<OracleRecord[]> => {
  const { data: oracleRecords } = await axios.get<OracleRecord[]>(
    `${config.oraclesServiceUrl}/records`
  )

  return oracleRecords
}

export const getMarketRates = async (marketId: string): Promise<OracleRecord | undefined> => {
  const rates = await getOracleRates()
  return rates.find(item => item.oraclePackage.marketId === marketId)
}

export type DataInterval = '1m' | '5m' | '15m' | '30m' | '1h' | '4h' | '1d' | '1w'

export interface OracleFloatingRate {
  completed: boolean
  close: string
  high: string
  low: string
  open: string
  timestamp: number
  volume: string
}

export const getFloatingRates = async (params: {
  marketId: string
  from: number
  to: number
  interval: DataInterval
}) => {
  const { marketId, ...rest } = params
  const { data } = await axios.get<OracleFloatingRate[]>(`${config.oraclesServiceUrl}/api/v1/floating-rate/${marketId}`, {
    params: rest
  })

  return data
}

export interface MarketOraclePackage {
  marketId: string
  packages: OraclePackage[]
}

export const getOraclePackages = async (): Promise<MarketOraclePackage[]> => {
  const rates = await getOracleRates()
  return rates.map(item => {
    return {
      marketId: item.oraclePackage.marketId,
      packages: [item.oraclePackage]
    }
  })
}
