import React, {ReactNode} from 'react'
import {Box, Spinner, Text} from "grommet";
import {getIconByName} from "../Icon";
import styled from "styled-components";

export interface RhoStepItem {
  text: string | ReactNode
  isActive?: boolean
  isInProgress?: boolean
  status?: 'default' | 'done'
}

export interface RhoStepsProps {
  items: RhoStepItem[]
}

const SpinnerContainer = styled(Box)`
    position: absolute;
    left: -1px;
    top: -1px;
    
    > div {
        max-width: unset!important;
    }
`

const RhoStepItem = (props: RhoStepItem & { index: number }) => {
  const {
    index,
    text,
    status = 'default',
    isActive = false,
    isInProgress = false
  } = props

  return <Box
    width={'40px'}
    height={'40px'}
    round={'40px'}
    align={'center'}
    justify={'center'}
    background={'widgetBg'}
    border={{ color: isInProgress ? 'borderSteps' : 'borderSteps', size: '1px' }}
    style={{ position: 'relative', zIndex: 2 }}
  >
    {isInProgress &&
        <SpinnerContainer>
            <Spinner color={'spinner'} width={'40px'} height={'40px'} size={'1px'} />
        </SpinnerContainer>
    }
    {status === 'done'
      ? getIconByName('checkmark').icon
      : <Text size={'16px'} color={isActive ? 'accentWhite' : 'text'}>
        {index + 1}
      </Text>
    }
    <Box style={{ position: 'absolute', bottom: '-28px', left: 'calc(-50% + 8px)' }}>
      {text}
    </Box>
  </Box>
}

export const RhoSteps = (props: RhoStepsProps) => {
  return <Box direction={'row'} width={'100%'} justify={'between'} style={{ position: 'relative' }}>
    {props.items.map((item, index) =>
      <RhoStepItem key={index} index={index} {...item} />
    )}
    <Box
      margin={{ left: '20px', top: '20px' }}
      width={'calc(100% - 40px)'}
      height={'1px'}
      background={'borderSteps'}
      style={{ position: 'absolute' }}
    />
  </Box>
}
