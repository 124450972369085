import React, {useMemo} from 'react'
import {Box, Text} from "grommet";
import {TradingViewChart} from "../trading-view";
import {WidgetContainer} from "../../../components";
import {StatsWidgetProps} from "../common";
import moment from "moment/moment";
import {Time} from "lightweight-charts";

export const OpenInterestDaily = (props: StatsWidgetProps) => {
  const { stats } = props

  const items = useMemo(() => {
    // Remove duplicates
    const datesMap: Record<string, boolean> = {}
    const items = stats.openInterestDaily.filter((item) => {
      if(!datesMap[`${item.date}`]) {
        datesMap[`${item.date}`] = true
        return true
      }
    })
    return items
      .map(item => {
        return {
          time: moment(item.date).unix() as Time,
          value: +item.openInterest
        }
      })
      .sort((a, b) => {
        return moment(a.time).unix() - moment(b.time).unix()
      })
  }, [stats.openInterestDaily])

  return <WidgetContainer style={{ flex: 1 }}>
    <Box>
      <Text color={'accentWhite'}>Open interest</Text>
      <Text>Open interest on Rho</Text>
    </Box>
    <Box margin={{ top: '8px' }}>
      <TradingViewChart
        isLoading={props.isLoading}
        height={300}
        lineItems={items}
        tooltipTitle={'Open interest'}
        tooltipValuePrefix={'$'}
      />
    </Box>
  </WidgetContainer>
}
