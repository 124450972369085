import React, {useEffect, useRef, useState} from 'react'
import {ChartOptions, createChart, DeepPartial, ISeriesApi, TickMarkFormatter, Time} from 'lightweight-charts';
import { darkTheme } from "./theme";
import {abbreviateNumber} from "../../../utils";
import styled from "styled-components";
import {Box, Spinner, Text} from "grommet";
import {WidgetContainer} from "../../../components";
import moment from 'moment';

const toolTipWidth = 150;
const toolTipHeight = 84;
const toolTipMargin = 15;

const TooltipContainer = styled(WidgetContainer)`
    position: absolute;
    display: none;
    color: #ADAEB8;
    width: ${toolTipWidth + 16}px;
    height: ${toolTipHeight}px;
    padding: 8px;
    text-align: left;
    z-index: 1000;
    top: 12px;
    left: 12px;
    border: 1px solid #383D57;
    pointer-events: none;
`

export interface TradingViewItem {
  time: Time
  value: number
}

export interface TradingViewCandle {
  time: Time
  open: number
  high: number
  low: number
  close: number
}

export interface TradingViewProps {
  isLoading?: boolean
  width?: number
  height: number
  lineItems?: TradingViewItem[]
  candleItems?: TradingViewCandle[]
  tickMarkFormatter?: TickMarkFormatter
  tooltipTitle?: string
  tooltipValuePrefix?: string
}

interface TradingViewTooltipState {
  visible: boolean
  title: string,
  value: string,
  time: string,
  left: number,
  top: number
}

const defaultTooltipState: TradingViewTooltipState = {
  visible: false,
  title: '',
  value: '',
  time: '',
  left: 0,
  top: 0
}

export const TradingViewChart = (props: TradingViewProps) => {
  const {
    width,
    height,
    lineItems,
    candleItems,
    tooltipTitle = '',
    tooltipValuePrefix = '',
    isLoading = false
  } = props;

  const chartContainerRef = useRef<HTMLElement>();
  const [tooltipState, setTooltipState] = useState<TradingViewTooltipState>(defaultTooltipState)

  const onCrosshairMove = (param: any, series: ISeriesApi<'Area'>) => {
    if(!chartContainerRef.current) {
      return
    }

    const container = chartContainerRef.current
    let newTooltipState: TradingViewTooltipState = {...defaultTooltipState}

    if (
      param.point === undefined ||
      !param.time ||
      param.point.x < 0 ||
      param.point.x > container.clientWidth ||
      param.point.y < 0 ||
      param.point.y > container.clientHeight
    ) {
    } else {
      // time will be in the same format that we supplied to setData.
      // thus it will be YYYY-MM-DD
      const data = param.seriesData.get(series);
      const price = data.value !== undefined ? data.value : data.close;
      const coordinate = series.priceToCoordinate(price);
      let shiftedCoordinate = param.point.x - 50;
      if (coordinate === null) {
        return;
      }
      shiftedCoordinate = Math.max(
        0,
        Math.min(container.clientWidth - toolTipWidth, shiftedCoordinate)
      );
      const coordinateY =
        coordinate - toolTipHeight - toolTipMargin > 0
          ? coordinate - toolTipHeight - toolTipMargin
          : Math.max(
            0,
            Math.min(
              container.clientHeight - toolTipHeight - toolTipMargin,
              coordinate + toolTipMargin
            )
          );

      newTooltipState = {
        ...newTooltipState,
        visible: true,
        title: tooltipTitle,
        value: `${tooltipValuePrefix}${abbreviateNumber(price)}`,
        time: param.time,
        left: shiftedCoordinate,
        top: coordinateY
      }
    }
    setTooltipState(newTooltipState)
  }

  useEffect(
    () => {
      const handleResize = () => {
        if(chartContainerRef.current) {
          chart.applyOptions({ width: chartContainerRef.current.clientWidth });
        }
      };

      // @ts-ignore
      const chart = createChart(chartContainerRef.current, {
        width: width || (chartContainerRef && chartContainerRef.current
          // @ts-ignore
          ? chartContainerRef.current.clientWidth
          : undefined),
        height,
        rightPriceScale: {
          borderVisible: false,
          visible: true,
        },
        leftPriceScale: {
          visible: false,
        },
        timeScale: {
          borderVisible: false,
        },
        grid: {
          vertLines: {
            visible: false
          },
          horzLines: {
            visible: false
          }
        }
      });
      // @ts-ignore
      chart.applyOptions(darkTheme.chart)
      chart.timeScale().fitContent();

      let chartOptions: DeepPartial<ChartOptions> = {
        crosshair: {
          // hide the horizontal crosshair line
          horzLine: {
            visible: false,
            labelVisible: false,
          },
          // hide the vertical crosshair label
          vertLine: {
            labelVisible: false,
          },
        },
        localization: {
          priceFormatter: (value: number) => {
            return abbreviateNumber(value)
          }
        }
      }

      if(props.tickMarkFormatter) {
        chartOptions = {
          ...chartOptions,
          timeScale: {
            ...chartOptions.timeScale,
            tickMarkFormatter: props.tickMarkFormatter
          }
        }
      }

      chart.applyOptions(chartOptions)

      if(lineItems) {
        const newSeries = chart.addAreaSeries();
        newSeries.setData(lineItems);
        // Update tooltip
        chart.subscribeCrosshairMove((param) => onCrosshairMove(param, newSeries))
      }

      if(candleItems) {
        const candlestickSeries = chart.addCandlestickSeries({})
        candlestickSeries.setData(candleItems)
      }

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        chart.remove();
      };
    },
    [lineItems, candleItems, chartContainerRef.current]
  );

  return <div
    // @ts-ignore
    ref={chartContainerRef}
    style={{
      position: 'relative',
      opacity: isLoading ? 0.4 : 1
  }}
  >
    {props.isLoading &&
        <Box
            width={'100%'}
            height={'100%'}
            justify={'center'}
            align={'center'}
            style={{ position: 'absolute', zIndex: 2, left: 0, top: 0 }}>
            <Spinner color={'gray'} size={'medium'} />
        </Box>
    }
    {tooltipState.visible &&
      <TooltipContainer
          style={{
          top: `${tooltipState.top}px`,
          left: `${tooltipState.left}px`,
          display: 'flex'
        }}
        justify={'center'}
        key={tooltipState.value}
      >
          <Text color={'accentWhite'}>
            {moment(+tooltipState.time * 1000).format('MMM D, YYYY, HH:mm')}
          </Text>
          <Box justify={'between'} margin={{ top: '2px' }} gap={'2px'}>
              {tooltipTitle &&
                  <Text color={'text'} size={'14px'}>
                    {tooltipTitle}
                  </Text>
              }
              <Text color={'accentWhite'} size={'18px'}>
                {tooltipState.value}
              </Text>
          </Box>
      </TooltipContainer>
    }
  </div>
}
