import React, {useState} from 'react'
import {Box, Text} from "grommet";
import {
  Number,
  PrimaryButton,
  QuestionMark,
  TokenAmountInput,
  ValuesChangeBadge,
  WidgetContainer
} from "../../components";
import {Button as AntdButton, Skeleton, Typography} from "antd";
import {ReactComponent as CrossImg} from "../../assets/images/cross.svg";
import {bnToDecimal, formatNumber, prepareFormNumber, truncateEthAddress} from "../../utils";
import {BigNumber} from "ethers";
import {erc20ABI, useAccount, useBalance, useConnect, useContractWrite, useNetwork} from "wagmi";
import VaultRouterABI from '../../abi/VaultRouterABI.json'
import {switchNetwork, waitForTransaction} from "@wagmi/core";
import {connect, writeContract} from "wagmi/actions";
import {toast} from "react-toastify";
import {getOraclePackages} from "../../api/oracleService";
import {getAllowance} from "../../api/erc20Contract";
import config from '../../config'
import {useVault} from "../../providers/VaultDataProvider";
import {showWithdrawNotification} from "./notifications";

const zero = BigNumber.from(0)

interface FormValuesState {
  amount: string,
  withdrawMax: boolean
}

const defaultValuesState: FormValuesState = {
  amount: '0',
  withdrawMax: false
}

type FormValueProperty = {
  [K in keyof FormValuesState]?: FormValuesState[K];
};

export const WithdrawModal = (props: {
  onClose: () => void
}) => {
  const {
    vault,
    isInitialLoading: isVaultLoading,
    refetch: refetchVaultBalance
  } = useVault()
  const { isConnected, address: userAddress } = useAccount()
  const {chain} = useNetwork()
  const { connectors } = useConnect()
  const metamaskConnector = connectors
    .find((item) => item.id.toLowerCase() === 'metamask')

  const { data: underlyingBalance } = useBalance({
    address: userAddress,
    token: vault?.underlyingAddress as `0x${string}`,
    watch: true,
    enabled: !!vault?.underlyingAddress,
    chainId: config.chainId
  })

  const [formValues, setValues] = useState<FormValuesState>(defaultValuesState)
  const [depositInProgress, setDepositInProgress] = useState(false)

  const fromTokenDecimals = vault?.underlyingDecimals || 6
  const valueFrom = vault ? vault.maxWithdraw.sub(vault.userPendingWithdrawalsAmount) : zero
  const amount = prepareFormNumber(formValues.amount, fromTokenDecimals)
  const valueTo = valueFrom.sub(amount)
  const selectedTokenName = vault?.underlyingName || 'USDT'
  const underlyingName = vault?.underlyingName || 'USDT'

  const onChangeFormValue = (newState: FormValueProperty) => {
    setValues((currentState) => {
      return {
        ...currentState,
        ...newState
      }
    })
  }

  const {
    writeAsync: withdrawAsync,
    error: depositError
  } = useContractWrite({
    address: config.vaultRouterAddress as `0x${string}`,
    abi: VaultRouterABI,
    functionName: 'withdraw',
    chainId: config.chainId,
  })

  const onClick = async () => {
    try {
      if(!vault) {
        console.error('No vault found')
        return
      }
      if(!userAddress) {
        return
      }
      setDepositInProgress(true)
      const packages = await getOraclePackages()
      const amount = prepareFormNumber(formValues.amount, fromTokenDecimals)
      const unwrapNativeToken = false
      const maxSharesOut = 10000000000000n
      const deadline = Date.now() + 60 * 1000

      const args = [
        vault.id,
        BigInt(amount.toString()),
        userAddress,
        userAddress,
        unwrapNativeToken,
        maxSharesOut,
        formValues.withdrawMax,
        packages,
        deadline
      ]

      const receipt = await withdrawAsync({ args })
      try {
        await waitForTransaction({
          hash: receipt.hash,
          chainId: config.chainId,
        })
      } catch (e) {
        console.error('Failed waitForTransaction:', e)
        await new Promise(resolve => setTimeout(resolve, 6000))
      }
      const updatedVault = await refetchVaultBalance()
      showWithdrawNotification(formValues.amount, updatedVault || vault, receipt)
      setValues({
        amount: '0',
        withdrawMax: false
      })
      props.onClose()
    } catch (e) {
      console.error('Failed to redeem', e)

      let message = (e as Error).message
      let messageText = message
      const [description] = message.split('(0x')
      if(description.length > 0) {
        messageText = description
      }

      toast.error(<Typography.Text copyable={{ text: message }} style={{ color: 'white' }}>
        {'Failed to redeem: ' + messageText}
      </Typography.Text>, {
        closeOnClick: false
      })
    } finally {
      setDepositInProgress(false)
    }
  }

  const isUnsupportedNetwork = isConnected && chain && (chain.unsupported || chain.id !== config.chainId)

  const onConnectClicked = async () => {
    try {
      if(metamaskConnector) {
        await connect({
          connector: metamaskConnector,
          chainId: config.chainId,
        })
      }
    } catch (e) {
      console.error('Failed to connect wallet', e)
    }
  }

  const inputBottomPanel = <Box pad={'0 10px 0'}>
    <Box background={'#272835'} width={'100%'} height={'1px'} />
    <Box direction={'row'} justify={'between'} align={'center'}>
      <Box direction={'row'} margin={{ top: '8px', bottom: '8px' }} gap={'10px'} align={'center'}>
        <Box direction={'row'} align={'center'} gap={'4px'}>
          <Text color={'textSecondary'}>
            Available to withdraw
          </Text>
          <QuestionMark tooltipId={'margin_mgmt_wallet'} tooltipText={'User balance'} />
        </Box>
        <ValuesChangeBadge
          from={valueFrom}
          to={valueTo}
          decimals={fromTokenDecimals}
          name={selectedTokenName}
          showName={false}
          fontSize={'12px'}
        />
      </Box>
      <Box
        background={'optionBg'}
        round={'4px'}
        pad={'4px 7px'}
        border={{ color: formValues.withdrawMax ? 'positiveValue' : 'unset', size: '1px' }}
        onClick={() => {
          const nextWithdrawMax = !formValues.withdrawMax

          onChangeFormValue({
            'withdrawMax': nextWithdrawMax,
            amount: nextWithdrawMax
              ? bnToDecimal(valueFrom, fromTokenDecimals).toString()
              : '0'
          })
        }}
      >
        <Text
          size={'12px'}
          color={formValues.withdrawMax ? 'positiveValue' : 'accentWhite2'}
          weight={formValues.withdrawMax ? 700 : 400}
        >
          MAX
        </Text>
      </Box>
      {/*<Box direction={'row'} gap={'4px'}>*/}
      {/*  {[25, 50, 75, 100].map((value) => {*/}
      {/*    return <Box key={value} background={'optionBg'} round={'4px'} pad={'4px 7px'} onClick={() => {*/}
      {/*      try {*/}
      {/*        const amount = bnToDecimal(valueFrom, fromTokenDecimals)*/}
      {/*        const amountDecimal = amount.mul(value / 100)*/}
      {/*         onChangeFormValue({ 'amount': amountDecimal.toString() })*/}
      {/*      } catch (e) {*/}
      {/*        console.error('Failed to format amount', valueFrom.toString())*/}
      {/*      }*/}
      {/*    }}>*/}
      {/*      <Text size={'12px'} color={'accentWhite2'}>{value}%</Text>*/}
      {/*    </Box>*/}
      {/*  })}*/}
      {/*</Box>*/}
    </Box>
  </Box>

  return <WidgetContainer style={{ position: 'relative', padding: '24px' }}>
    <Box direction={'row'} justify={'between'} align={'center'}>
      <Text color={'textHeader'} size={'20px'} weight={500}>
        Redeem
      </Text>
      <Box direction={'row'} justify={'end'}>
        <AntdButton type={'text'} onClick={() => { props.onClose() }}><Box justify={'center'} align={'center'}><CrossImg /></Box></AntdButton>
      </Box>
    </Box>
    <Box margin={{ top: '16px' }}>
      <Box>
        <Box direction={'row'} align={'center'} gap={'4px'}>
          <Text color={'textSecondary'}>In vault</Text>
        </Box>
        {!vault
          ? <Skeleton.Input active size={'small'} style={{ width: '240px', height: '26px' }} />
          : <Number
              value={vault.maxWithdraw}
              decimals={vault.underlyingDecimals}
              fontSize={'28px'}
              fontColor={'textHeader'}
              name={vault.underlyingName}
              showName={true}
            />
        }
      </Box>
    </Box>
    {(!vault || vault.userPendingWithdrawalsAmount.eq(0))
      ? null
      : <Box margin={{ top: '16px' }}>
        <Box direction={'row'} align={'center'} gap={'4px'}>
          <Text color={'textSecondary'}>Pending withdrawals</Text>
        </Box>
        <Number
          value={vault.userPendingWithdrawalsAmount}
          decimals={vault.underlyingDecimals}
          fontSize={'16px'}
          fontColor={'textHeader'}
          name={vault.underlyingName}
          showName={true}
        />
      </Box>
    }
    <Box margin={{ top: '32px' }}>
      <TokenAmountInput
        id={'margin_mgmt_amount'}
        value={formValues.amount}
        status={''}
        options={[{ text: underlyingName }]}
        width={'300px'}
        bottompanel={inputBottomPanel}
        onChange={(value) => onChangeFormValue({ 'amount': (value || 0).toString() })}
      />
    </Box>
    <Box margin={{ top: '32px' }}>
      {isConnected
        ? isUnsupportedNetwork
          ? <PrimaryButton
            text={'Switch network'}
            onClick={() => {
              switchNetwork({
                chainId: config.chainId
              }).catch(e => {
                console.error('Failed to switch network', e);
              })
            }}
          />
          : <PrimaryButton
            text={(formValues.amount && +formValues.amount > 0)
              ? `Redeem ${formatNumber(formValues.amount)} USDT`
              : 'Redeem'
            }
            disabled={
              !vault || amount.lte(0) || depositInProgress || valueTo.lt(0)
            }
            loading={depositInProgress}
            onClick={onClick}
          />
        : <PrimaryButton text={'Connect wallet'} onClick={onConnectClicked} />
      }
    </Box>
  </WidgetContainer>
}
