import {BigNumber} from "ethers";
import Decimal from 'decimal.js'
import {MarketInfo, RiskDirectionType} from "../../types";
import {HistoryItem, MarginUpdate, Provision, Trade} from "../../api/dataService";
import moment from "moment/moment";

export enum EventType {
  tradeUpdate = 'trades',
  marginUpdate = 'marginTransfers',
  liquidityUpdate = 'liquidityProvisions'
}

export enum TradeType {
  maker = 'maker',
  taker = 'taker',
  all = 'all'
}

export const TradeTypeAlias: Record<TradeType, string> = {
  [TradeType.maker]: 'Only maker',
  [TradeType.taker]: 'Only taker',
  [TradeType.all]: 'Maker and taker',
}

export interface FutureData {
  id: string
  marketId: string
  termStart: BigNumber
  termLength: BigNumber
}

const historyKeysOrder = [
  'market',
  'maturity',
  'direction',
  'underlyingName',
  'notionalAmount',
  'rate',
  'feeAmount',
  'timestamp',
  'floatingIndex',
  'marketRateBefore',
  'marketRateAfter',
  'ownerIsMaker'
]

const mapHistoryItemKeys = (obj: HistoryItem, order = historyKeysOrder) => {
  const sortedObject: Record<string, string> = {};

  for (const key of order) {
    if (key in obj) {
      sortedObject[key] = (obj as any)[key];
    }
  }

  for (const key in obj) {
    if (!order.includes(key)) {
      sortedObject[key] = (obj as any)[key]
    }
  }

  return sortedObject;
}

export const mapHistoryItem = (item: HistoryItem, markets: MarketInfo[]) => {
  const { marketId, timestamp } = item


  const market = markets.find(item => item.descriptor.id === marketId)
  const underlyingDecimals = market?.descriptor.underlyingDecimals || 6
  const underlyingName = market?.descriptor.underlyingName || 'USDT'

  const mappedProps: Record<string, any> = {}
  if ("direction" in item) {
    mappedProps.direction = item.direction === RiskDirectionType.PAYER ? 'payer' : 'receiver'
  }
  if ("ownerIsMaker" in item) {
    mappedProps.ownerIsMaker = item.ownerIsMaker ? 'true' : 'false'
  }
  if("rate" in item) {
    mappedProps.rate = new Decimal(item.rate).div(10 ** 16).toDecimalPlaces(2).toString()
  }
  if("notionalAmount" in item) {
    mappedProps.notionalAmount = new Decimal(item.notionalAmount).div(10 ** underlyingDecimals).toDecimalPlaces(2).toString()
  }
  if("feeAmount" in item) {
    const feesSign = item.ownerIsMaker ? '+' : '-'
    mappedProps.feeAmount = feesSign + new Decimal(item.feeAmount)
      .div(10 ** underlyingDecimals)
      .toDecimalPlaces(4)
      .toString()
  }
  if("marketRateBefore" in item && item.marketRateBefore) {
    mappedProps.marketRateBefore = new Decimal(item.marketRateBefore).div(10 ** 16).toDecimalPlaces(4).toString()
  }
  if("marketRateAfter" in item && item.marketRateAfter) {
    mappedProps.marketRateAfter = new Decimal(item.marketRateAfter).div(10 ** 16).toDecimalPlaces(4).toString()
  }
  if("floatingIndex" in item) {
    mappedProps.floatingIndex = new Decimal(item.floatingIndex).div(10 ** 18).toDecimalPlaces(6).toString()
  }
  if("timestamp" in item && item.timestamp) {
    mappedProps.timestamp = `${moment(timestamp * 1000).format('DD/MM/YYYY HH:mm:ss')}`
  }
  if("maturity" in item) {
    mappedProps.maturity = `${moment(item.maturity * 1000).format('DD/MM/YYYY')}`
  }
  if("marginDelta" in item) {
    mappedProps.marginDelta = new Decimal(item.marginDelta).div(10 ** underlyingDecimals).toDecimalPlaces(4).toString()
  }

  // LP Events
  if("lowerBoundRate" in item) {
    mappedProps.lowerBoundRate = new Decimal(item.lowerBoundRate).div(10 ** 16).toDecimalPlaces(4).toString()
  }
  if("upperBoundRate" in item) {
    mappedProps.upperBoundRate = new Decimal(item.upperBoundRate).div(10 ** 16).toDecimalPlaces(4).toString()
  }

  mappedProps.underlyingName = underlyingName
  return mapHistoryItemKeys({
    ...item,
    ...mappedProps
  })
}
