import {readContract} from "wagmi/actions";
import ContractProviderABI from "../abi/ContractProviderABI.json";
import TradeLimiterABI from "../abi/TradeLimiterABI.json";
import TradeLimiterStorageABI from "../abi/TradeLimiterStorageABI.json";
import config from '../config'
import {enrichResponse} from "./utils";
import {Address} from "wagmi";
import {keccak256, toBytes} from "viem";
import {TradeLimiterParameters} from "../types";

const { contractProviderAddress } = config

export const getMarketAddressById = async (marketId: string): Promise<Address> => {
  const data = await readContract({
    address: contractProviderAddress,
    abi: ContractProviderABI,
    functionName: 'getMarketAddressById',
    args: [marketId],
    chainId: config.chainId
  })
  return enrichResponse(data)
}

export const getAllFutureIds = async (): Promise<string[]> => {
  const data = await readContract({
    address: contractProviderAddress,
    abi: ContractProviderABI,
    functionName: 'getAllFutureIds',
    args: [],
    chainId: config.chainId
  })
  return enrichResponse(data)
}

export const getFutureAddressById = async (futureId: string): Promise<Address> => {
  const data = await readContract({
    address: contractProviderAddress,
    abi: ContractProviderABI,
    functionName: 'getFutureAddressById',
    args: [futureId],
    chainId: config.chainId
  })
  return enrichResponse(data)
}

export const getTradeLimiterStorageAddress = async (contractProviderAddress: string) => {
  const tradeLimiterId = keccak256(toBytes('TRADE_LIMITER'))
  const limiterAddress =  await readContract({
    address: contractProviderAddress as `0x${string}`,
    abi: ContractProviderABI,
    functionName: 'getContractAddress',
    args: [tradeLimiterId],
    chainId: config.chainId
  }) as Address
  return await readContract({
    address: limiterAddress as `0x${string}`,
    abi: TradeLimiterABI,
    functionName: 'getStorage',
    args: [],
    chainId: config.chainId
  }) as Address
}

export const getTradeLimiterParameters = async (tradeLimiterStorageAddress: string, marketId: string) => {
  const data = await readContract({
    address: tradeLimiterStorageAddress as Address,
    abi: TradeLimiterStorageABI,
    functionName: 'getTradeLimiterParameters',
    args: [marketId],
    chainId: config.chainId
  }) as TradeLimiterParameters
  return enrichResponse(data)
}
