import {FutureInfo, IRateMathType, MarketInfo, MarketPortfolio, RiskDirectionType} from "../types";
import {useMemo} from "react";
import {Decimal} from "decimal.js";
import {bnToDecimal} from "../utils";
import {BigNumber} from "ethers";
import {CompoundingRateMath, LinearRateMath} from "../utils/leverage";
import {marginTotal} from "../utils/mappers";

const DefaultRange = { min: -100, max: 100 }

export const useMaxLeverage = (params: {
  market?: MarketInfo
  future?: FutureInfo
  portfolio?: MarketPortfolio,
  leverageRange?: { min: number, max: number }
}) => {
  const {market, portfolio, future} = params

  return useMemo(() => {
    if (market && future && portfolio) {
      const {id: futureId} = future
      const {underlyingDecimals} = market.descriptor
      const currentMargin = marginTotal(portfolio.marginState.margin)
      const secondsToMaturity = future
        ? future.termStart.add(future.termLength).toNumber() - Math.round(Date.now() / 1000)
        : 0

      const currentMarginDecimal = portfolio ? bnToDecimal(currentMargin, market.descriptor.underlyingDecimals) : new Decimal(0)
      const openPosition = portfolio ? portfolio.futureOpenPositions.find(pos => pos.futureId === futureId) : undefined
      const openPositionNotional = openPosition ? openPosition.notional : BigNumber.from(0)
      const openPositionNotionalDecimal = bnToDecimal(openPositionNotional, market.descriptor.underlyingDecimals)
      const currentRate = future
        ? new Decimal(bnToDecimal(future.vAMMParams.currentFutureRate, 18))
        : new Decimal(0)
      const delta = new Decimal(bnToDecimal(market.riskParameters.marginThresholdDelta, 18))
      const marginRequirementSecondsFloor = market.riskParameters.marginRequirementSecondsFloor
      const hedgeFactor = new Decimal(bnToDecimal(market.riskParameters.hedgeMarginFactor, 18))

      const rateMath = market.descriptor.rateMathType === IRateMathType.LINEAR
        ? new LinearRateMath()
        : new CompoundingRateMath()
      try {
        const maxShort = rateMath.calcMaxLeverageWithPortfolio(
          futureId,
          portfolio.futures,
          portfolio.futureOpenPositions,
          delta,
          currentMarginDecimal,
          openPositionNotionalDecimal,
          currentRate,
          secondsToMaturity,
          marginRequirementSecondsFloor,
          hedgeFactor,
          RiskDirectionType.RECEIVER,
          portfolio,
          underlyingDecimals,
          openPosition
        ) * -1

        const maxLong = rateMath.calcMaxLeverageWithPortfolio(
          futureId,
          portfolio.futures,
          portfolio.futureOpenPositions,
          delta,
          currentMarginDecimal,
          openPositionNotionalDecimal,
          currentRate,
          secondsToMaturity,
          marginRequirementSecondsFloor,
          hedgeFactor,
          RiskDirectionType.PAYER,
          portfolio,
          underlyingDecimals,
          openPosition
        )

        return {
          maxShortLeverage: maxShort,
          maxLongLeverage: maxLong
        }
      } catch (e) {
        console.log('Failed to calculate leverage params, use default range:', e)
        return {
          maxShortLeverage: DefaultRange.min,
          maxLongLeverage: DefaultRange.min
        }
      }
    } else {
      return {
        maxShortLeverage: params.leverageRange?.min || DefaultRange.min,
        maxLongLeverage: params.leverageRange?.max || DefaultRange.min
      }
    }
  }, [
    params.market,
    params.portfolio,
    params.future,
    params.leverageRange
  ])
}
