import {readContract} from "wagmi/actions";
import ViewABI from "../abi/ViewABI.json";
import {
  LiquidityDistribution,
  MakerLiquidityDistribution,
  MarginState,
  MarketInfo,
  MarketOraclePackages,
  MarketPortfolio,
  OraclePackage,
} from "../types";
import config from '../config'
import {enrichResponse} from "./utils";
import {mapMarket, mapPortfolio, sortMarkets} from "../mappers/";
import {BigNumber} from "ethers";
import {getOracleRates} from "./oracleService";

const { viewContractAddress } = config

// const readContractWrapper = async <T>(config: ReadContractConfig<any, string>): Promise<T> => {
//   const cacheKey = 'api_cache_' + config.functionName + config.args.map(item => {
//     if(typeof item === 'string' || item instanceof BigNumber || typeof item === 'number') {
//       return item
//     }
//     return ''
//   }).join('')
//
//   const cacheValue = getCacheValue(cacheKey)
//
//   if(cacheValue) {
//     return enrichResponse(cacheValue)
//   } else {
//     const result = await readContract(config)
//     writeCacheValue(cacheKey, result)
//     return enrichResponse(result)
//   }
// }

export const getMarketsOraclePackages = async (): Promise<MarketOraclePackages[]> => {
  const marketIds = await allActiveMarketsIds()
  const oracleRecords = await getOracleRates()
  return marketIds.map(marketId => {
    const record = oracleRecords.find(item => item.oraclePackage.marketId === marketId)
    return {
      marketId,
      packages: record ? [record.oraclePackage] : []
    }
  })
}

export const getPortfolioOraclePackages = async (userAddress: string): Promise<MarketOraclePackages[]> => {
  const marketIds = await portfolioMarketIds(userAddress)
  const oracleRecords = await getOracleRates()
  return marketIds.map(marketId => {
    const record = oracleRecords.find(item => item.oraclePackage.marketId === marketId)
    return {
      marketId,
      packages: record ? [record.oraclePackage] : []
    }
  })
}

export const allActiveMarketsIds = async (offset = 0, limit = 100): Promise<string[]> => {
  const data = await readContract({
    address: viewContractAddress,
    abi: ViewABI,
    functionName: 'allActiveMarketsIds',
    args: [offset, limit],
    chainId: config.chainId
  })
  return enrichResponse(data)
}

export const getActiveMarketsInfo = async (
  oraclePackages: MarketOraclePackages[] = [],
  offset = 0,
  limit = 100
): Promise<MarketInfo[]> => {
  let markets = await readContract({
      address: viewContractAddress,
      abi: ViewABI,
      functionName: 'activeMarketsInfo',
      args: [offset, limit, oraclePackages],
      chainId: config.chainId
   }) as MarketInfo[]

  markets = enrichResponse(markets)
    .map(mapMarket)
    .sort(sortMarkets)

  // Remove paused futures and markets with 0 futures
  markets = markets.map((market) => {
    const futures = market.futures.filter(future => {
      return !future.pauseConfiguration.trade
    })
    return {
      ...market,
      futures
    }
  })
  .filter(market => {
    return market.futures.length > 0
  })

  return markets
}

export const portfolioMarketIds = async (
  userAddress: string,
  offset = 0,
  limit = 100
): Promise<string[]> => {
  const data = await readContract({
    address: viewContractAddress,
    abi: ViewABI,
    functionName: 'portfolioMarketIds',
    args: [userAddress, offset, limit],
    chainId: config.chainId
  })
  return enrichResponse(data)
}

export const getPortfolio = async (
  userAddress: string,
  oraclePackages: MarketOraclePackages[],
  offset = 0,
  limit = 100
): Promise<MarketPortfolio[]> => {
  let portfolioItems = await readContract({
    address: viewContractAddress,
    abi: ViewABI,
    functionName: 'portfolio',
    args: [userAddress, offset, limit, oraclePackages],
    chainId: config.chainId
  }) as MarketPortfolio[]
  portfolioItems = portfolioItems.map(mapPortfolio)
  return enrichResponse(portfolioItems)
}


export const getMarginDetails = async (
  marketId: string,
  userAddress: string,
  oraclePackages: OraclePackage[]
): Promise<MarginState> => {
  const data = await readContract({
    address: viewContractAddress,
    abi: ViewABI,
    functionName: 'marginDetails',
    args: [marketId, userAddress, oraclePackages],
    chainId: config.chainId
  })
  return enrichResponse(data)
}

export const getPoolLiquidityDistribution = async (
  futureId: string,
  oraclePackages: OraclePackage[],
  offset = 0,
  limit = 500
): Promise<LiquidityDistribution> => {
  // @ts-ignore
  const [ provisionDistribution, currentFutureRate, intervalLiquidity ] = await readContract({
    address: viewContractAddress,
    abi: ViewABI,
    functionName: 'poolLiquidityDistribution',
    args: [futureId, oraclePackages, offset, limit],
    chainId: config.chainId
  })

  return {
    provisionDistribution: enrichResponse(provisionDistribution),
    currentFutureRate: enrichResponse(currentFutureRate),
    intervalLiquidity: enrichResponse(intervalLiquidity)
  }
}

export const getMakerLiquidityDistribution = async (
  futureId: string,
  maker: string,
  offset = 0,
  limit = 500
): Promise<MakerLiquidityDistribution> => {
  // @ts-ignore
  const [currentFutureRate, intervals] = await readContract({
    address: viewContractAddress,
    abi: ViewABI,
    functionName: 'makerLiquidityDistribution',
    args: [futureId, maker, offset, limit],
    chainId: config.chainId
  })

  return {
    currentFutureRate: enrichResponse(currentFutureRate),
    intervalLiquidity: enrichResponse(intervals)
  }
}

export const getWithdrawableMargin = async (
  marketId: string,
  participant: string,
  oraclePackages: OraclePackage[],
): Promise<BigNumber> => {
  const data = await readContract({
    address: viewContractAddress,
    abi: ViewABI,
    functionName: 'withdrawableMargin',
    args: [marketId, participant, oraclePackages],
    chainId: config.chainId
  }) as bigint
  return enrichResponse(data)
}

