import {Box, BoxProps, Text} from 'grommet'
import {FormError} from "../types";
import React, {CSSProperties, ReactNode} from "react";
import { InputNumber, InputNumberProps } from 'antd';
import {QuestionMark} from "./QuestionMark";
import {getIconByName} from "./Icon";
import styled from "styled-components";

export interface NumberInputProps extends InputNumberProps {}
export type ValueType = string | number | undefined | null

const formatterHelper = (value: ValueType) => {
  const valueStr = (value || '').toString()
  const [decimalPart, fractionalPart = ''] = valueStr.split('.')
  const decimalFormatted = `${decimalPart}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const fractionalFormatted = fractionalPart ? `.${fractionalPart}` : ''
  return decimalFormatted + fractionalFormatted
}
const parserHelper = (value: ValueType) => `${value}`.replace(/\$\s?|(,*)/g, '')

export const NumberInput = (props: NumberInputProps) => {
  const {
    defaultValue = 0,
    stringMode = true,
    decimalSeparator = ',',
    min = 0,
    max = 1000_000_000_000,
    formatter = formatterHelper,
    parser = parserHelper
  } = props

  const defaultProps = {
    defaultValue,
    stringMode,
    decimalSeparator,
    min,
    max,
    formatter,
    parser
  }

  return <InputNumber
    {...defaultProps}
    {...props}
  />
}

export interface ErrorMessageProps {
  formError: FormError
  onClick?: (event: React.MouseEvent<Element>) => void
}
export const FormErrorMessage = (props: ErrorMessageProps) => {
  const { formError, onClick } = props

  return <Box style={{ position: 'relative', marginBottom: '15px' }}>
    <Box
      onClick={onClick}
      style={{ position: 'absolute', left: '10px', bottom: '-22px', width: '100%', height: '22px' }}
    >
      <Box direction={'row'} align={'center'} gap={'6px'}>
        <Text
          color={'errorMessage'}
          size={'small'}
          style={{ width: 'inherit', wordBreak: 'break-word' }}>
          {formError.text}
        </Text>
        {formError.tooltipText &&
            <QuestionMark tooltipId={formError.type + formError.field} tooltipText={formError.tooltipText} />
        }
      </Box>
    </Box>
  </Box>
}

export interface FormMessageProps {
  text: string
  onClick: (event: React.MouseEvent<Element>) => void
}

export const FormMessage = (props: FormMessageProps) => {
  const { text, onClick } = props

  return <Box style={{ position: 'relative' }}>
    <Box
      onClick={onClick}
      style={{ position: 'absolute', bottom: '-22px', width: '250px', height: '22px' }}
    >
      <Text
        size={'small'}
        style={{ width: 'inherit', wordBreak: 'break-word' }}>
        {text}
      </Text>
    </Box>
  </Box>
}

const InputWrapperContainer = styled(Box)<{ isActive?: boolean }>`
    border: 1px solid transparent;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    transition: border-bottom-color 200ms, border-top-color 200ms, border-left-color 200ms, border-right-color 200ms;
    &:hover {
        border-color: #8186E4;
    }

    ${props => props.isActive && `
      border-color: #8186E4;
    `}
`

export const InputWrapper = (props: BoxProps & { children: ReactNode; style?: CSSProperties }) => {
  return <InputWrapperContainer
    background={'inputBg'}
    pad={props.pad || '10px'}
    round={'6px'}
    style={props.style}
    {...props}
  >
    {props.children}
  </InputWrapperContainer>
}

export interface TokenAmountInputProps extends InputNumberProps {
  id?: string
  title?: string
  description?: string
  options?: { text: string }[]
  bottompanel?: ReactNode
  width?: string
}

const InputNumberContainer = styled(InputNumber)`
    border-color: transparent;
    
    input {
        padding: 0!important;
    }

    input:hover {
        border-color: inherit;
    }
`

export const TokenAmountInput = (props: TokenAmountInputProps) => {
  const {
    defaultValue = 0,
    stringMode = true,
    autoFocus = true,
    decimalSeparator = ',',
    min = 0,
    max = 1000_000_000_000,
    formatter = formatterHelper,
    parser = parserHelper,
    options = [],
    bottompanel,
  } = props

  const defaultProps = {
    defaultValue,
    stringMode,
    decimalSeparator,
    min,
    max,
    formatter,
    parser,
  }

  const hasTitleAndDescription = props.title && props.description
  const inputPad = hasTitleAndDescription
    ? '10px 10px 4px 12px'
    : '10px'

  return <InputWrapper gap={'4px'} pad={'0'}>
    <Box direction={'row'} align={'center'} justify={'between'}>
      <Box pad={inputPad}>
        {hasTitleAndDescription &&
            <Box direction={'row'} align={'center'} gap={'4px'}>
                <Text size={'13px'} color={'#7B7C8E'} weight={400}>{props.title}</Text>
                <QuestionMark tooltipId={props.id || props.title} tooltipText={props.description} />
            </Box>
        }
        <InputNumberContainer
          {...defaultProps}
          {...props}
          controls={false}
          autoFocus={autoFocus}
          status={''}
          style={{
            width: props.width || '100%',
            minWidth: '70%',
            border: 'none',
            background: '#19191A',
            fontSize: '18px'
          }}
        />
      </Box>
      {options.length > 0 &&
          <Box margin={{ right: '16px' }}>
            {options.map((option, index) => {
              return <Box key={option.text + index} direction={'row'} gap={'10px'} align={'center'}>
                <Box>
                  {getIconByName(option.text, '24px').icon}
                </Box>
                <Text size={'16px'}>{option.text}</Text>
              </Box>
            })}
      </Box>
      }
    </Box>
    {bottompanel && bottompanel}
  </InputWrapper>
}
