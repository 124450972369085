import React, {useState} from 'react'
import {Box, Text} from "grommet";
import {NumberInput, NumberInputProps} from "./Input";
import styled from "styled-components";
import bn from "bignumber.js";
import useInterval from "../hooks/useInterval";

enum ControlAction {
  decrease = 'decrease',
  increase = 'increase'
}

const ControlButtonContainer = styled(Box)<{
  action: ControlAction;
  disabled?: boolean
}>`
  height: 100%;
  background-color: #26262C;
  cursor: pointer;
  user-select: none;
  transition: background-color 100ms;

  ${props => props.action === ControlAction.decrease ? `
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  ` : `
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  `}

  ${props => props.disabled && `
    opacity: 0.3;
    pointer-events: none;
  `}
  
  &:hover {
    background: #2C2F3F;
  }

  &:active {
    background: #232631;
  }
`

interface ControlButtonProps {
  disabled?: boolean
  action: ControlAction
  onClick: () => void
  onMouseUp: () => void
  onMouseDown: () => void
}

const ControlButton = (props: ControlButtonProps) => {
  const { disabled = false, action, onClick, onMouseUp, onMouseDown } = props

  return <ControlButtonContainer
    disabled={disabled}
    width={'28px'} justify={'center'} align={'center'} action={action}
    onClick={disabled ? undefined : onClick}
    onMouseUp={onMouseUp}
    onMouseDown={onMouseDown}
  >
    <Text size={'16px'} color={'textInputSecondary'}>{action === 'decrease' ? '-' : '+'}</Text>
  </ControlButtonContainer>
}

const IntervalDefault = 0.5
const LowerRateDefault = -5
const UpperRateDefault = 100

export interface RatesInputProps extends NumberInputProps {
  lowerBoundRate?: number
  upperBoundRate?: number
  intervalLength?: number
  onChangeValue: (value: string) => void
}

export const RatesInput = (props: RatesInputProps) => {
  const {
    value,
    lowerBoundRate = LowerRateDefault,
    upperBoundRate = UpperRateDefault,
    intervalLength = IntervalDefault
  } = props

  const [actionStartedDate, setActionStartedDate] = useState(0)
  const [actionRunning, setActionRunning] = useState<ControlAction | null>(null)

  const getIntervalTimer = () => {
    if(actionRunning) {
      if(Date.now() - actionStartedDate > 1000) {
        return 100 // Regular update interval
      }
      return 500 // Pause before the first update
    }
    return null
  }

  const getCurrentStep = () => {
    if(Math.abs(+(value || 0)) < upperBoundRate - 10) {
      if(Date.now() - actionStartedDate > 10000) {
        return intervalLength * 10
      }
      if(Date.now() - actionStartedDate > 2000) {
        return intervalLength * 2
      }
    }
    return intervalLength
  }

  useInterval(() => {
    if(actionRunning) {
      onControlClicked(actionRunning, getCurrentStep())
    }
  }, getIntervalTimer())

  const onChange = (value: string | number | null) => {
    setNewValue((value || 0).toString(), false)
  }

  const setNewValue = (v: string, checkBounds = true) => {
    const value = bn(v || '0')
    if(checkBounds) {
      if(value.gte(bn(lowerBoundRate)) && value.lte(bn(upperBoundRate))) {
        props.onChangeValue(v)
      }
    } else {
      props.onChangeValue(v)
    }
  }

  const onControlClicked = (action: ControlAction, step = intervalLength) => {
    let initialValue = bn(value || '0')
    if(step >= intervalLength * 2) {
      initialValue = initialValue.dp(0)
    }
    const currentStep = action === ControlAction.decrease
      ? bn(-step)
      : bn(step)
    const nextValue = initialValue.plus(currentStep)
      .toFormat(1)
      .toString()
    setNewValue(nextValue)
  }

  const onMouseUp = () => {
    setActionRunning(null)
  }

  const onMouseDown = (action: ControlAction) => {
    setActionRunning(action)
    setActionStartedDate(Date.now())
  }

  const onFocus = () => {
    setActionRunning(null)
  }

  const onBlur = () => {
    let initialValue = bn(value || '0')
    const modulo = initialValue.mod(intervalLength).toNumber()
    if(modulo !== 0) {
      const delta = intervalLength - Math.abs(modulo)
      if(delta < intervalLength / 2 ) {
        initialValue = modulo > 0 ? initialValue.plus(delta) : initialValue.minus(delta)
      } else {
        initialValue = modulo > 0 ? initialValue.minus(modulo) : initialValue.minus(modulo)
      }
    }

    initialValue = bn.min(initialValue, upperBoundRate)
    initialValue = bn.max(initialValue, lowerBoundRate)

    const nextValue = initialValue.toFormat(1)
      .toString()
    setNewValue(nextValue)
    setActionRunning(null)
  }

  return <Box
    height={'36px'}
    direction={'row'}
    justify={'between'}
    align={'center'}
    border={{ size: '1px', color: '#383D57' }}
    round={'3px'}
    background={'#26262C'}
  >
    <ControlButton
      disabled={+(value || 0) <= lowerBoundRate}
      action={ControlAction.decrease}
      onClick={() => onControlClicked(ControlAction.decrease)}
      onMouseUp={() => onMouseUp()}
      onMouseDown={() => onMouseDown(ControlAction.decrease)}
    />
    <Box width={'60px'} direction={'row'} style={{ position: 'relative' }}>
      <NumberInput
        value={value}
        controls={false}
        min={lowerBoundRate}
        max={upperBoundRate}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        style={{ fontSize: '14px', fontWeight: 300, border: 'none' }}
      />
      <Box
        height={'100%'}
        align={'center'}
        justify={'center'}
        style={{ position: 'absolute', right: '2px' }}
      >
        <Text size={'14px'}>%</Text>
      </Box>
    </Box>
    <ControlButton
      disabled={+(value || 0) >= upperBoundRate}
      action={ControlAction.increase}
      onClick={() => onControlClicked(ControlAction.increase)}
      onMouseUp={() => onMouseUp()}
      onMouseDown={() => onMouseDown(ControlAction.increase)}
    />
  </Box>
}
