import React from 'react'
import {Box, Table, TableBody, TableCell, TableHeader, TableRow, Text} from "grommet";
import styled from "styled-components";
import {FutureOpenPosition, RiskDirection, RiskDirectionType} from "../../../../types";
import {DynamicValue, Number, QuestionMark} from "../../../../components";
import tooltips from "../../../../utils/tooltips";
import {marginTotal} from "../../../../utils/mappers";
import {BigNumber} from "ethers";
import {bnToDecimal, getFutureAlias, getLiquidationRate, getShortDate, numberToBN} from "../../../../utils";
import {useAccount} from "wagmi";
import {useNavigate} from "react-router-dom";
import {RiskDirectionTag} from "../../../../components/tag";
import {useProtocolData} from "../../../../providers/ProtocolDataProvider";
import { Decimal } from 'decimal.js'
import {OpenPosition} from "../../../../api/dataService";
import {SelectMarket} from "../../../../components/market-select";
import {ViewType} from "../../../../app.types";

const TableContainer = styled(Table)`
    thead {
      th {
        color: #838495;
        font-size: 14px;
        font-weight: 500;
        border: none;
      }
    }

    tr {
        border-top: 0!important;
    }

    //td:first-child, th:first-child {
    //  border-top: 0 !important;
    //  padding: 0;
    //}

    td {
        border-top: 1px dashed ${
  props => props.theme.global.colors.tableRowBorder
}!important;
    }

    th:last-child, td:last-child {
        padding-right: 0;
    }
`

const TableMarkerCell = styled(TableCell)<{ isActive: boolean }>`
    padding: 0 !important;
    ${props => props.isActive &&
  `
        border-left: 3px solid ${props.theme.global.colors.brandRho};
      `
}
`

const zero = BigNumber.from(0)

export const OpenPositions = (props: {
  viewType: ViewType
  market?: SelectMarket
  positions: OpenPosition[]
  isActivePositions?: boolean
}) => {
  const navigate = useNavigate()
  const { isConnected } = useAccount()

  const { viewType, positions } = props

  const {
    markets,
    portfolio,
    marketsUserData
  } = useProtocolData()

  const futures = portfolio.flatMap(item => item.futures)

  return <Box
    style={{ overflowX: viewType === 'mobile' ? 'auto' : 'unset' }}
  >
    <TableContainer>
      <TableHeader style={{ height: '36px' }}>
        <TableRow>
          {/*<TableCell size={'16px'} scope="col" />*/}
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>INSTRUMENT/RATE</Text>
              <QuestionMark tooltipId={'instrument_tooltip'} tooltipText={tooltips.instrument} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>DIRECTION</Text>
              <QuestionMark tooltipId={'risk_dir_tooltip'} tooltipText={tooltips.riskDirection} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>NOTIONAL</Text>
              <QuestionMark tooltipId={'open_notional'} tooltipText={tooltips.notional} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>AVG. RATE</Text>
              <QuestionMark tooltipId={'avg_rate_tooltip'} tooltipText={tooltips.averageRate} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>LIQ. RATE</Text>
              <QuestionMark tooltipId={'liq_rate_tooltip'} tooltipText={tooltips.liquidationRate} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>LEVERAGE</Text>
              <QuestionMark tooltipId={'leverage_tooltip'} tooltipText={tooltips.futureLeverage} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>DV01</Text>
              <QuestionMark tooltipId={'dv01_tooltip'} tooltipText={tooltips.dv01} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>P&L</Text>
              <QuestionMark tooltipId={'pnl_tooltip'} tooltipText={tooltips.profitAndLoss} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>MARGIN REQ.</Text>
              <QuestionMark tooltipId={'margin_req_tooltip'} tooltipText={tooltips.marginRequired} />
            </Box>
          </TableCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {positions.map((item, index) => {
          const { futureId, maturity, direction } = item

          const future = futures.find((item) => item.id === futureId)
          const market = markets.find(item => item.descriptor.id === future?.marketId)
          const marketPortfolio = portfolio.find(item => item.descriptor.id === future?.marketId)
          const underlyingDecimals = market?.descriptor.underlyingDecimals || 6

          let averageRate = ''
          if(marketsUserData && futureId) {
            const marketData = marketsUserData.find(item => !!item.futures[futureId])
            if(marketData) {
              const futureData = marketData.futures[futureId]
              if(futureData.averageRatePosition) {
                averageRate = new Decimal(futureData.averageRatePosition)
                  .div(10 ** 16)
                  .toDecimalPlaces(2)
                  .toString()
              }
            }
          }

          let liquidationRate = ''
          if(marketPortfolio && market && future) {
            const riskDirection = marketPortfolio.marginState.riskDirection
            const liquidationRateNumber = getLiquidationRate(
              market,
              future,
              marketPortfolio,
              {
                notional: '0',
                collateral: '0',
                riskDirection: riskDirection === RiskDirectionType.RECEIVER ? RiskDirection.receiver : RiskDirection.payer,
                isMaxRateLimitAuto: true,
                maxRateLimit: '0',
                isNativeTokenSelected: false
              },
            )
            liquidationRate = new Decimal(liquidationRateNumber)
              .toDecimalPlaces(2)
              .toString()
          }

          const marginFrom = marketPortfolio
            ? marginTotal(marketPortfolio.marginState.margin)
            : zero

          const underlyingName = market?.descriptor.underlyingName || 'N/A'

          const futureRate = future
            ? new Decimal(item.lastRate).div(10 ** 16).toDecimalPlaces(2)
            : new Decimal(0).toDecimalPlaces(2)

          let leverageFrom = marginFrom.gt(0)
            ? +BigNumber.from(item.notional).mul(100).div(marginFrom).toString()
            : 1
          leverageFrom = +(leverageFrom / 100).toFixed(1)

          const rowFutureAlias = market && future ? getFutureAlias(market, future) : ''
          const currentFutureAlias = ''

          const onSelectFuture = () => {
            if(rowFutureAlias !== currentFutureAlias) {
              navigate(`/trade/${rowFutureAlias}`)
            }
          }

          const instrumentInfo = props.market === 'all' && market && future
            ? `${getFutureAlias(market, future)} (${futureRate}%)`
            : `${getShortDate(maturity * 1000)} (${futureRate}%)`

          return <TableRow
            key={item.futureId + index}
            // onClick={rowFutureAlias !== currentFutureAlias ? onSelectFuture : undefined}
            style={{
              borderTop: '1px solid #383D57',
              cursor: rowFutureAlias !== currentFutureAlias ? 'pointer' : 'default'
            }}
          >
            {/*<TableMarkerCell size={'16px'} isActive={isActive} />*/}
            <TableCell>
              <Box>
                <Text>{instrumentInfo}</Text>
              </Box>
            </TableCell>
            <TableCell>
              <Box width={'50px'}>
                <RiskDirectionTag direction={direction} />
              </Box>
            </TableCell>
            <TableCell>
              <Number
                value={BigNumber.from(item.notional)}
                decimals={underlyingDecimals}
                name={underlyingName}
                showName={true}
              />
            </TableCell>
            <TableCell>
              <Text>{averageRate ? `${averageRate}%` : '-'}</Text>
            </TableCell>
            <TableCell>
             <Text>{liquidationRate ? `${liquidationRate}%` : '-'}</Text>
            </TableCell>
            <TableCell>
              <Box direction={'row'} align={'center'} gap={'2px'}>
                <Text>{isConnected ? `${leverageFrom.toString()}x` : '-'}</Text>
              </Box>
            </TableCell>
            <TableCell>
              <Number
                value={numberToBN(item.dv01, underlyingDecimals)}
                decimals={underlyingDecimals}
                name={underlyingName}
                showName={true}
              />
            </TableCell>
            <TableCell>
              <DynamicValue
                value={numberToBN(item.position.profitAndLoss, underlyingDecimals)}
                decimals={underlyingDecimals}
                name={underlyingName}
                showName={true}
              />
            </TableCell>
            <TableCell>
              <Number
                value={numberToBN(item.mr, underlyingDecimals)}
                decimals={underlyingDecimals}
                name={underlyingName}
                showName={true}
              />
            </TableCell>
          </TableRow>
        })}
      </TableBody>
    </TableContainer>
  </Box>
}
