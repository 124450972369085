import {prepareFormNumber} from "../../utils";
import {FormError, FormErrors} from "../../types";
import {ManageMarginParams} from "./index";

export const getFormError = (params: ManageMarginParams): FormError | null => {
  const {
    formValues,
    fromTokenBalance,
    fromTokenDecimals,
    // nativeTokenBalance,
    // underlyingBalance,
    withdrawableMargin
  } = params

  const formAmount = prepareFormNumber(formValues.amount, fromTokenDecimals)
  // const lmr = marginDetails ? marginDetails.lpMarginThreshold : BigNumber.from(0)
  // const margin = marginDetails ? marginTotal(marginDetails.margin) : BigNumber.from(0)

  if(formValues.operation === 'deposit') {
    if(!formValues.wrapNativeToken && fromTokenBalance.sub(formAmount).isNegative()) {
      return {
        field: 'amount',
        text: 'Insufficient funds',
        type: FormErrors.insufficientFunds
      }
    }
    if(formValues.wrapNativeToken && fromTokenBalance.sub(formAmount).isNegative()) {
      return {
        field: 'amount',
        text: 'Insufficient funds',
        type: FormErrors.insufficientFunds
      }
    }
  }

  if(formValues.operation === 'withdraw') {
    if(formAmount.gt(withdrawableMargin)) {
      return {
        field: 'amount',
        text: 'Insufficient margin to withdraw',
        type: FormErrors.insufficientFunds
      }
    }
  }

  return null
}
