import axios from 'axios'
import config from "../config";
import {RiskDirectionType} from "../types";
import {mapHistoryTrade} from "../mappers";

const client = axios.create({
  baseURL: `${config.dataServiceApiUrl}/api/v1`
})

export type DataInterval = '1m' | '5m' | '15m' | '30m' | '1h' | '4h' | '1d' | '1w' | string

export interface GetCandlesParams {
  futureId: string
  interval: DataInterval
  from: number
  to: number
}

export interface RateCandle {
  open: number
  close: number
  high: number
  low: number
  volume: number
  timestamp: string
  completed: boolean
}


export const getFutureRates = async (params: GetCandlesParams): Promise<RateCandle[]> => {
  const { data } = await client.get<{ candles: RateCandle[] }>(
    `/market-data/candles`, {
    params: {
      ...params
    }
  })
  return data.candles
}

export interface FutureMarketStats {
  rateChange: number
  high: number
  low: number
  volume: number
}

export const getFutureStats = async (params: { futureId: string, interval: DataInterval }): Promise<FutureMarketStats> => {
  const { data } = await client.get<{ info: FutureMarketStats }>(
    `/market-data/stats`, {
      params
    })
  return data.info
}

export interface Trade {
  txHash: string
  market: string
  maturity: number
  marketId: string
  futureId: string
  marketAddress: string
  futureAddress: string
  ownerAddress: string
  notionalAmount: string
  feeAmount: string
  rate: string
  direction: RiskDirectionType
  ownerIsMaker: boolean
  timestamp: number
  marketRateBefore: string
  marketRateAfter: string
  floatingIndex: string
}

export interface GetTradesResponse {
  cached: boolean
  trades: Trade[]
}

export interface BaseHistoryParams {
  userAddress?: string
  marketId?: string
  futureId?: string
  ownerIsMaker?: boolean
  before?: number
  count?: number
}

export const getTradesInfo = async (params: BaseHistoryParams) => {
  const {
    userAddress,
    marketId,
    futureId,
    ownerIsMaker,
    before = 0,
    count = 100
  } = params

  const { data } = await client.get<GetTradesResponse>(
    `/trades`,
    {
      params: {
        userAddress,
        marketId,
        futureId,
        ownerIsMaker,
        before,
        count,
      },
      headers: {
        'Accept': 'application/json'
      }
    },
  )
  return data.trades.map(mapHistoryTrade) || []
}

export interface UserFutureData {
  averageRatePayer: string
  averageRateReceiver: string
  averageRatePosition: string
}

export interface GetFutureUserDataResponse {
  cached: boolean
  userFutureData: UserFutureData
}

export const getFutureUserData = async (futureId: string, userId: string) => {
  const { data } = await client.get<GetFutureUserDataResponse>(`/future-user-data/${futureId}/${userId}`)
  return data.userFutureData
}

export interface Provision {
  txHash: string
  ownerAddress: string
  timestamp: number
  marketId: string
  marketAddress: string
  futureId: string
  futureAddress: string
  notionalAmount: string
  lowerBoundRate: string
  upperBoundRate: string
  market: string
  maturity: number
}

export interface GetProvisionsResponse {
  cached: boolean
  provisions: Provision[]
}

export const getProvisions = async (params: BaseHistoryParams) => {
  const {
    userAddress,
    marketId,
    futureId,
    before = 0,
    count = 100
  } = params

  const { data } = await client.get<GetProvisionsResponse>(
    `/provisions`,
    {
      params: {
        userAddress,
        marketId,
        futureId,
        before,
        count,
      },
      headers: {
        'Accept': 'application/json'
      }
    },
  )
  return data.provisions || []
}

export interface MarginUpdate {
  txHash: string
  ownerAddress: string
  timestamp: number
  marketId: string
  marketAddress: string
  marginDelta: string
  market: string
}

export type HistoryItem = Trade | Provision | MarginUpdate

export interface GetMarginUpdateResponse {
  cached: boolean
  marginUpdates: MarginUpdate[]
}

export const getMarginUpdates = async (params: BaseHistoryParams) => {
  const {
    before = 0,
    count = 100,
    ...rest
  } = params

  const { data } = await client.get<GetMarginUpdateResponse>(
    `/margin-updates`,
    {
      params: {
        ...rest,
        before,
        count,
      },
      headers: {
        'Accept': 'application/json'
      }
    },
  )
  return data.marginUpdates || []
}

export interface MarketFutureUserData {
  averageRatePayer: string
  averageRateReceiver: string
  averageRatePosition: string
  profitAndLoss: number
  openProfitAndLoss: number
  closedProfitAndLoss: number
}

export interface MarketUserData {
  profitAndLoss: number
  openProfitAndLoss: number
  closedProfitAndLoss: number
  futures: Record<string, MarketFutureUserData>
}

export interface MarketUserDataState extends MarketUserData {
  marketId: string
}

export const getMarketUserData = async (marketId: string, userId: string) => {
  const { data } = await client.get<{
    cached: boolean
    data: MarketUserData
  }>(`/market-user-data/${marketId}/${userId}`)

  return data.data
}

interface TokenPrice {
  name: string
  priceUsd: number
  lastUpdated: string
}

interface TokenPriceResult {
  [key: string]: {
    [key: string]: number
  }
}

export const getTokensPrice = async (tokens: string[], currency = 'usd'): Promise<TokenPriceResult> => {
  const { data } = await client.get<{ cached: boolean; prices: TokenPrice[] }>('/market-data/crypto-prices')

  const result: TokenPriceResult = {}

  data.prices.forEach(item => {
    result[item.name] = {
      "usd": item.priceUsd
    }
  })
  return result
}

interface OpenPositionResponse {
  position: {
    floatTokenAmount: string
    fixedTokenAmount: string
    floatTradeValue: string
    profitAndLoss: number
  }
  maturity: number
  direction: RiskDirectionType
  notional: string
  dv01: number
  lastRate: string
  mr: number
}

export interface OpenPosition extends OpenPositionResponse {
  futureId: string
}

interface ClosedPositionResponse {
  maturity: number
  lastRate: string
  profitAndLoss: number
}

export interface ClosedPosition extends ClosedPositionResponse {
  futureId: string
}

export interface UserPositionsResponse {
  positions: Record<string, {
    openPosition: OpenPositionResponse
    closedPosition: ClosedPositionResponse
    marketId: string
  }>
}

export interface UserPositions {
  futureId: string
  marketId: string
  openPosition: OpenPosition
  closedPosition: ClosedPosition
}

export const getUserPositions = async (userAddress: string): Promise<UserPositions[]> => {
  const { data } = await client.get<UserPositionsResponse>(`/positions/${userAddress}`)

  return Object.entries(data.positions)
    .map(([futureId, item]) => {
      return {
        futureId,
        marketId: item.marketId,
        openPosition: {
          futureId,
          ...item.openPosition
        },
        closedPosition: {
          futureId,
          ...item.closedPosition
        }
      }
    })
}

export type StatsPeriod = '1d' | '7d' | '30d' | '90d'
export const AllStatPeriods: StatsPeriod[] = ['1d', '7d', '30d', '90d']
export const StatsPeriodAlias: Record<StatsPeriod, string> = {
  '1d': '1D',
  '7d': '7D',
  '30d': '30D',
  '90d': '90D',
}

export interface BaseStatsParams {
  period?: StatsPeriod
}

export interface GetTotalVolumeResponse {
  totalVolume: string
}

export const getTotalVolume = async (params: BaseStatsParams = {}, signal?: AbortSignal) => {
  const { data } = await client.get<GetTotalVolumeResponse>(`/stats/volume`, {
    params,
    signal
  })
  return data.totalVolume
}

export interface GetTotalTradersResponse {
  TotalTraders: string
}

export const getTotalTraders = async (params: BaseStatsParams = {}, signal?: AbortSignal) => {
  const { data } = await client.get<GetTotalTradersResponse>(`/stats/traders`, {
    params,
    signal
  })
  return data.TotalTraders
}

export interface GetTotalFeesResponse {
  totalFees: string,
  side: 'maker' | 'taker'
}

export const getTotalFees = async (params: BaseStatsParams = {}, signal?: AbortSignal) => {
  const { data } = await client.get<GetTotalFeesResponse[]>(`/stats/fees`, {
    params,
    signal
  })
  return data
}

export interface StatsRunningTotal {
  running_total: string
  running_trades: string
  date: string
}

export const getRunningTotal = async (params: BaseStatsParams = {}, signal?: AbortSignal) => {
  const { data } = await client.get<StatsRunningTotal[]>(`/stats/running-total`, {
    params,
    signal
  })
  return data
}

export interface FeesDaily {
  runningTotal: string
  totalFees: string
  side: 'taker' | 'maker'
  date: string
}

export const getFeesByDays = async (params: BaseStatsParams = {}, signal?: AbortSignal) => {
  const { data } = await client.get<FeesDaily[]>(`/stats/fees-by-days`, {
    params,
    signal
  })
  return data
}

export interface OpenInterest {
  openInterest: string
  totalPositions: string
}

export const getCurrentOpenInterest = async (params: BaseStatsParams = {}, signal?: AbortSignal) => {
  const { data } = await client.get<OpenInterest>(`/stats/current-oi`, {
    params,
    signal
  })
  return data
}

export interface OpenInterestDaily extends OpenInterest {
  date: string
}

export const getOpenInterestDaily = async (params: BaseStatsParams = {}, signal?: AbortSignal) => {
  const { data } = await client.get<OpenInterestDaily[]>(`/stats/open-interest-daily`, {
    params,
    signal
  })
  return data
}

export interface StatsMarginUsage {
  blockNum: number
  timestamp: number
  marketId: string
  totalIMR: string
  totalCollateral: string
  marginUsage: string
}

export const getMarginUsage = async (params: BaseStatsParams = {}, signal?: AbortSignal) => {
  const { data } = await client.get<StatsMarginUsage[]>(`/stats/margin-usage`, {
    params,
    signal
  })
  return data
}

export interface StatsVolumeByMarket {
  usdVolume: string
  marketID: string
  marketName: string
  instrumentName: string
}

export const getVolumeByMarket = async (params: BaseStatsParams = {}, signal?: AbortSignal) => {
  const { data } = await client.get<StatsVolumeByMarket[]>(`/stats/volume-by-market`, {
    params,
    signal
  })
  return data
}

export interface StatsOpenInterestByMarket {
  openInterest: number
  marketId: string
  marketName: string
  instrument_name: string
}

export const getOpenInterestByMarket = async (params: BaseStatsParams = {}, signal?: AbortSignal) => {
  const { data } = await client.get<StatsOpenInterestByMarket[]>(`/stats/oi-by-market`, {
    params,
    signal
  })
  return data
}

export interface UserMarginHistoryParams {
  from: number
  to?: number
  interval: DataInterval
}

export interface MarginHistoryPoint {
  openPositionFutureValue: string
  totalFutureValue: string
  collateral: string
  incurredFees: string
  lpFees: string
  timestamp: string
}

export const getUserMarginHistory = async (
  userAddress: string,
  params: UserMarginHistoryParams,
  signal?: AbortSignal
) => {
  const { data } = await client.get<{
    points: MarginHistoryPoint[]
  }>(`/user-margin-history/${userAddress}`, { params, signal })

  return data.points
}

export const getUserMarketMarginHistory = async (
  userAddress: string,
  marketId: string,
  params: UserMarginHistoryParams,
  signal?: AbortSignal
) => {
  const { data } = await client.get<{
    points: MarginHistoryPoint[]
  }>(`/market-user-margin-history/${marketId}/${userAddress}`, { params, signal })

  return data.points
}
