import React, {useState} from 'react'
import {getConfigMode, setConfigMode} from "../../config";
import {Tag, Tooltip} from "antd";
import {Box, Text, Button} from "grommet";
import {useLocation, useNavigate} from "react-router-dom";
import {WidgetContainer} from "../widgets";

const configMode = getConfigMode()
const isMainnet = configMode === 'mainnet'

export const NetworkTag = () => {
  const [isOpen, setOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation();

  const content = <WidgetContainer gap={'8px'} border={{ color: 'transparent' }}>
    <Text color={'white'}>The app is running in {isMainnet ? 'mainnet' : 'testnet'} mode</Text>
    <Button onClick={() => {
      setConfigMode(isMainnet ? 'testnet' : 'mainnet')
      navigate(location.pathname)
      navigate(0)
    }}>Switch to {isMainnet ? 'testnet' : 'mainnet'}</Button>
  </WidgetContainer>

  const tagColor = isMainnet ? 'geekblue' : 'cyan'

  return <Tooltip
    title={content}
    placement={'bottom'}
    trigger={'click'}
    open={isOpen}
    color={'#1E1E20'}
    onOpenChange={(isOpened) => {
      setOpen(isOpened)
    }}
  >
    <Tag color={tagColor} style={{ cursor: 'pointer', padding: '0', borderRadius: '3px' }}>
      <Box pad={'2px 3px'} onClick={() => setOpen(!isOpen)} round={'3px'}>
        <Text size={'10px'}>{isMainnet ? 'MAINNET' : 'TESTNET'}</Text>
      </Box>
    </Tag>
  </Tooltip>
}
