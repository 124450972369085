import {useEffect, useState} from "react";
import {getMarketUserData, MarketUserDataState} from "../api/dataService";
import usePoller from "./usePoller";

export const useMarketsUserData = (params: {
  marketIds: string[]
  userAddress?: string
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [ marketsUserData, setMarketsUserData ] = useState<MarketUserDataState[]>([])

  const loadData = async (updateLoadingStatus = true) => {
    try {
      if(params.userAddress && params.marketIds.length > 0) {
        if(updateLoadingStatus) {
          setIsLoading(true)
        }
        // console.log(`useMarketsUserData: markets length: ${params.marketIds.length}, started loading data...`)
        const data: MarketUserDataState[] = await Promise.all(params.marketIds.map(async (marketId) => {
          const marketData = await getMarketUserData(marketId, params.userAddress as string)
          return {
            ...marketData,
            marketId,
          }
        }))
        setMarketsUserData(data)
        // console.log('useMarketsUserData: loaded markets user data', data, params.marketIds.length)
      } else {
        setMarketsUserData([])
      }
    } catch (e) {
      console.error('useMarketsUserData: failed to load data:', e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadData()
  }, [params.userAddress, params.marketIds.length])

  return {
    isLoading,
    marketsUserData,
    refetch: loadData
  }
}
