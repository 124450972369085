import React, { createContext, useContext, useState, PropsWithChildren, useEffect } from 'react';
import usePoller from "../hooks/usePoller";
import {getOracleRates, OracleRecord} from "../api/oracleService";

const ratesLocalStorageKey = 'rho_rates_oracle_v1'

const getInitialState = (): OracleRecord[] => {
  try {
    const rawData = localStorage.getItem(ratesLocalStorageKey)
    if (rawData) {
      const data = JSON.parse(rawData)
      return data as OracleRecord[]
    }
  } catch (e) { }
  return [];
}

const initialState = getInitialState()
const RatesContext = createContext(initialState);

// TODO: to config
const pollingInterval = 10 * 1000

export const useRates = () => useContext(RatesContext);

export interface FloatRate {
  tag: string
  rate: number
  delta: number
}

export const formatFloatRates = (
  { rates, sourceName, tag, marketId }: {
    rates: OracleRecord[],
    sourceName: string,
    tag: string,
    marketId: string
  }
): FloatRate | null => {
  const rateItem = rates.find(rate => rate.oraclePackage.marketId === marketId)
  if(rateItem) {
    let rate = +rateItem.latestRate
    // if(tag === 'funding rate') {
    //   rate = rate * 365 * 3
    // }
    return {
      tag,
      rate,
      delta: +rateItem.rateDelta
    }
  }

  console.log(sourceName, tag, 'not found float rate on backend')

  return null
}

export const RateProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [rates, setRates] = useState<OracleRecord[]>(initialState)

  const updateLocalStorageCache = () => {
    try {
      localStorage.setItem(ratesLocalStorageKey, JSON.stringify(rates))
    } catch (e) { }
  }

  useEffect(() => {
    updateLocalStorageCache()
  }, [rates])

  const getData = async () => {
    try {
      const oracleRates = await getOracleRates()
      setRates(oracleRates)
    } catch (e) {
      console.error(`RateProvider: cannot get rates:`, e)
    }
  }

  usePoller(getData, pollingInterval)

  return <RatesContext.Provider value={rates}>
    {children}
  </RatesContext.Provider>
};
