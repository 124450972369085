import {readContract} from "wagmi/actions";
import QuoterABI from "../abi/QuoterABI.json";
import {
  LiquidityQuote,
  OraclePackage,
  TradeQuote
} from "../types";
import config from '../config'
import {enrichResponse} from "./utils";
import {BigNumber} from "ethers";

const { quoterContractAddress } = config

export const getTradeQuote = async (
  futureId: string,
  notional: BigNumber,
  userAddress: string,
  oraclePackages: OraclePackage[]
): Promise<TradeQuote> => {
  const data = await readContract({
    address: quoterContractAddress,
    abi: QuoterABI,
    functionName: 'quoteTrade',
    args: [futureId, notional, userAddress, oraclePackages],
    chainId: config.chainId
  })
  return enrichResponse(data)
}

export const getLPQuote = async (
  futureId: string,
  notional: BigNumber,
  userAddress: string,
  operation: '0' | '1',
  swapRateMin: BigNumber,
  swapRateMax: BigNumber,
  oraclePackages: OraclePackage[]
): Promise<LiquidityQuote> => {
  const data = await readContract({
    address: quoterContractAddress,
    abi: QuoterABI,
    functionName: 'quoteLiquidityProvision',
    args: [futureId, notional, userAddress, operation, swapRateMin, swapRateMax, oraclePackages],
    chainId: config.chainId
  })
  return enrichResponse(data)
}
