import React from 'react'
import {Box, Text} from "grommet";

const countriesList = [
  'Crimea, Donetsk, Luhansk, and Sevastopol regions of Ukraine',
  'Cuba',
  'Iran',
  'North Korea',
  'Syria',
  'Canada',
  'United States',
  'Micronesia (US associated territory)',
  'Guam (US associated territory)',
  'Marshall Islands (US associated territory)',
  'Northern Mariana Islands (US associated territory)',
  'Puerto Rico (US associated territory)',
  'Palau (US associated territory)',
  'US Minor Outlying Islands',
  'US Virgin Islands'
]

const personList = [
  '“U.S. person” (as defined under U.S. securities and commodity futures laws, and as described in more detail in Rho Protocol\'s Terms of Service);',
  '“Canadian person” (as defined under Canadian law) or',
  '“Singapore person” (as defined under Singapore law)'
]

export const RestrictedPersons = () => {
  return <Box width={'700px'} margin={{ top: '64px' }} justify={'start'} gap={'16px'}>
    <Text size={'38px'} weight={500} color={'white'}>Restricted Persons</Text>
    <Box>
      <Text>Rho Protocol does not provide products or services to:</Text>
    </Box>
    <Box>
      <Text>Any person (natural or legal) or territory that is the subject of economic sanctions or other trade or export restrictions imposed or administered by the United States, the European Union, the United Kingdom, Singapore or the United Nations. These restricted territories are currently:</Text>
    </Box>
    <Box>
      <ul style={{ marginTop: '0' }}>
        {countriesList.map(item => <li key={item}>{item}</li>)}
      </ul>
    </Box>
    <Box>
      <Text>Any person (natural or legal) that is a</Text>
    </Box>
    <Box>
      <ul style={{ marginTop: '0' }}>
        {personList.map(item => <li key={item}>{item}</li>)}
      </ul>
    </Box>
    <Box>
      <Text>
        All of the above are referred to as “Restricted Persons.” None of the above are eligible to utilize Rho Protocol’s products and services.  By using Rho Protocol’s products and services, you represent and warrant to Rho Protocol on an ongoing basis that you are not a Restricted Person.
      </Text>
    </Box>
  </Box>
}
