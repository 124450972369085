import React from 'react'
import {PrimaryButton} from "../../../components";
import {TradeButtonProps} from "../common";

export const TradeButton = (props: TradeButtonProps) => {
  const {
    disabled = false,
    status = 'default',
    inProgress = false,
    onClick
  } = props

  return <PrimaryButton
    status={status}
    disabled={disabled}
    loading={inProgress}
    text={inProgress ? 'Waiting for confirmation' : 'Trade'}
    onClick={onClick}
  />
}
