import {useMemo} from 'react';
import {useParams} from "react-router-dom";
import useMarkets from "./useMarkets";
import {getFutureByAlias} from "../utils";

const useCurrentFuture = () => {
  const params = useParams()
  const paramsFutureAlias = params.futureAlias || ''
  const { markets } = useMarkets()

  return useMemo(() => {
    let marketId = ''
    let futureId = ''

    if(paramsFutureAlias) {
      const data = getFutureByAlias(paramsFutureAlias, markets)
      if(data) {
        marketId = data.market.descriptor.id
        futureId = data.future.id
      }
    }

    return {
      marketId,
      futureId
    }
  }, [paramsFutureAlias, markets.length])
};

export default useCurrentFuture;
