import {Box, Text} from "grommet";
import {abbreviateNumber} from "../utils";
import React from "react";
import {Tooltip} from "react-tooltip";
import styled from "styled-components";
import {getIconByName} from "./Icon";
import tooltips from "../utils/tooltips";

const SliderInfoWrapper = styled(Box)`
  width: 100px;
  min-width: 100px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
`

const SliderMark = styled(Box)`
  position: absolute;
  width: 2px;
  height: 12px;
  top: -5px;
  border-radius: 2px;
`

const SliderPoint = (props: { marginLeft: string, name: string, value: string, tooltipContent: string, markColor?: string, position?: string }) => {
  const { marginLeft, name, value, tooltipContent, markColor = '#42475D', position = 'bottom' } = props

  const tooltipId = `slider-tooltip-${name}`
  const { icon: iconInfo } = getIconByName('info', '10px')

  return <Box
    width={'2px'}
    margin={{ left: marginLeft }}
    data-tooltip-id={tooltipId}
    data-tooltip-content={tooltipContent}
    style={{ position: 'relative', cursor: 'pointer' }}
  >
    <SliderMark background={markColor} />
    <SliderInfoWrapper margin={{ top: position === 'bottom' ? '20px' : '-20px' }}>
      <Box direction={'row'} justify={'center'} align={'center'} gap={'2px'}>
        <Text size={'12px'} color={'textSecondary'}>{name}</Text>
        {iconInfo}
      </Box>
      <Text size={'10px'} weight={500}>{value}</Text>
    </SliderInfoWrapper>
    <Tooltip
      id={tooltipId}
      place={position === 'bottom' ? 'top' : 'bottom'}
      border={'1px solid #454A55'}
      opacity={1}
    />
  </Box>
}

const getMin = (values: number[]) => {
  return values.reduce((acc, nextValue) =>
    nextValue < acc ? nextValue : acc, values.length ? values[0] : 0)
}

const getMax = (values: number[]) => {
  return values.reduce((acc, nextValue) =>
    nextValue > acc ? nextValue : acc, values.length ? values[0] : 0)
}

const getFormattedValue = (value: number) => {
  let decimalPlaces = undefined
  if(value < 10) {
    decimalPlaces = 2
  }
  return abbreviateNumber(value.toString(), decimalPlaces)
}

export const PortfolioStateSlider = (props: { lt: number, imr: number, cm: number, lmr?: number, coinName: string }) => {
  const { lt, imr, cm, lmr , coinName } = props

  const minOffset = 0.25 // Minimum offset from left and right corner, 10 percent of min value
  const allValues = [lt, imr, cm]
  if(lmr) {
    allValues.push(lmr)
  }

  let minValue = getMin(allValues)
  minValue -= minValue * minOffset
  let maxValue = getMax(allValues)
  maxValue += minValue * minOffset

  const calculateMargin = (value = 0) => {
    if(value < 0) {
      return 0
    }
    if(maxValue !== minValue && maxValue !== 0) {
      return Math.round(((value - minValue) / (maxValue - minValue)) * 100)
    }
    return 0
  }

  let ltMargin = calculateMargin(lt)
  let imrMargin = calculateMargin(imr)
  let cmMargin = calculateMargin(cm)
  let lmrMargin = calculateMargin(lmr)

  if(imrMargin - ltMargin < minOffset * 100) {
    imrMargin = imrMargin + minOffset * 100
  }

  if(lmrMargin < 100 - minOffset * 100) {
    // Show LMR after IMR
    if(lmrMargin - imrMargin < minOffset * 100) {
      lmrMargin = imrMargin + minOffset * 100
      // Move cm to right
      if(lmr && cm > lmr) {
        cmMargin += Math.min(minOffset * 100, 100 - cmMargin)
      }
    }
  } else {
    // Move IMR to left side
    if(lmrMargin - imrMargin < minOffset * 100) {
      imrMargin = imrMargin - minOffset * 100
    }
  }

  if(lt === imr && lt === 0) {
    ltMargin = 0
  }

  ltMargin = Math.min(ltMargin, 100)
  imrMargin = Math.min(imrMargin, 100)
  cmMargin = Math.min(cmMargin, 100)
  lmrMargin = Math.min(lmrMargin, 100)

  const ltTooltip = tooltips.liquidationThreshold
  const imrTooltip = tooltips.initialMargin
  const lmrTooltip = tooltips.liquidityMargin
  const cmTooltip = 'Current Margin: this is the sum of your collateral and position gains/losses. Keep an eye on it to avoid liquidation risk.'

  return <Box justify={'center'} style={{ minWidth: '260px' }}>
    <Box background={'#42475D'} height={'2px'} round={'2px'}>
      <SliderPoint
        name={'LT'}
        value={`${getFormattedValue(lt)} ${coinName}`}
        marginLeft={`${ltMargin}%`}
        tooltipContent={ltTooltip}
      />
      <SliderPoint
        name={'IMR'}
        value={`${getFormattedValue(imr)} ${coinName}`}
        marginLeft={`${imrMargin}%`}
        tooltipContent={imrTooltip}
      />
      {(typeof lmr !== 'undefined') &&
          <SliderPoint
              name={'LMR'}
              value={`${getFormattedValue(lmr)} ${coinName}`}
              marginLeft={`${lmrMargin}%`}
              tooltipContent={lmrTooltip}
          />
      }
      <SliderPoint
        name={'CM'}
        value={`${getFormattedValue(cm)} ${coinName}`}
        position={'top'}
        marginLeft={`${cmMargin}%`}
        tooltipContent={cmTooltip}
        markColor={'#D1DAFF'}
      />
    </Box>
  </Box>
}
