import {BigNumber} from "ethers";
import {Box, Text} from "grommet";
import React from "react";
import {ValuesChangeBadge} from "./Badge";

export interface BalanceChangeProps  {
  balance: BigNumber
  decimals: number
  value: BigNumber
  name?: string
  onClick?: () => void
}

export const BalanceChange = (props: BalanceChangeProps) => {
  const { value, balance, name, decimals } = props
  let nextBalance =  balance.sub(value)
  if(nextBalance.lt(0)) {
    nextBalance = BigNumber.from(0)
  }

  return <Box direction={'row'} align={'center'} gap={'4px'} onClick={props.onClick}>
    <Text weight={400} color={'textSecondary'} size={'12px'}>
      Wallet balance:
    </Text>
    <Text color={'textSecondary'} style={{ fontSize: '10px!important' }}>
      <ValuesChangeBadge
        from={balance}
        to={!nextBalance.eq(balance) ? nextBalance : null}
        decimals={decimals}
        name={name}
        showName={true}
        fontSize={'12px'}
      />
    </Text>
  </Box>
}
