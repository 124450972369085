import React from 'react'
import { Box, Text } from 'grommet'
import {Arrow} from "./Icon";
import styled from "styled-components";

const VisibleItems = 5

export interface PaginationProps {
  current: number
  total: number
  onChange: (current: number) => void
}

const PaginationItem = styled(Box)<{
  isCurrentPage: boolean
  isSkipItem: boolean
}>`
  width: 32px;

  ${props => props.isCurrentPage && `
    background-color: #4852FF;
  `}

  ${props => (!props.isCurrentPage && !props.isSkipItem) && `
    &:hover {
      background-color: #4852FF;
    }
  `}

  ${props => (props.isSkipItem) && `
    cursor: default;
  `}
`

const NavigationItem = styled(Box)<{ isDisabled: boolean }>`
  width: 64px;
  cursor: pointer;

  ${props => (!props.isDisabled) && `
    &:hover {
      background-color: #4852FF;
    }
  `}

  ${props => props.isDisabled && `
    opacity: 0.4;
    cursor: default;
  `}
`

export const Pagination = (props: PaginationProps) => {
  const { current, total } = props

  let isFilteredBefore = false
  let isFilteredAfter = false

  const pagesList = Array(total)
    .fill(null)
    .map((_, index, arr) => {
      const pageNumber = index + 1

      const isFirst = pageNumber === 1
      const isLast = pageNumber === total
      let window = 1
      const delta = current - pageNumber
      const deltaAbs = Math.abs(delta)

      if(
        // show first 5 items
        (pageNumber < VisibleItems && current < (VisibleItems - 1))
        // show last 5 items
        || ((pageNumber > total - (VisibleItems - 1)) && (current > total - (VisibleItems - 1)))
      ) {
        return pageNumber
      }

      if(!isFirst && !isLast && deltaAbs > window) {
        if(delta < 0) {
          return 'skip_before'
        } else {
          return 'skip_after'
        }
      }
      return pageNumber
    })
    .filter((pageNumber, index, arr) => {
      const isVisible = typeof pageNumber === 'number'
        || (pageNumber === 'skip_before' && !isFilteredBefore)
        || (pageNumber === 'skip_after' && !isFilteredAfter)

      if(pageNumber === 'skip_before') {
        isFilteredBefore = true
      }
      if(pageNumber === 'skip_after') {
        isFilteredAfter = true
      }
      return isVisible
    })

  const isPrevDisabled = current <= 1
  const isNextDisabled = current >= total

  return <Box
    direction={'row'}
    height={'32px'}
    border={{ color: '#232630' }}
    round={'3px'}
    style={{ userSelect: 'none' }}
  >
    <NavigationItem
      align={'center'}
      justify={'center'}
      isDisabled={isPrevDisabled}
      onClick={isPrevDisabled ? undefined : () => props.onChange(props.current - 1)}
    >
      <Arrow direction={'right'} />
    </NavigationItem>
    {pagesList.map(pageNumber => {
      const isSkipItem = typeof pageNumber !== 'number'
      const isCurrentPage = props.current === pageNumber
      return <PaginationItem
        key={pageNumber}
        align={'center'}
        justify={'center'}
        isCurrentPage={isCurrentPage}
        isSkipItem={isSkipItem}
        onClick={() => {
          if(!isSkipItem) {
            props.onChange(pageNumber)
          }
        }}
        style={{
          opacity: total === 1 ? 0.5 : 'unset',
        }}
      >
        <Text>{isSkipItem ? '...' : pageNumber}</Text>
      </PaginationItem>
    })}
    <NavigationItem
      align={'center'}
      justify={'center'}
      isDisabled={isNextDisabled}
      onClick={isNextDisabled ? undefined : () => props.onChange(props.current + 1)}
    >
      <Arrow direction={'left'} />
    </NavigationItem>
  </Box>
}
