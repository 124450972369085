import {arbitrum, arbitrumSepolia} from "wagmi/chains";

const ConfigKeyLS = 'rho_config_v1'
export type ConfigMode = 'mainnet' | 'testnet'

export const ChainName: Record<number, string> = {
  [arbitrum.id]: arbitrum.name,
  [arbitrumSepolia.id]: arbitrumSepolia.name,
}

export const isMainnet = () => {
  return config.chainId === arbitrum.id
}

export const setConfigMode = (networkMode: ConfigMode) => {
  localStorage.setItem(ConfigKeyLS, networkMode)
}

export const getConfigMode = (): ConfigMode => {
  const urlParams = new URLSearchParams(window.location.search);
  const urlNetworkType = urlParams.get('network');
  if(urlNetworkType) {
    return urlNetworkType === 'testnet' ? 'testnet' : 'mainnet'
  }
  return localStorage.getItem(ConfigKeyLS) === 'testnet' ? 'testnet' : 'mainnet'
}

export const mainnetConfig = {
  rpcUrl: 'https://arb1.arbitrum.io/rpc',
  walletConnectProjectId: '749b34c19a1c2607d6e5cb65472a3edc',
  routerContractAddress: '0xbEF0110560921824AF49dE900f2f0bF9ceb87E8C' as `0x${string}`,
  viewContractAddress: '0xdcb233560Ef093eBf7be820eE6de1dF8c9f86586' as `0x${string}`,
  quoterContractAddress: '0x1e50242e9c5fB0b1E6B9DaF9676793D284D5AaCa' as `0x${string}`,
  contractProviderAddress: '0xB38E8b4B6fb48A28825948494E42ad29d6a83e7f' as `0x${string}`,
  vaultContractProvider: '0x6544779Ba9747cFA20a9b837C9547DE2e0cbf071' as `0x${string}`,
  vaultRouterAddress: '0x6d67DC521Bc50f80c3B28414d5FEbf32Ade80E62' as `0x${string}`,
  vaultAllocatorId: '0x75671e1fd10680df33ace780f2b92bc4d51be5f35f1d63c5ad7d0af7a0281d2c',
  vaultLaunchTimestamp: 1722343412,
  vaultIndexAtLaunch: (22502900157 / 21324110736),
  chainId: 42161,
  txConfirmations: 2,
  oraclesServiceUrl: 'https://v2.roaracle.app',
  dataServiceApiUrl: 'https://ds.rhoservice.com',
  vaultsApiUrl: 'https://mainnet-vaults-api.rhoservice.com',
  gasEstimateMultiplier: 1.25
}

export const testnetConfig = {
  rpcUrl: process.env.REACT_APP_RPC_URL || 'https://arbitrum-sepolia.publicnode.com',
  walletConnectProjectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || '749b34c19a1c2607d6e5cb65472a3edc',
  routerContractAddress: (process.env.REACT_APP_ROUTER_CONTRACT_ADDRESS || '0x8a471EFaCBAb12d208591563137d36bC3bFF1B11') as `0x${string}`,
  viewContractAddress: (process.env.REACT_APP_VIEW_CONTRACT_ADDRESS || '0x794589eb6b036314dB3ab18e73dEA13ba71f1739') as `0x${string}`,
  quoterContractAddress: (process.env.REACT_APP_QUOTER_CONTRACT_ADDRESS || '0x7c67afB326BfE712b1B3EC06E555CDAf64Cc001b') as `0x${string}`,
  contractProviderAddress: (process.env.REACT_APP_CONTRACT_PROVIDER_ADDRESS || '0xb387133AF4993D2149015691153ca762338b1ec0') as `0x${string}`,
  vaultContractProvider: '0xb8bAEa4b1C241b46BB8475E15a1c989C21094391' as `0x${string}`,
  vaultRouterAddress: '0x6CA8ce3e36871ed87e7a61de104326a578350eb2' as `0x${string}`,
  vaultAllocatorId: '0xc7f81cf6a280b62a722ba277415c51e5da4a32aded5a939776c2e47a71f2adf5',
  vaultLaunchTimestamp: 1721215764,
  vaultIndexAtLaunch: 1,
  chainId: parseInt(process.env.REACT_APP_CHAIN_ID || arbitrumSepolia.id.toString()),
  txConfirmations: parseInt(process.env.REACT_APP_TX_CONFIRMATIONS || '2'),
  oraclesServiceUrl: process.env.REACT_APP_ORACLE_SERVICE_URL || 'https://testnet-v2.roaracle.app',
  dataServiceApiUrl: process.env.REACT_APP_DATA_SERVICE_API_URL || 'https://ds-testnet.rhoservice.com',
  vaultsApiUrl: 'https://testnet-vaults-api.rhoservice.com',
  gasEstimateMultiplier: 2
}

export const availableConfigs = [mainnetConfig, testnetConfig]

export const configMode = getConfigMode()
const config = configMode === 'mainnet' ? mainnetConfig : testnetConfig

export const sentryDSN = process.env.REACT_APP_SENTRY_DSN || ''

console.log('routerContractAddress', config.routerContractAddress)
console.log('viewContractAddress', config.viewContractAddress)
console.log('quoterContractAddress', config.quoterContractAddress)
console.log('vaultContractProvider', config.vaultContractProvider)
console.log('chainId', config.chainId)
console.log('oraclesServiceUrl', config.oraclesServiceUrl)
console.log('dataServiceApiUrl', config.dataServiceApiUrl)
console.log('sentryDSN', sentryDSN)

export default config
