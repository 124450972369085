import {Box} from "grommet";
import styled from "styled-components";

export const ScrollableContainer = styled(Box)`
    overflow-y: scroll;

    scrollbar-color: #373E62 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 10px;
    }
    
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #373E62;
        border-radius: 20px;
        //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-corner {
        background: rgba(0,0,0,0);
    }
`
