import {
  createConfig,
  configureChains, Chain,
} from 'wagmi'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { publicProvider } from 'wagmi/providers/public'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import {
  arbitrumGoerli,
  arbitrum,
  arbitrumSepolia,
  mainnet,
  polygon,
  base,
  avalanche,
  optimism,
  bsc,
  mantle, fantom
} from 'wagmi/chains'
import config from '../config'

const { walletConnectProjectId } = config

let supportedChain: Chain = arbitrumGoerli

if(config.chainId === arbitrum.id) {
  supportedChain = arbitrum
} else if(config.chainId === arbitrumSepolia.id) {
  supportedChain = arbitrumSepolia
} else {
  console.warn('WARNING: unexpected chain id. Arbitrum Goerli will be used as default chain.')
}

if(config.rpcUrl) {
  supportedChain.rpcUrls = {
    public: { http: [config.rpcUrl]},
    default: { http: [config.rpcUrl] },
  }
  console.log('Init with custom RPC URL: ', config.rpcUrl)
}

const supportedChains = [
  supportedChain,
  mainnet,
  arbitrum,
  avalanche,
  optimism,
  polygon,
  base,
  bsc,
  mantle,
  fantom
]

const { chains, publicClient, webSocketPublicClient } = configureChains(
  supportedChains,
  [publicProvider()],
)

const walletConnectConnector = new WalletConnectConnector({
  chains: supportedChains,
  options: {
    projectId: config.walletConnectProjectId,
  },
})

export const metamaskConnector = new MetaMaskConnector({ chains })

export const wagmiConfig = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient,
  connectors: [metamaskConnector, walletConnectConnector],
})

createWeb3Modal({ wagmiConfig, projectId: walletConnectProjectId, chains })
