import {BigNumber} from "ethers";
import {RhoVaultKey} from "./api/vault";

export interface ProvisionDistribution {
  total: BigNumber
  payer: BigNumber
  receiver: BigNumber
}

export interface LiquidityQuote {
  totalFutureProvisionNotional: ProvisionDistribution
  totalFutureProvisionPayerDv01: BigNumber
  totalFutureProvisionReceiverDv01: BigNumber
  newMarginThreshold: BigNumber
  newProvisionDistribution: ProvisionDistribution
  newProvisionNotionalDv01: BigNumber
  marketPortfolio: MarketPortfolio
}

export interface VAMMParams {
  lowerBoundRate: BigNumber
  currentFutureRate: BigNumber
  upperBoundRate: BigNumber
  intervalLength: BigNumber
  intervalsCount: number
}

export interface FutureInfo {
  id: string
  marketId: string
  termStart: BigNumber
  termLength: BigNumber
  vAMMParams: VAMMParams
  totalLiquidityNotional: BigNumber
  openInterest: BigNumber
  pauseConfiguration: {
    liquidation: boolean
    liquidityProvision: boolean
    liquidityRemoval: boolean
    positionsOwnershipTransfer: boolean
    trade: boolean
  }
}

export enum RiskDirectionType {
  RECEIVER = 0,
  PAYER = 1
}

export enum IRateMathType {
  LINEAR = 0,
  COMPOUNDING = 1
}

export interface MarketDescriptor {
  id: string
  sourceName: string
  instrumentName: string
  tag: string
  version: number
  underlying: string
  underlyingName: string
  underlyingDecimals: number
  underlyingIsWrappedNativeToken: boolean
  rateMathType: IRateMathType
}

export interface MarketInfo {
  descriptor: MarketDescriptor
  futures: FutureInfo[]
  openInterest: BigNumber
  totalLiquidityNotional: BigNumber
  riskParameters: MarketRiskParameters
}

export interface FixedAndFloatTokensPair {
  fixedTokenAmount: BigNumber,
  floatTokenAmount: BigNumber
}

export interface TradeInfo {
  direction: RiskDirectionType
  tokensPair: FixedAndFloatTokensPair;
  marketRateBefore: BigNumber;
  marketRateAfter: BigNumber;
  tradeRate: BigNumber;
  lpFee: BigNumber
  protocolFee: BigNumber
  floatIndex: BigNumber;
}

export interface OneDirectionTradeQuote {
  tradeInfo: TradeInfo
  totalFutureOpenPositionNotional: BigNumber;
  totalFutureOpenPositionDv01: BigNumber;
  newMargin: Margin;
  newMarginThreshold: BigNumber;
  tradeNotionalDv01: BigNumber;
}

export interface TradeQuote {
  insufficientLiquidityForPayer: boolean
  insufficientLiquidityForReceiver: boolean
  exceededTradeRateImpactLimitForPayer: boolean
  exceededTradeRateImpactLimitForReceiver: boolean
  exceededTradeNotionalLimitForPayer: boolean
  exceededTradeNotionalLimitForReceiver: boolean
  exceededMarketRateImpactLimitForPayer: boolean
  exceededMarketRateImpactLimitForReceiver: boolean
  payerQuote: OneDirectionTradeQuote,
  receiverQuote: OneDirectionTradeQuote
  marketPortfolio: MarketPortfolio
}

export interface ProfitAndLoss {
  netFutureValue: BigNumber
  accruedLPFee: BigNumber
  incurredFee: BigNumber
}

export interface Margin {
  collateral: BigNumber
  profitAndLoss: ProfitAndLoss
}

export interface MarginState {
  margin: Margin;
  initialMarginThreshold: BigNumber;
  liquidationMarginThreshold: BigNumber;
  lpMarginThreshold: BigNumber;
  dv01: BigNumber;
  riskDirection:  RiskDirectionType
}

export interface FutureOpenPosition {
  futureId: string
  tokensPair: FixedAndFloatTokensPair
  notional: BigNumber
  profitAndLoss: ProfitAndLoss
  requiredMargin: BigNumber
  dv01: BigNumber
  riskDirection: RiskDirectionType
}

export interface RateBounds {
  lower: BigNumber
  upper: BigNumber
}

export interface ProvisionInfo {
  bounds: RateBounds
  notional: ProvisionDistribution
  requiredMargin: BigNumber
  payerDv01: BigNumber
  receiverDv01: BigNumber
}

export interface MakerFutureProvisions {
  futureId: string
  provisions: ProvisionInfo[]
  requiredMargin: BigNumber
}

export interface MarketPortfolio {
  descriptor: MarketDescriptor
  marginState: MarginState
  futures: FutureInfo[]
  futureOpenPositions: FutureOpenPosition[]
  futureMakerProvisions: MakerFutureProvisions[]
}

// Client types
export type OpType = 'borrowing' | 'lending' | 'staking'

export enum RiskDirection {
  payer = 'Payer',
  receiver = 'Receiver',
  none = 'None'
}

export enum LiquidityOperation {
  provide = 'Provide',
  remove = 'Remove',
}

export enum FormErrors {
  insufficientFunds = 'insufficientFunds',
  collateralRequired = 'collateralRequired',
  quoteSwapError = 'quoteSwapError',
  wrongValue = 'wrongValue',
  insufficientLiquidity = 'insufficientLiquidity',
  exceededTradeRateImpactLimit = 'exceededTradeRateImpactLimit',
  exceededMarketRateImpactLimit = 'exceededmarketRateImpactLimit',
  exceededNotionalLimit = 'exceededRateImpactLimit',
  exceededRateLimit = 'exceededRateLimit',
  floatTokenAmountIsTooSmall = 'floatTokenAmountIsTooSmall',
  common = 'common'
}

export interface FormError {
  field?: string
  type: FormErrors
  value?: BigNumber
  text: string
  tooltipText?: string
}

export interface OraclePackage {
  marketId: string;
  timestamp: number
  signature: string
  indexValue: string
}

export interface MarketOraclePackages {
  marketId: string
  packages: OraclePackage[]
}

export interface IntervalLiquidity {
  notional: BigNumber
  bounds: RateBounds
}

export interface LiquidityDistribution {
  provisionDistribution: ProvisionDistribution
  currentFutureRate: BigNumber
  intervalLiquidity: IntervalLiquidity[]
}

export interface MakerLiquidityDistribution {
  currentFutureRate: BigNumber
  intervalLiquidity: IntervalLiquidity[]
}

export interface MarketRiskParameters {
  minNotional: BigNumber
  liquidationThresholdDelta: BigNumber
  marginThresholdDelta: BigNumber
  marginThresholdTolerance: BigNumber
  maxRateImpactPerTrade: BigNumber
  hedgeMarginFactor: BigNumber
  marginRequirementSecondsFloor: number
  liquidationMarginRequirementSecondsFloor: number
  maturityLockoutSeconds: number
}

export interface TradeLimiterParameters {
  maxTradeNotional: BigNumber
  marketRateChangeLimit: BigNumber
  marketRateLimitWindowSeconds: number
  marketRateLimitAggregationInterval: number
}

export interface MarketTradeLimiterParams {
  marketId: string
  params: TradeLimiterParameters
}

export interface VaultInfo {
  id: string
  address: string
  name: string
  underlyingName: string
  underlyingAddress: string
  underlyingDecimals: number
  marketIds: string[]
  futures: FutureInfo[]
  maxWithdraw: BigNumber
  totalSupply: BigNumber
  totalAssets: BigNumber
  key: RhoVaultKey
  userPendingWithdrawalsAmount: BigNumber
}
