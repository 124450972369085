import React from 'react'
import {Box} from "grommet";
import {TradeButtonProps} from "../common";
import {PrimaryButton} from "../../../components";

export const DepositButton = (props: TradeButtonProps) => {
  const { disabled = false, inProgress = false, onClick } = props

  return <Box>
    <PrimaryButton
      disabled={disabled}
      loading={inProgress}
      text={'Deposit'}
      onClick={onClick}
    />
  </Box>
}
