import {useAccount} from "wagmi";
import {useEffect, useMemo, useState} from "react";
import {BigNumber} from "ethers";
import {getOraclePackage, isFulfilled} from "../utils";
import {getWithdrawableMargin} from "../api/viewContract";
import {getOracleRates} from "../api/oracleService";

const zero = BigNumber.from(0)

export const useWithdrawableMargin = (marketId?: string) => {
  const { address: userAddress } = useAccount()

  const [amount, setAmount] = useState(zero)

  const loadData = async () => {
    try {
      if(userAddress && marketId) {
        setAmount(zero)
        const oraclePackage = await getOraclePackage(marketId)
        const data = await getWithdrawableMargin(marketId, userAddress, [oraclePackage])
        setAmount(data)
      } else {
        setAmount(zero)
      }
    } catch (e) {
      console.error('Failed to get withdrawable margin:', e)
    }
  }

  useEffect(() => {
    loadData()
  }, [userAddress, marketId])

  return useMemo(() => {
    return {
      amount,
      refetch: loadData
    }
  }, [amount])
}

export interface MarketWithdrawableMargin {
  marketId: string
  withdrawableMargin: BigNumber
}

export const useMarketsWithdrawableMargin = (marketIds: string[]) => {
  const { address: userAddress } = useAccount()

  const [marketsWithdrawableMargin, setMarketsWithdrawableMargin] = useState<MarketWithdrawableMargin[]>([])

  const loadData = async () => {
    try {
      if(userAddress && marketIds.length > 0) {
        const oracleRates = await getOracleRates()
        const values = await Promise.allSettled(marketIds.map(async (marketId) => {
          const marketRate = oracleRates.find(item => item.oraclePackage.marketId === marketId)
          if(marketRate) {
            const withdrawableMargin = await getWithdrawableMargin(marketId, userAddress, [marketRate.oraclePackage])
            return {
              marketId,
              withdrawableMargin
            }
          }
          return {
            marketId,
            withdrawableMargin: zero
          }
        }))
        const filteredValues = values
          .filter(isFulfilled)
          .map(item => item.value)
        setMarketsWithdrawableMargin(filteredValues)
      } else {
        setMarketsWithdrawableMargin([])
      }
    } catch (e) {
      console.error('[useMarketsWithdrawableMargin]: failed to get markets withdrawable margin', e)
    }
  }

  useEffect(() => {
    loadData()
  }, [userAddress, marketIds.length])

  return useMemo(() => {
    return {
      marketsWithdrawableMargin,
      refetch: loadData
    }
  }, [marketsWithdrawableMargin])
}
