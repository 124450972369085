import React, {useEffect, useState} from 'react'
import {Box, Spinner, Text} from "grommet";
import {MenuItemId} from "./common";
import styled from "styled-components";
import {OpenPositionsList} from "./open-positions";
import {useAccount, useBalance} from "wagmi";
import {TradeProps} from "../common";
import {Document} from 'grommet-icons';
import {BottomMenuFilterMode, PanelVisibilityStatus} from "./index";
import {toast} from "react-toastify";
import {TradeHistory} from "./trade-history";
import {MarginManagement} from "./margin-management";
import {ScrollableContainer} from "../../../components/scroll";
import {getTradesInfo, Trade, UserPositions} from "../../../api/dataService";
import {useProtocolData} from "../../../providers/ProtocolDataProvider";
import usePoller from "../../../hooks/usePoller";
import {useUserPositions} from "../../../hooks/useUserPositions";

const PanelHeightMap: Record<PanelVisibilityStatus, string> = {
  ['closed']: '0',
  ['opened-default']: '400px',
  ['opened-small']: '116px',
}

const ContentContainer = styled(ScrollableContainer)<{ visibilityStatus: PanelVisibilityStatus }>`
    height: ${props => PanelHeightMap[props.visibilityStatus]};
    opacity: ${props => props.visibilityStatus !== 'closed' ? 1 : 0};
    transition: height 250ms, opacity 250ms;
    overflow-y: scroll;
`

const sortByMaturity = (a: UserPositions, b: UserPositions) => {
  const diffOpenPosition = a.openPosition.maturity - b.openPosition.maturity
  const diffClosedPosition = a.closedPosition.maturity - b.closedPosition.maturity
  return diffOpenPosition !== 0 ? diffOpenPosition : diffClosedPosition
}

export const PanelContent = (props: TradeProps & {
  visibilityStatus: PanelVisibilityStatus
  activeItem?: MenuItemId
  isLoading: boolean
  setLoading: (isLoading: boolean) => void
  filterMode: BottomMenuFilterMode
}) => {
  const {
    market,
    markets,
    marketId,
    futureId,
    visibilityStatus,
    activeItem,
    setLoading,
    filterMode
  } = props

  const { address: userAddress } = useAccount()

  const { data: underlyingTokenBalance } = useBalance({
    address: userAddress,
    token: props.underlying as `0x${string}`,
    watch: true,
    enabled: !!props.underlying
  })

  const {
    portfolio, refetchPortfolio,
    userPositions, refetchUserPositions
  } = useProtocolData()

  const [trades, setTrades] = useState<Trade[]>([])
  const futures = markets.map(market => market.futures).flat()

  const updateTrades = async () => {
    const tradesRequestParams = {
      userAddress,
      marketId: filterMode === 'market' ? marketId : undefined,
      futureId: filterMode === 'instrument' ? futureId : undefined,
    }
    const items = await getTradesInfo(tradesRequestParams)
    setTrades(items)
  }

  const loadTrades = async () => {
    if(marketId) {
      try {
        if(!userAddress) {
          setTrades([])
          setLoading(false)
          return
        }

        setLoading(true)
        await updateTrades()
      } catch (e) {
        setTrades([])
        console.error('Error on trades loading:', e)
        toast.error(`Failed to load trades history: ${(e as Error).message.slice(0, 200)}`)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if(marketId && activeItem === MenuItemId.openPositions) {
      refetchUserPositions().then(() => setLoading(false))
    }

    if(activeItem === MenuItemId.tradeHistory) {
      if(marketId) {
        refetchPortfolio().then(() => {
          loadTrades()
        }).finally(() => setLoading(false))
      }
    }

    if(activeItem === MenuItemId.marginManagement) {
      refetchPortfolio().then(() => setLoading(false))
    }

    if(!userAddress) {
      setTrades([])
    }
  }, [userAddress, marketId, futureId, activeItem, filterMode]);

  usePoller(() => {
    if(activeItem === MenuItemId.tradeHistory) {
      updateTrades().catch(e => console.error('Failed to update trades', e));
    }
  }, 5000)

  useEffect(() => {
    if(userAddress && marketId && (activeItem && [MenuItemId.openPositions, MenuItemId.marginManagement].includes(activeItem))) {
      // loadPortfolio(false)
    }
  }, [props.executeTradeReceipt, underlyingTokenBalance?.value]);

  const openPositions = userPositions
    .sort(sortByMaturity)
    .filter(item => {
      const { openPosition } = item
      const isOpenPosition = openPosition && openPosition.notional !== '0'
      const existedFuture = futures.find(future => future.id === item.futureId)

      if(filterMode === 'all') {
        return isOpenPosition && !!existedFuture
      } else if(filterMode === 'market') {
        return isOpenPosition && !!existedFuture && (market?.futures || []).find(future => future.id === item.futureId)
      }
      return isOpenPosition && !!existedFuture && props.futureId == item.futureId
    })
    .map(item => item.openPosition)

  const isLoading = props.isLoading

  return <ContentContainer visibilityStatus={visibilityStatus}>
    <Box
      margin={{ top: '8px' }}
      style={{
        display: visibilityStatus !== 'closed' ? 'unset' : 'none',
    }}
    >
      {(!isLoading && activeItem === MenuItemId.openPositions && portfolio && openPositions.length > 0) &&
          <OpenPositionsList {...props} openPositions={openPositions} />
      }
      {(!isLoading && activeItem === MenuItemId.tradeHistory && portfolio && trades.length > 0) &&
          <TradeHistory {...props} trades={trades} />
      }
      {(!isLoading && activeItem === MenuItemId.marginManagement) &&
          <MarginManagement {...props} />
      }
    </Box>
    {isLoading &&
      <Box
          height={'calc(100% - 48px)'}
          width={'100%'}
          justify={'center'}
          align={'center'}
          style={{ position: 'absolute' }}
      >
          <Spinner color={'brandRho'} size={'medium'} />
      </Box>
    }
    {(activeItem === MenuItemId.openPositions && !isLoading && openPositions.length === 0) &&
        <Box height={'100%'} justify={'center'} align={'center'} gap={'16px'}>
            <Document color={'text'} />
            <Text size={'18px'}>You have no open positions</Text>
        </Box>
    }
    {(activeItem === MenuItemId.tradeHistory && !isLoading && trades.length === 0) &&
        <Box height={'100%'} justify={'center'} align={'center'} gap={'16px'}>
            <Document color={'text'} />
            <Text size={'18px'}>You have no trades</Text>
        </Box>
    }
  </ContentContainer>
}
