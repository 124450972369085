import React from 'react'
import {Box, Text} from "grommet";
import {HalfPieChart} from "../charts/half-pie";
import {QuestionMark, RightArrow} from "../";
import tooltips from "../../utils/tooltips";
import {useProtocolData} from "../../providers/ProtocolDataProvider";
import {getLeverage} from "../../utils";

const MinLeverage = 1

export const MarketLeverage = (props: {
  marketId?: string
  nextLeverage?: {
    leverage: number,
    maxLeverage: number,
    leveragePercentage: number
  }
}) => {
  const { nextLeverage } = props
  const { markets, portfolio } = useProtocolData()
  const market = markets.find(item => item.descriptor.id === props.marketId)
  const marketPortfolio = portfolio.find(item => item.descriptor.id === props.marketId)

  let currentLeverage = getLeverage({
    market,
    marketPortfolio
  })

  if(currentLeverage.leverage > 1) {
    currentLeverage.leverage = +(currentLeverage.leverage.toFixed(0))
  } else {
    currentLeverage.leverage = +(currentLeverage.leverage.toFixed(1))
  }

  if(nextLeverage) {
    if(nextLeverage.leverage > 1) {
      nextLeverage.leverage = +(nextLeverage.leverage.toFixed(0))
    } else {
      nextLeverage.leverage = +(nextLeverage.leverage.toFixed(1))
    }
  }

  const items = [
    {
      start: 0,
      end: nextLeverage ? nextLeverage.leveragePercentage * 100 : currentLeverage.leveragePercentage * 100,
      color: '#707FC2'
    },
  ]

  return <Box style={{ position: 'relative', width: '250px' }}>
    <HalfPieChart items={items} />
    <Box
      width={'100%'}
      height={'100%'}
      align={'center'}
      justify={'center'}
      pad={{ top: '48px' }}
      gap={'2px'}
      style={{
        position: 'absolute'
      }}
    >
      <Box style={{ position: 'absolute', top: '14px' }}>
        <Text>{Math.round((nextLeverage ? nextLeverage.maxLeverage : currentLeverage.maxLeverage) / 2)}x</Text>
      </Box>
      <Box style={{ position: 'absolute', left: '24px', bottom: '4px' }}>
        <Text>{MinLeverage}x</Text>
      </Box>
      <Box align={'center'} gap={'2px'}>
        <Box direction={'row'} align={'center'} gap={'2px'}>
          <Text size={'13px'} color={'textSecondary'}>Leverage</Text>
          <QuestionMark tooltipId={'bottom_current_leverage'} tooltipText={tooltips.marketLeverage} />
        </Box>
        {nextLeverage
          ? <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'18px'} color={'text'}>
                {currentLeverage.leverage}x
              </Text>
              <Box>
                <RightArrow size={'11px'} isPositive={(currentLeverage.leverage < nextLeverage.leverage)} />
              </Box>
              <Text size={'18px'} color={'accentWhite2'}>
                {nextLeverage.leverage}x
              </Text>
            </Box>
          :
          <Text size={'20px'} color={'accentWhite2'}>
            {currentLeverage.leverage}x
          </Text>
        }
      </Box>
      <Box style={{ position: 'absolute', right: '24px', bottom: '4px' }}>
        <Text>{nextLeverage ? nextLeverage.maxLeverage : currentLeverage.maxLeverage}x</Text>
      </Box>
    </Box>
  </Box>
}
