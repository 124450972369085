import React, {useEffect, useState} from 'react'
import {Text, Button, Box} from "grommet";
import {Dropdown} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import config, {getConfigMode, setConfigMode, availableConfigs} from "../../config";
import type { MenuProps } from 'antd';
import { arbitrum, arbitrumGoerli, arbitrumSepolia } from 'wagmi/chains'
import styled from "styled-components";
import {Arrow} from "../Icon";
import {NetworkNameMap, NetworkTypeMap} from "../../app.types";
import {useAccount, useNetwork} from "wagmi";
import useIsTabActive from "../../hooks/useIsTabActive";

const configMode = getConfigMode()
const allChains = [arbitrum, arbitrumGoerli, arbitrumSepolia]

const ButtonContainer = styled(Button)`
  background: none;
  border-radius: 3px;
  font-family: 'Roboto',serif;
  padding: 5px 10px;
`

export const NetworkSelect = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const [isOpened, setOpened] = useState(false)

  const { chain, chains } = useNetwork()
  const { isConnected, address: userAddress } = useAccount()
  const isTabActive = useIsTabActive()

  useEffect(() => {
    if(isTabActive && isConnected && chain && (chain.unsupported || chain.id !== config.chainId)) {
      const supportedChain = chains.find(item => item.id === config.chainId)
      if(supportedChain && supportedChain.id && supportedChain.id !== chain.id) {
        // switchNetwork({
        //   chainId: supportedChain.id
        // }).catch(e => {
        //   console.error('Failed to switch network', e)
        // })
      }
    }
  }, [isTabActive, isConnected, chain]);

  const items: MenuProps['items'] = availableConfigs.map(config => {
    const configChain = allChains.find(chain => chain.id === config.chainId)
    const networkType = configChain?.id === arbitrum.id ? 'mainnet' : 'testnet'
    const onClick = () => {
      setConfigMode(networkType)
      navigate(`${location.pathname}?network=${networkType}`)
      navigate(0)
    }

    return {
      key: config.chainId,
      label: <Box onClick={onClick}>
        <Text weight={500}>
          {NetworkNameMap[networkType]}
        </Text>
        {networkType !== 'mainnet' &&
            <Text color={'textSecondary'}>
              {NetworkTypeMap[networkType]}
            </Text>
        }
      </Box>
    }
  })

  return <Dropdown menu={{ items }} trigger={['click']} onOpenChange={setOpened}>
    <ButtonContainer>
      <Box direction={'row'} align={'center'} gap={'8px'}>
        <Text color={'text'} style={{ cursor: 'pointer' }}>
          {NetworkNameMap[configMode]}
        </Text>
        <Arrow direction={isOpened ? 'up' : 'down'} width={'10px'} />
      </Box>
    </ButtonContainer>
  </Dropdown>
}
