import React, {useEffect, useMemo, useState} from 'react'
import {Box, Text} from "grommet";
import {WidgetContainer} from "../../../components";
import {TradingView, TradingViewItem} from "./tradingView";
import {ChartContainerMinWidth, ChartHeight} from "../constants";
import {getUserMarginHistory, getUserMarketMarginHistory, MarginHistoryPoint} from "../../../api/dataService";
import {useAccount} from "wagmi";
import moment from "moment/moment";
import {BarPrice, Time} from "lightweight-charts";
import Decimal from "decimal.js";
import {PortfolioProps} from "../common";
import {abbreviateNumber} from "../../../utils";

type ChartType = 'margin' | 'pnl'
type ChartInterval = '1d' | '7d' | '30d'
const allChartIntervals: ChartInterval[] = ['7d', '30d']
const chartIntervalMap: Record<string, number> = {
  '1d': 1,
  '7d': 7,
  '30d': 30
}

export const PortfolioHistoryChart = (props: PortfolioProps) => {
  const { market } = props
  const { address: userAddress } = useAccount()

  const [chartType, setChartType] = useState<ChartType>('margin')
  // Fees always enabled for "margin" chart
  const [isFeesSelected, setIsFeesSelected] = useState<boolean>(true)
  const [chartInterval, setChartInterval] = useState('30d')

  const [marginPoints, setMarginPoints] = useState<MarginHistoryPoint[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [abortController, setAbortController] = useState<AbortController>()

  useEffect(() => {
    const loadChartData = async () => {
      try {
        setIsLoading(true)
        if(userAddress) {
          const controller = new AbortController();
          const signal = controller.signal;

          if(abortController) {
            abortController.abort()
          }
          setAbortController(controller)

          const amountOfDays = chartIntervalMap[chartInterval]
          const params = {
            from: Math.round((Date.now() - amountOfDays * 24 * 60 * 60 * 1000) / 1000),
            interval: '1d'
          }
          let points: MarginHistoryPoint[]
          if(market && market !== 'all') {
            points = await getUserMarketMarginHistory(userAddress, market.descriptor.id, params, signal)
          } else {
            points = await getUserMarginHistory(userAddress, params, signal)
          }
          setMarginPoints(points)
          console.log('Margin history points loaded:', points)
        } else {
          setMarginPoints([])
        }
      } catch (e) {
        console.error('Failed to load chart data:', e)
      } finally {
        setIsLoading(false)
      }
    }
    loadChartData()
  }, [userAddress, market, chartInterval]);

  const chartLineItems: TradingViewItem[] = useMemo(() => {
    if(marginPoints.length === 0) {
      return new Array(30).fill(null).map((_, index) => {
        const day = moment().subtract(index, 'day')
        const time = day.unix() as Time
        return {
          value: 0,
          time
        }
      }).reverse()
    }
    return marginPoints.map(item => {
      let value = 0

      // if fees on: +LP - incurred
      if(chartType === 'margin') {
        value = new Decimal(item.collateral)
          .add(new Decimal(item.totalFutureValue))
          .add(new Decimal(item.lpFees))
          .sub(new Decimal(item.incurredFees))
          .toNumber()
      } else if(chartType === 'pnl') {
        let valueDecimal = new Decimal(item.totalFutureValue)
        if(isFeesSelected) {
          valueDecimal = valueDecimal
            .add(new Decimal(item.lpFees))
            .sub(new Decimal(item.incurredFees))
        }
        value = valueDecimal.toNumber()
      }

      const time = moment(item.timestamp).unix() as Time
      return {
        value,
        time
      }
    })
  }, [chartType, chartInterval, userAddress, marginPoints, isFeesSelected])

  return <WidgetContainer
    style={{
      minWidth:
        props.viewType === 'mobile' ? 'unset' : ChartContainerMinWidth
  }}
  >
    <Box justify={'between'} height={'100%'}>
      <Box direction={'row'} justify={'between'}>
        <Box>
          <Text size={'22px'} weight={500} color={'textHeader'}>
            {chartType === 'margin' ? 'Margin history' : 'P&L history'}
            {market === 'all' ? ' (USD)' : ` (${market?.descriptor.underlyingName})`}
          </Text>
          <Text>Last {chartIntervalMap[chartInterval]} days</Text>
        </Box>
        {/*<Box direction={'row'} height={'20px'} gap={'24px'} justify={'center'} align={'center'}>*/}
        {/*  <HeaderSwitch*/}
        {/*    size={'16px'}*/}
        {/*    height={'16px'}*/}
        {/*    options={[{ id: 'margin', text: 'Margin' }, { id: 'pnl', text: 'P&L' }]}*/}
        {/*    selectedOptionId={chartType}*/}
        {/*    onChange={(type) => setChartType(type as ChartType)}*/}
        {/*  />*/}
        {/*  <Box width={'1px'} height={'14px'} background={'dividerBg'} />*/}
        {/*  <Box>*/}
        {/*    <Box direction={'row'} gap={'12px'} align={'center'}>*/}
        {/*      <Text size={'16px'}>Fees</Text>*/}
        {/*      <Switch*/}
        {/*        size={'small'}*/}
        {/*        disabled={chartType === 'margin'}*/}
        {/*        checked={chartType === 'margin' || isFeesSelected}*/}
        {/*        onChange={setIsFeesSelected}*/}
        {/*      />*/}
        {/*    </Box>*/}
        {/*  </Box>*/}
        {/*  /!*<Box width={'1px'} height={'14px'} background={'dividerBg'} />*!/*/}
        {/*  /!*<Box>*!/*/}
        {/*  /!*  <SmallTagsOptions*!/*/}
        {/*  /!*    activeItemId={chartInterval}*!/*/}
        {/*  /!*    items={allChartIntervals.map(item => {*!/*/}
        {/*  /!*      return {*!/*/}
        {/*  /!*        id: item,*!/*/}
        {/*  /!*        text: item*!/*/}
        {/*  /!*      }*!/*/}
        {/*  /!*    })}*!/*/}
        {/*  /!*    onSelect={setChartInterval}*!/*/}
        {/*  /!*  />*!/*/}
        {/*  /!*</Box>*!/*/}
        {/*</Box>*/}
      </Box>
      <Box style={{ position: 'relative' }}>
        <Box
          style={isLoading ? {
            opacity: 0.4,
            filter: 'blur(1px)',
            pointerEvents: 'none'
          } : {}}
        >
          <TradingView
            height={ChartHeight}
            lineItems={chartLineItems}
            priceFormatter={(value: BarPrice) => {
              return abbreviateNumber(value, 2)
            }}
            tooltipFormatter={(tooltip) => {
              const prefix = market === 'all' ? '$' : ''
              const suffix = market === 'all' ? '' : ` ${market?.descriptor.underlyingName}`
              return {
                ...tooltip,
                title: chartType === 'margin' ? 'Margin' : 'Profit and Loss',
                value: `${prefix}${abbreviateNumber(tooltip.value, 2)}${suffix}`,
              }
            }}
          />
        </Box>
        {isLoading &&
            <Box width={'100%'} height={'260px'} justify={'center'} align={'center'} style={{ position: 'absolute' }}>
                <Box pad={'12px'}>
                    <Text size={'16px'} color={'accentWhite'}>Loading</Text>
                </Box>
            </Box>
        }
        {/*<Box width={'100%'} height={'260px'} justify={'center'} align={'center'} style={{ position: 'absolute' }}>*/}
        {/*  <Box pad={'12px'}>*/}
        {/*    <Text size={'16px'} color={'accentWhite'}>COMING SOON</Text>*/}
        {/*  </Box>*/}
        {/*</Box>*/}
      </Box>
    </Box>
  </WidgetContainer>
}
