import React from 'react'
import {Box, Table, TableBody, TableCell, TableHeader, TableRow, Text} from "grommet";
import styled from "styled-components";
import {MaturityBadge, Number, QuestionMark} from "../../../../components";
import tooltips from "../../../../utils/tooltips";
import {TradeProps} from "../../common";
import {getDirectionAlias, getExplorerUrl, getFutureAlias} from "../../../../utils";
import moment from "moment/moment";
import {BigNumber} from "ethers";
import {Button} from "antd";
import {LinkOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {Trade} from "../../../../api/dataService";
import {useProtocolData} from "../../../../providers/ProtocolDataProvider";
import {configMode} from "../../../../config";

const TableContainer = styled(Table)`
    thead {
      th {
        color: #838495;
        font-size: 14px;
        font-weight: 500;
        border: none;
      }
    }

    th:first-child, td:first-child {
        padding-left: 0;
    }

    th:last-child, td:last-child {
        padding-right: 0;
    }

    tbody {
        tr {
            border-top: 1px dashed ${
                    props => props.theme.global.colors.tableRowBorder
            }!important;
        }
    }
`

const CurrentMark = styled(Box)<{ isActive?: boolean }>`
    &::before {
        content: "";
        position: absolute;
        left: -16px;
        top: -10px;
        width: 3px;
        height: 35px;
        background: ${props =>
  props.isActive ? props.theme.global.colors.brandRho : 'unset'
};
    }
`

export const TradeHistory = (props: TradeProps & {
  trades: Trade[]
}) => {
  const { trades } = props
  const navigate = useNavigate()
  const { portfolio } = useProtocolData()

  return <Box margin={{ left: '16px' }}>
    <TableContainer>
      <TableHeader style={{ height: '36px' }}>
        <TableRow>
          <TableCell scope="col">
            <Text size={'12px'} color={'textSecondary'}>TYPE</Text>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>MARKET</Text>
              <QuestionMark tooltipId={'open_market'} tooltipText={'Market name'} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>MATURITY</Text>
              <QuestionMark tooltipId={'trades_history__maturity'} tooltipText={tooltips.maturity} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>DIRECTION</Text>
              <QuestionMark tooltipId={'trades_history__rd'} tooltipText={tooltips.riskDirection} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>NOTIONAL</Text>
              <QuestionMark tooltipId={'trades_history__notional'} tooltipText={tooltips.notional} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>RATE</Text>
              <QuestionMark tooltipId={'trades_history_rate'} tooltipText={tooltips.futureRate} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>FEE</Text>
              <QuestionMark tooltipId={'trades_history_fee'} tooltipText={tooltips.tradingFees} />
            </Box>
          </TableCell>
          <TableCell scope="col">
            <Box direction={'row'} align={'center'} gap={'2px'}>
              <Text size={'12px'} color={'textSecondary'}>TIME</Text>
            </Box>
          </TableCell>
          {/*<TableCell scope="col" align={'right'}>*/}
          {/*  <Text size={'12px'} color={'textSecondary'}>TXN</Text>*/}
          {/*</TableCell>*/}
        </TableRow>
      </TableHeader>
      <TableBody>
        {trades.map((item, index) => {
          const {
            futureId,
            direction,
            notionalAmount,
            rate,
            feeAmount,
            timestamp,
            txHash,
            maturity,
            market: marketName
          } = item

          const futures = portfolio.map(item => item.futures).flat()
          const future = futures.find((item) => item.id === futureId)
          const market = props.markets.find(item => item.descriptor.id === future?.marketId)
          const ts = moment(+timestamp * 1000)
          const transactionTimestamp = ts.isSame(new Date(), 'day')
            ? ts.format('HH:mm:ss')
            : ts.format('DD-MM-YY HH:mm:ss')
          const underlyingDecimals = market?.descriptor.underlyingDecimals || 6
          const underlyingName = market?.descriptor.underlyingName || 'N/A'

          const onClickTx = (transactionHash: string) => {
            window.open(`${getExplorerUrl()}/tx/${transactionHash}`, '_blank')
          }

          const rowFutureAlias = market && future ? getFutureAlias(market, future) : ''
          const currentFutureAlias = props.market && props.future ? getFutureAlias(props.market, props.future) : ''

          const onSelectFuture = () => {
            if(rowFutureAlias !== currentFutureAlias) {
              navigate(`/trade/${rowFutureAlias}?network=${configMode}`)
            }
          }

          return <TableRow
            key={item.futureId + index}
            style={{
              borderTop: '1px solid #383D57',
              position: 'relative',
            }}
          >
            <TableCell size={'70px'}>
              <Box>
                <Text>{item.ownerIsMaker ? 'Trade - LP' : 'Trade'}</Text>
              </Box>
            </TableCell>
            <TableCell size={'120px'}>
              <Box onClick={rowFutureAlias !== currentFutureAlias ? onSelectFuture : undefined}>
                <Text>{marketName}</Text>
              </Box>
            </TableCell>
            <TableCell size={'100px'}>
              <Box onClick={rowFutureAlias !== currentFutureAlias ? onSelectFuture : undefined}>
                <MaturityBadge
                  value={maturity * 1000}
                  showDaysLeft={false}
                />
              </Box>
            </TableCell>
            <TableCell>
              <Text>{getDirectionAlias(direction)}</Text>
            </TableCell>
            <TableCell>
              <Number
                value={BigNumber.from(notionalAmount)}
                decimals={underlyingDecimals}
                name={underlyingName}
                showName={true}
              />
            </TableCell>
            <TableCell>
              {(+rate / 10**16).toFixed(2)} %
            </TableCell>
            <TableCell>
              <Number
                value={BigNumber.from(feeAmount)}
                decimals={underlyingDecimals}
                name={underlyingName}
                showName={true}
              />
            </TableCell>
            <TableCell>
              {transactionTimestamp}
            </TableCell>
            <TableCell>
              <Box direction={'row'} justify={'end'}>
                <Button type={'text'} style={{
                  height: '25px',
                  padding: '2px 10px',
                  border: 'none'
                }} onClick={() => onClickTx(txHash)}
                >
                  <LinkOutlined style={{ color: '#BCC2DB' }} />
                </Button>
              </Box>
            </TableCell>
          </TableRow>
        })}
      </TableBody>
    </TableContainer>
  </Box>
}
