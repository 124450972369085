import React from 'react'
import {Box} from "grommet";
import {Tooltip, PlacesType} from "react-tooltip";
import {getIconByName} from "./Icon";

export interface QuestionMarkProps {
  tooltipId?: string;
  tooltipText?: string;
  size?: string;
  place?: PlacesType
}

export const QuestionMark = (props: QuestionMarkProps) => {
  const {
    tooltipId,
    tooltipText = '',
    size = '10px',
    place = 'top'
  } = props

  const { icon: iconInfo } = getIconByName('info', size)

  return <Box
    data-tooltip-id={tooltipId}
    data-tooltip-content={tooltipText}
    style={{ cursor: 'pointer' }}
  >
    {iconInfo}
    <Tooltip
      id={tooltipId}
      place={place}
      border={'1px solid #454A55'}
      opacity={1}
      style={{
        minWidth: tooltipText.length > 128 ? '256px' : 'unset',
        fontSize: '14px',
        fontWeight: 'normal',
        textAlign: 'left',
      }}
    />
  </Box>
}
