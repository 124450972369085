import React, {createContext, useContext, useState, PropsWithChildren, useEffect, useMemo} from 'react';
import {getACMAddress, getContractProvider, isProtocolUser, isWhitelistEnabled} from "../api/routerContract";
import {useAccount} from "wagmi";
import usePortfolio from "../hooks/usePortfolio";
import {MarketInfo, MarketPortfolio, MarketTradeLimiterParams} from "../types";
import useLocalTransactionsHistory, {LocalTransaction} from "../hooks/useLocalTransactionsHistory";
import {useMarketsUserData} from "../hooks/useMarketsUserData";
import {MarketUserDataState, UserPositions} from "../api/dataService";
import {getTradeLimiterParameters, getTradeLimiterStorageAddress} from "../api/contractProvider";
import {setMarketTradeLimiterParamsToLS, getMarketTradeLimiterParamsFromLS} from "../utils/localstorage";
import usePoller from "../hooks/usePoller";
import useIsTabActive from "../hooks/useIsTabActive";
import {FutureStats, useFutureStats} from "../hooks/useFutureStats";
import {CurrentFutureData, useFutureAlias} from "../hooks/useFutureAlias";
import {useUserPositions} from "../hooks/useUserPositions";

const addressesLSKey = 'rho_address_data_v1'

export interface RhoAddressData {
  isProtocolUser: boolean;
  markets: MarketInfo[];
  portfolio: MarketPortfolio[];
  portfolioInitialFetching: boolean;
  portfolioIsFetching: boolean;
  portfolioIsFetched: boolean;
  accountTotalMarginUSD: number
  refetchPortfolio: () => Promise<void>
  history: LocalTransaction[]
  addTx: (tx: LocalTransaction) => void
  marketsUserData: MarketUserDataState[]
  isMarketUserDataLoading: boolean
  tradeLimiterParams: MarketTradeLimiterParams[]
  futuresStats: FutureStats[]
  currentFuture?: CurrentFutureData
  userPositions: UserPositions[]
  refetchUserPositions: () => Promise<void>
}

const getInitialState = (): RhoAddressData => {
  let isProtocolUser = false
  try {
    const rawData = localStorage.getItem(addressesLSKey)
    if (rawData) {
      const addressData =  JSON.parse(rawData) as RhoAddressData
      isProtocolUser = addressData.isProtocolUser
    }
  } catch (e) {}

  return {
    isProtocolUser,
    markets: [],
    portfolio: [],
    portfolioInitialFetching: true,
    portfolioIsFetching: false,
    portfolioIsFetched: false,
    accountTotalMarginUSD: 0,
    refetchPortfolio: async () => {},
    history: [],
    addTx: () => {},
    marketsUserData: [],
    isMarketUserDataLoading: false,
    tradeLimiterParams: [],
    futuresStats: [],
    currentFuture: undefined,
    userPositions: [],
    refetchUserPositions: async () => {}
  }
}

const initialState = getInitialState()
const UserDataContext = createContext(initialState);

export const useProtocolData = () => useContext(UserDataContext);

export const ProtocolDataProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [addressData, setAddressData] = useState<RhoAddressData>(initialState)
  const [contractProviderAddress, setContractProviderAddress] = useState('')
  const [acmAddress, setACMAddress] = useState('')
  const [tradeLimiterStorageAddress, setTradeLimiterStorageAddress] = useState('')
  const [tradeLimiterParams, setTradeLimiterParams] = useState<MarketTradeLimiterParams[]>([])

  const { address: userAddress, isConnected } = useAccount()
  const isTabActive = useIsTabActive()
  const {
    markets,
    portfolio,
    isInitialFetching: portfolioInitialFetching,
    isFetched: portfolioIsFetched,
    isFetching: portfolioIsFetching,
    accountTotalMarginUSD,
    refetch: refetchPortfolio
  } = usePortfolio({ userAddress })

  const portfolioMarketIds = useMemo(() => portfolio.map(item => item.descriptor.id), [portfolio])

  const {
    marketsUserData,
    isLoading: isMarketUserDataLoading,
    refetch: refetchMarketsUserData
  } = useMarketsUserData({ userAddress, marketIds: portfolioMarketIds })

  const { futuresStats } = useFutureStats({
    markets
  })
  const currentFuture = useFutureAlias(markets)
  const { userPositions, refetch: refetchUserPositions, isLoading: isUserPositionsLoading } = useUserPositions(userAddress)

  const { history, addTx } = useLocalTransactionsHistory()

  const updateLocalStorageCache = () => {
    try {
      localStorage.setItem(addressesLSKey, JSON.stringify(addressData))
    } catch (e) {}
  }

  useEffect(() => {
    updateLocalStorageCache()
  }, [addressData])

  useEffect(() => {
    const loadData = async () => {
      if(tradeLimiterStorageAddress && markets.length > 0) {
        const data = await Promise.allSettled(markets.map(async (market) => {
          const { descriptor: { id: marketId } } = market
          let params = getMarketTradeLimiterParamsFromLS(marketId)
          if(params) {
            // console.log(`Trade limiter params [${marketId}}]: found in local cache`, params)
          } else {
            params = await getTradeLimiterParameters(tradeLimiterStorageAddress, marketId)
            // console.log(`Trade limiter params [${marketId}}]: fetched from the Protocol`, params)
          }
          return {
            marketId,
            params
          }
        }))
        const items = data
          .map(item => (item.status === 'fulfilled' ? item.value : null))
          .filter(item => item) as MarketTradeLimiterParams[]
        setTradeLimiterParams(items)

        items.forEach(item => {
          setMarketTradeLimiterParamsToLS(item.marketId, item.params)
        })
        console.log('ProtocolDataProvider: markets trade limiter params:', items)
      }
    }

    loadData()
  }, [tradeLimiterStorageAddress, markets.length]);

  useEffect(() => {
    const loadProtocolConfiguration = async () => {
      try {
        const providerAddressData = await getContractProvider()
        const acmAddressData = await getACMAddress(providerAddressData)
        const tradeLimiterStorageAddressData = await getTradeLimiterStorageAddress(providerAddressData)
        setContractProviderAddress(providerAddressData)
        setACMAddress(acmAddressData)
        setTradeLimiterStorageAddress(tradeLimiterStorageAddressData)
      } catch (e) {
        console.error(`ProtocolDataProvider: failed to load protocol configuration:`, e)
      }
    }

    loadProtocolConfiguration()
  }, []);

  useEffect(() => {
    const updateUserData = async () => {
      if(userAddress && isConnected && acmAddress) {
        try {
          const whitelistIsEnabled = await isWhitelistEnabled()
          const isUser = whitelistIsEnabled ? await isProtocolUser(acmAddress, userAddress) : true
          console.log(`User address: ${userAddress}, is protocol user: ${isUser}, whitelistIsEnabled: ${whitelistIsEnabled}`)
          setAddressData((addressData) => {
            return {
              ...addressData,
              isProtocolUser: isUser
            }
          })
        } catch (e) {
          console.error(`ProtocolDataProvider: cannot get data:`, e)
        }
      }
    }

    updateUserData()
  }, [userAddress, isConnected, acmAddress]);

  usePoller(() => {
    if(isTabActive) {
      if(!portfolioIsFetching) {
        refetchPortfolio()
      }
      if(!isMarketUserDataLoading) {
        refetchMarketsUserData(false)
      }
      if(!isUserPositionsLoading)  {
        refetchUserPositions()
      }
    }
  }, 5000)

  return <UserDataContext.Provider value={{
    ...addressData,
    markets,
    portfolio,
    portfolioInitialFetching,
    portfolioIsFetching,
    portfolioIsFetched,
    accountTotalMarginUSD,
    refetchPortfolio,
    history,
    addTx,
    marketsUserData,
    isMarketUserDataLoading,
    tradeLimiterParams,
    futuresStats,
    currentFuture,
    userPositions,
    refetchUserPositions
  }}>
    {children}
  </UserDataContext.Provider>
};
