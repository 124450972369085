import React from "react";
import {Box, Text} from "grommet";
import {useVault} from "../../providers/VaultDataProvider";
import {bnToDecimal, formatNumber} from "../../utils";
import {Skeleton} from "antd";
import {Number, QuestionMark} from "../../components";
import tooltips from "../../utils/tooltips";

export const EarnHeader = () => {
  const {
    vault,
  } = useVault()

  return <Box direction={'row'} justify={'between'} align={'center'}>
    <Box gap={'4px'}>
      <Text size={'40px'} weight={500} color={'textHeader'}>Earn</Text>
      <Text>Earn APY on your USDT</Text>
    </Box>
    <Box direction={'row'} gap={'44px'} margin={{ right: '24px', top: '10px' }}>
      <Box gap={'6px'} align={'end'}>
        {!vault
          ? <Skeleton.Input active size={'small'} style={{ width: '160px', height: '32px' }} />
          : <Number
              value={vault.totalAssets}
              decimals={vault.underlyingDecimals}
              fontSize={'28px'}
              fontColor={'textHeader'}
              name={vault.underlyingName}
              showName={true}
            />
        }
        <Box direction={'row'} align={'center'} gap={'4px'}>
          <Text color={'textSecondary'}>TVL</Text>
          <QuestionMark tooltipId={'header_tvl'} tooltipText={tooltips.tvl} />
        </Box>
      </Box>
    </Box>
  </Box>
}
