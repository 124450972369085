import {RiskDirection, RiskDirectionType} from "../../types";
import {Box, Text} from "grommet";
import React from "react";
import styled from "styled-components";

const RiskDirectionContainer = styled(Box)`
    padding: 2px 6px;
    border-radius: 6px;
`


export const RiskDirectionTag = (props: {
  direction: RiskDirection | RiskDirectionType | null
}) => {
  const { direction } = props

  const color = direction === null
    ? 'text'
    : (direction === RiskDirection.payer || direction === RiskDirectionType.PAYER) ? 'ratePositive' : 'rateNegative'

  return <RiskDirectionContainer
    border={{ color, size: '1px' }}
    align={'center'}
  >
    <Text
      color={color}
      size={'12px'}
    >
      {direction === null ? 'None'
        : (direction === RiskDirection.payer || direction === RiskDirectionType.PAYER)
          ? 'Long'
          : 'Short'}
    </Text>
  </RiskDirectionContainer>
}
