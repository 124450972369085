import React, {useEffect, useState} from 'react'
import {TradeProps} from "../common";
import {PanelMenu} from "./Menu";
import {WidgetContainer, WidgetsBox} from "../../../components";
import {MenuItemId} from "./common";
import {PanelContent} from "./Content";
import {getLsKey, RhoLsKey, setLsKey} from "../../../utils/localstorage";
import styled from "styled-components";
import {useProtocolData} from "../../../providers/ProtocolDataProvider";

export type PanelVisibilityStatus = 'closed' | 'opened-small' | 'opened-default'
export type BottomMenuFilterMode = 'all' | 'market' | 'instrument'
const initialVisibilityStatus = (getLsKey(RhoLsKey.bottomPanelVisibilityStatus) || 'closed') as PanelVisibilityStatus
const LSKeyFilterMode = 'rho_client_bottom_panel_filter_mode_v1'

const getDefaultFilterMode = () => {
  return window.localStorage.getItem(LSKeyFilterMode) as BottomMenuFilterMode || 'all'
}

const saveLastUserFilterMode = (mode: BottomMenuFilterMode) => {
  window.localStorage.setItem(LSKeyFilterMode, mode)
}

export const WidgetContainerWithZeroLeftPadding = styled(WidgetContainer)`
    padding-left: 0;
`

export const BottomPanel = (props: TradeProps & { overlay?: boolean }) => {
  const { futureId } = props

  const { userPositions } = useProtocolData()
  const openPosition = userPositions.find(item => item.futureId === futureId && item.openPosition.notional)

  const [isInitialSetup, setInitialSetup] = useState(false)
  const [visibilityStatus, setPanelStatus] = useState<PanelVisibilityStatus>(initialVisibilityStatus)
  const [activeItem, setActiveItem] = useState<MenuItemId>()
  const [isLoading, setLoading] = useState(false)
  const [filterMode, setFilterMode] = useState<BottomMenuFilterMode>(getDefaultFilterMode())

  useEffect(() => {
    setLsKey(RhoLsKey.bottomPanelVisibilityStatus, visibilityStatus)
    if(visibilityStatus !== 'closed') {
      if(!activeItem) {
        setActiveItem(MenuItemId.openPositions)
      }
    }
  }, [visibilityStatus]);

  useEffect(() => {
    if(!isInitialSetup) {
      if(openPosition && activeItem === 'openPositions') {
        setPanelStatus('opened-small')
        setFilterMode('instrument')
        setInitialSetup(true)
      }
    }
  }, [isInitialSetup, openPosition]);

  const onMenuItemSelect = (id: MenuItemId) => {
    if(activeItem !== id) {
      setActiveItem(id)
      setLoading(true)
    }
    if(visibilityStatus === 'closed' || (visibilityStatus === 'opened-small' && id !== 'openPositions')) {
      setPanelStatus('opened-default')
    }
  }

  const onFilterModeSelect = (mode: BottomMenuFilterMode) => {
    setFilterMode(mode)
    setLoading(true)
    if(visibilityStatus === 'closed' || visibilityStatus === 'opened-small') {
      setPanelStatus('opened-default')
    }

    saveLastUserFilterMode(mode)
  }

  const onChangeVisibilityStatus = (status: PanelVisibilityStatus) => {
    setPanelStatus(status)
  }

  return <WidgetsBox
    overlay={props.overlay}
  >
    <WidgetContainerWithZeroLeftPadding>
      <PanelMenu
        visibilityStatus={visibilityStatus}
        activeItem={activeItem}
        onSelect={onMenuItemSelect}
        filterMode={filterMode}
        setFilterMode={onFilterModeSelect}
        onChangeVisibilityStatus={onChangeVisibilityStatus}
      />
      <PanelContent
        {...props}
        visibilityStatus={visibilityStatus}
        activeItem={activeItem}
        isLoading={isLoading}
        setLoading={setLoading}
        filterMode={filterMode}
      />
    </WidgetContainerWithZeroLeftPadding>
  </WidgetsBox>
}
