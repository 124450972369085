import React, {useEffect, useState} from 'react'
import {Box, Spinner, Text} from "grommet";
import {EarnWidget, TableRow, VaultProps} from "./common";
import moment from 'moment'
import {
  GetHistoryParams,
  getVaultHistory,
  getVaultHistoryCount,
  VaultHistoryItem,
  VaultOpType,
  VaultOpTypeAlias
} from "../../api/vault-api";
import Decimal from 'decimal.js'
import {Button, Pagination, Select} from "antd";
import {useAccount} from "wagmi";
import {Search} from "grommet-icons";
import usePoller from "../../hooks/usePoller";
import useIsTabActive from "../../hooks/useIsTabActive";

const Badge = (props: {
  title: string | React.ReactNode;
  color?: string
  description: string
  align?: string
}) => {
  const { title } = props

  return <Box align={props.align || 'start'} gap={'2px'}>
    {typeof title === 'string'
      ? <Text size={'14px'} color={props.color || 'accentWhite'}>{props.title}</Text>
      : title
    }
    <Text color={props.color || 'textSecondary'}>{props.description}</Text>
  </Box>
}

const DefaultLimit = 8

type VaultOpTypeUI = (VaultOpType | 'all')

const TxHistoryItem = (props: VaultProps & { item: VaultHistoryItem }) => {
  const { vault } = props
  const { txHash, opType, blockTimestamp, assets } = props.item

  const ts = moment(blockTimestamp)
  const title = ts.isSame(new Date(), 'day')
    ? ts.format('HH:mm:ss')
    : ts.format('DD-MM-YY HH:mm:ss')
  const action = opType === 'deposit'
    ? 'Sent'
    : opType === 'withdrawal'
      ? 'Received'
      : 'Requested'
  const sign = opType === 'deposit' ? '+' : ''
  const color = opType === 'deposit'
    ? 'positiveValue' :
    opType === 'withdrawal'
      ? 'text'
      : 'text'

  const underlyingDecimals = vault?.underlyingDecimals || 6
  const underlyingName = vault?.underlyingName || 'USDT'

  const amount = <Box direction={'row'} align={'center'} gap={'6px'}>
    <Text size={'14px'} color={color}>
      {sign}{
        new Decimal(assets).div(10 ** underlyingDecimals).toString()
      }
      {' '}
      {underlyingName}
    </Text>
  </Box>

  return <TableRow key={txHash} items={[
    <Badge title={VaultOpTypeAlias[opType]} description={title} />,
    <Badge title={amount} description={action} align={'end'} />,
  ]} />
}

export const TransactionsHistory = (props: VaultProps) => {
  const { address: userAddress } = useAccount()
  const [opType, setOpType] = useState<VaultOpTypeUI>('all')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // Status to track loading internally
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState<Error>()
  const [historyCount, setHistoryCount] = useState<number>(0)
  const [history, setHistory] = useState<VaultHistoryItem[]>([])
  const [currentPage, setCurrentPage] = useState(1)

  const isTabActive = useIsTabActive()

  useEffect(() => {
    fetchHistory(1)
  }, [userAddress, opType]);

  usePoller(() => {
    if(userAddress && !isFetching && isTabActive && currentPage === 1) {
      fetchHistory(currentPage, false)
    }
  }, 5_000)

  const fetchHistory = async (
    page= 1,
    updateLoadingStatus = true
  ) => {
    try {
      setLoadingError(undefined)
      if(!userAddress) {
        setHistoryCount(0)
        setHistory([])
        return
      }
      if(updateLoadingStatus) {
        setIsLoading(true)
      }
      setIsFetching(true)
      const params: GetHistoryParams = {
        userAddress,
        offset: (page - 1) * DefaultLimit,
        limit: DefaultLimit
      }
      if(opType !== 'all') {
        params.opType = opType
      }
      // await new Promise(resolve => setTimeout(resolve, 1000))
      const [countData, items] = await Promise.all([
        getVaultHistoryCount(params),
        getVaultHistory(params)
      ])
      setHistoryCount(countData)
      setHistory(items)
      // console.log('Loaded vault history: ', items, 'count:', countData)
    } catch (e) {
      console.error('Failed to fetch history:', e)
      setHistoryCount(0)
      setHistory([])
      setLoadingError(e as Error)
    } finally {
      setIsLoading(false)
      setIsFetching(false)
    }
  }

  return <Box gap={'8px'}>
    <Box justify={'between'} direction={'row'} align={'center'}>
      <Text color={'textSecondary'} size={'16px'}>Transactions history</Text>
      <Box width={'200px'}>
        <Select
          value={opType}
          options={['all', 'deposit', 'withdrawal', 'withdrawal_request']
            .map((item) => {
              return {
                value: item,
                label: VaultOpTypeAlias[item as VaultOpTypeUI]
              }
          })}
          onChange={(value) => {
            setOpType(value)
            setCurrentPage(1)
          }}
        />
      </Box>
    </Box>
    <EarnWidget style={{
      padding: '16px 0',
      height: `${DefaultLimit * 62}px`,
      position: 'relative',
    }}>
      {isLoading
        ?
            <Box
              width={'100%'}
              height={'100%'}
              align={'center'}
              justify={'center'}
              style={{ position: 'absolute' }}
          >
              <Spinner color={'spinner'} />
          </Box>
        : loadingError
          ? <Box align={'center'} justify={'center'} gap={'16px'} height={'100%'}>
            <Text size={'16px'}>Failed to load transactions list</Text>
            <Button type={'primary'} onClick={() => {
              const nextPage = 1
              setCurrentPage(nextPage)
              fetchHistory(nextPage)
            }}>Retry</Button>
          </Box>
          :
          history.length === 0
            ?
            <Box height={'100%'} align={'center'} justify={'center'} gap={'16px'}>
              <Search color={'text'} />
              <Text size={'20px'}>
                {opType === 'all' ? 'History is empty' : 'No items found'}
              </Text>
            </Box>
            :
          history.map(item =>
            <TxHistoryItem key={item.txHash} {...props} item={item} />
          )
      }
    </EarnWidget>
    <Box align={'center'}>
      <Pagination
        disabled={isLoading}
        defaultCurrent={currentPage}
        current={currentPage}
        total={historyCount}
        onChange={(page) => {
          setCurrentPage(page)
          fetchHistory(page)
        }}
      />
    </Box>
  </Box>
}
