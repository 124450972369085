import React from 'react'
import {Box, Button, Spinner, Text} from "grommet";
import styled from "styled-components";

interface ControlButtonProps {
  disabled?: boolean
  isActive: boolean,
  text: string
  onClick: () => void
}

const ControlButtonContainer = styled(Box)`
  &:hover {
    border-color: #707FC2;
  }
`

export const ControlButton = (props: ControlButtonProps) => {
  const { isActive, text, disabled = false, onClick } = props

  return <ControlButtonContainer
    background={'#303340'}
    border={{ size: '1px', color: props.isActive ? '#707FC2' : 'borderListItem' }}
    pad={'4px 6px'} round={'3px'}
    style={{
      userSelect: 'none',
      zIndex: props.isActive ? 1 : 0,
      opacity: disabled ? 0.4 : 1
  }}
    onClick={disabled ? undefined : onClick}
  >
    <Text size={'12px'} color={isActive ? 'text' : 'textButtonSecondary'}>
      {text}
    </Text>
  </ControlButtonContainer>
}

export type PrimaryButtonStatus = 'default' | 'success' | 'error'

export interface PrimaryButtonProps {
  viewType?: 'default' | 'secondary'
  status?: PrimaryButtonStatus
  disabled?: boolean
  loading?: boolean
  text?: string
  size?: string
  onClick?: () => void
  style?: any
}

const ButtonBg: Record<PrimaryButtonStatus, string> = {
  default: '#444CE4',
  success: '#3CB473',
  error: '#D65C5C'
}

const ButtonHoverBg: Record<PrimaryButtonStatus, string> = {
  default: '#5258e3',
  success: '#51c486',
  error: '#e37474'
}

const ButtonActiveBg: Record<PrimaryButtonStatus, string> = {
  default: '#3e44ad',
  success: '#29965b',
  error: '#c44b4b'
}

const SecondaryButtonBg: Record<PrimaryButtonStatus, string> = {
  default: '#2E2E38',
  success: '#2E2E38',
  error: '#2E2E38'
}

export const getButtonBg = (props: PrimaryButtonProps, params: {
  isActive?: boolean
  isHover?: boolean
} = {}) => {
  const { disabled = false, status = 'default', viewType = 'default' } = props
  const { isActive = false, isHover = false } = params

  if(!disabled) {
    if(isHover) {
      return ButtonHoverBg[status]
    }

    if(isActive) {
      return ButtonActiveBg[status]
    }
  }

  if(viewType === 'default') {
    return ButtonBg[status]
  }

  return SecondaryButtonBg[status]
}

const PrimaryButtonContainer = styled(Button)<PrimaryButtonProps>`
    background-color: ${ props => getButtonBg(props) };
    min-width: 180px;
    min-height: 50px;
    padding: 10px 16px;
    border-radius: 6px;
    transition: background-color 250ms;
    font-weight: ${
      props => props.viewType === 'secondary'
            ? 400
            : 500
    };

    &:hover {
        background-color: ${ props => getButtonBg(props, { isHover: true }) };
    }
    &:active {
        background-color: ${ props => getButtonBg(props, { isActive: true }) };
    }
`

export const PrimaryButton = (props: PrimaryButtonProps) => {
  const {
    disabled = false,
    loading = false,
    text = '',
    size = '18px',
    onClick,
    ...rest
  } = props

  return <PrimaryButtonContainer
    disabled={disabled}
    onClick={onClick}
    {...rest}
  >
    {loading
      ?
      <Box width={'100%'} direction={'row'} justify={'center'} align={'center'} gap={'16px'}>
        <Spinner size={'xsmall'} color={'white'} />
        <Text size={size}>{text}</Text>
      </Box>
      :
      <Box>
        <Text color={'#F4F4F6'} size={size}>{text}</Text>
      </Box>
    }
  </PrimaryButtonContainer>
}

const GradientButtonWrap = styled(Button)`
    padding: 1px;
    background: linear-gradient(to right, #DB70D6, #9470DB, #7079DB, #70A6DB);
    border-radius: 4px;
    transition: background 150ms;

    &:hover {
        background: linear-gradient(to right, #DB70D6, #9470DB, #7079DB);
    }
`

const GradientButtonContainer = styled(Box)`
    border-radius: 4px;
    background: #292A32;
    padding: 8px;
    transition: background 150ms, color 150ms;
    
    &:hover {
        color: white;
        background: linear-gradient(to right, #DB70D6, #9470DB, #7079DB);

        > span {
            background: #E4E4E8;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
`

const GradientButtonText = styled(Text)`
    background: linear-gradient(90.61deg, #DB70D6 11.08%, #9470DB 36.68%, #7079DB 62.68%, #70A6DB 88.67%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
    padding: 0 8px;
`

export const GradientButton = (props: PrimaryButtonProps) => {
  const {
    disabled,
    loading = false,
    text = '',
    size = '14px',
    onClick,
    ...rest
  } = props

  return <GradientButtonWrap
    disabled={disabled}
    onClick={onClick}
    {...rest}
  >
    <GradientButtonContainer>
      {loading
        ?
        <Box width={'100%'} direction={'row'} justify={'center'} align={'center'} gap={'16px'}>
          <Spinner size={'xsmall'} color={'white'} />
          <Text size={size}>{text}</Text>
        </Box>
        :
        <GradientButtonText size={size}>{text}</GradientButtonText>
      }
    </GradientButtonContainer>
  </GradientButtonWrap>
}

const FilterButtonContainer = styled(Box)<{ isActive: boolean; isDisabled: boolean; }>`
    height: 28px;
    padding: 0 10px;
    border-radius: 3px;
    background: ${props => props.isActive
      ? '#2E2E38'
      : 'unset'
    };
    color: ${props => props.isActive
            ? '#D6D6DC'
            : '#818298'
    };
    user-select: none;
    
    ${props => props.isDisabled &&
      `
        opacity: 0.4;
      `
    }
    
    ${props => !props.isDisabled &&
      `
      cursor: pointer;

      &:hover {
        background: #2E2E38;
        color: #D6D6DC;
      }
      `
    }
;
`

export interface FilterButtonProps {
  id: string
  text: string
  isActive: boolean
  isDisabled?: boolean
  onClick: (id: string) => void
}

export const FilterButton = (props: FilterButtonProps) => {
  const { id, text, isActive, isDisabled = false, onClick } = props

  return <FilterButtonContainer
    isActive={isActive}
    isDisabled={isDisabled}
    align={'center'}
    justify={'center'}
    onClick={isDisabled ? undefined : () => onClick(id)}
  >
    {text}
  </FilterButtonContainer>
}
