import { useEffect, useState } from 'react';
import {getActiveMarketsInfo} from "../api/viewContract";
import {MarketInfo} from "../types";

const useMarkets = () => {
  const [markets, setMarkets] = useState<MarketInfo[]>([]);

  const loadMarkets = async () => {
    try {
      const data = await getActiveMarketsInfo()
      setMarkets(data)
      console.log('useMarkets: markets updated', data)
    } catch (e) {
      console.error('useMarkets: failed to load markets: ', e)
    }
  }

  useEffect(() => {
    loadMarkets()
  }, []);

  return {
    markets
  };
};

export default useMarkets;
