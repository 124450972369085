import React, {useMemo} from 'react'
import {Box, Text} from "grommet";
import {TradingViewChart} from "../trading-view";
import {WidgetContainer} from "../../../components";
import {generateTradingViewItems} from "../mocks";
import {StatsWidgetProps} from "../common";
import moment from "moment";
import {Time} from "lightweight-charts";

export const TradingVolumeWidget = (props: StatsWidgetProps) => {
  const { stats } = props

  const items = useMemo(() => {
    return stats.runningTotal.map(item => {
      return {
        time: moment(item.date).unix() as Time,
        value: +item.running_total
      }
    }).sort((a, b) => {
      return moment(a.time).unix() - moment(b.time).unix()
    })
  }, [stats.runningTotal])

  return <WidgetContainer style={{ flex: 1 }}>
    <Box>
      <Text color={'accentWhite'}>Trading Volume</Text>
      <Text>Cumulative trading volume on Rho</Text>
    </Box>
    <Box margin={{ top: '8px' }}>
      <TradingViewChart
        isLoading={props.isLoading}
        height={300}
        lineItems={items}
        tooltipTitle={'Trading volume'}
        tooltipValuePrefix={'$'}
      />
    </Box>
  </WidgetContainer>
}
