import {useMemo, useState} from "react";
import {RiskDirection, RiskDirectionType} from "../types";

export interface LocalTransaction {
  transactionHash: string
  status: 'pending' | 'confirmed' | 'failed'
  type: 'trade' | 'deposit' | 'withdraw' | 'cross-chain-deposit'
  marketId: string
  direction: RiskDirection | RiskDirectionType | null
  amount: string
  underlyingName: string
  rate: string
}

const useLocalTransactionsHistory = () => {
  const [history, setHistory] = useState<LocalTransaction[]>([])

  const addTx = (tx: LocalTransaction) => {
    setHistory((items) => {
      return [tx, ...items]
    })
  }

  return useMemo(() => {
    return {
      history,
      addTx
    }
  }, [history])
}

export default useLocalTransactionsHistory
