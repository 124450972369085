import React, {useEffect, useMemo, useState} from 'react'
import {Box, Text} from "grommet";
import {WidgetContainer} from "../../components";
import moment from "moment";
import {TradingVolumeWidget} from "./trading-volume";
import {TradesNumber} from "./trades-number";
// import {TotalFeesPaid} from "./total-fees-paid";
// import {MmTakerFees} from "./mm-taker-fees";
import {Select, Skeleton, Typography} from "antd";
import {
  AllStatPeriods,
  BaseStatsParams, getFeesByDays, getRunningTotal,
  getTotalFees,
  getTotalVolume,
  StatsPeriod,
  StatsPeriodAlias,
  getOpenInterestDaily, getVolumeByMarket, getOpenInterestByMarket, getMarginUsage,
} from "../../api/dataService";
import {abbreviateNumber, formatNumber} from "../../utils";
import {toast} from "react-toastify";
import {Tooltip} from "react-tooltip";
import {ProtocolStats, StatsWidgetProps} from "./common";
import {TotalFeesPaid} from "./total-fees-paid";
import {OpenInterestDaily} from "./open-interest-daily";
import {fullMarketNameMap} from "../../mappers";

const StatsBadge = (props: {
  isLoading?: boolean
  tooltipId?: string
  tooltipText?: string
  text: string
  description: string
  lastUpdateTimestamp: number
}) => {
  const {
    isLoading= false,
    tooltipId,
    tooltipText,
    lastUpdateTimestamp
  } = props

  return <WidgetContainer
    gap={'8px'}
    style={{
      padding: '24px',
      flex: 1,
  }}
  >
    {(isLoading && (Date.now() - lastUpdateTimestamp > 60 * 1000))
      ? <Skeleton.Input active size={'small'} style={{ width: '140px', height: '29px' }} />
      : <Text
        size={'24px'}
        weight={500}
        color={'accentWhite'}
        data-tooltip-id={tooltipId}
        data-tooltip-html={tooltipText}
        // style={{ opacity: isLoading ? 0.4 : 1 }}
      >
        {props.text}
        <Tooltip
          id={tooltipId}
          place={'bottom-start'}
          border={'1px solid #454A55'}
          opacity={1}
          style={{
            fontSize: '14px',
            fontWeight: 'normal',
            textAlign: 'left',
          }}
        />
      </Text>
    }
    <Text size={'16px'} weight={500}>
      {props.description}
    </Text>
  </WidgetContainer>
}

const defaultStatsData: ProtocolStats = {
  totalVolume: '0',
  totalTraders: '0',
  totalProtocolFees: '0',
  totalMakerFees: '0',
  totalTakerFees: '0',
  runningTotal: [],
  feesByDays: [],
  openInterestDaily: [],
  marginUsage: [],
  volumeByMarket: [],
  openInterestByMarket: []
}

export const StatsPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [lastUpdateTimestamp, setLastUpdateTimestamp] = React.useState<number>(0);
  const [abortController, setAbortController] = useState<AbortController>()

  const [selectedPeriod, setSelectedPeriod] = React.useState<StatsPeriod>('30d');
  const [protocolStats, setProtocolStats] = useState<ProtocolStats>(defaultStatsData)

  useEffect(() => {
    const loadData = async () => {
      try {
        if(abortController) {
          abortController.abort('New request')
        }

        const controller = new AbortController();
        const signal = controller.signal;

        setAbortController(controller)
        setIsLoading(true)

        const params: BaseStatsParams = {
          period: selectedPeriod
        }

        const [
          totalVolumeData,
          // totalTradersData,
          // totalFeesData,
          runningTotal,
          // feesByDays,
          openInterestDaily,
          volumeByMarket,
          openInterestByMarket,
          marginUsage
        ] = await Promise.allSettled([
          getTotalVolume(params, signal),
          // getTotalTraders(params, signal),
          // getTotalFees(params, signal),
          getRunningTotal(params, signal),
          // getFeesByDays(params, signal),
          getOpenInterestDaily(params, signal),
          getVolumeByMarket(params, signal),
          getOpenInterestByMarket(params, signal),
          getMarginUsage(params, signal),
        ])

        const newStats: ProtocolStats = defaultStatsData

        if(totalVolumeData.status === 'fulfilled') {
          newStats.totalVolume = Math.round(Number(totalVolumeData.value)).toString()
        }
        // if(totalTradersData.status === 'fulfilled') {
        //   newStats.totalTraders = totalTradersData.value || '0'
        // }
        // if(totalFeesData.status === 'fulfilled') {
        //   let totalMakerFees = '0'
        //   let totalTakerFees = '0'
        //   totalFeesData.value.forEach(( item) => {
        //     const { totalFees, side } = item
        //     if(side === 'maker') {
        //       totalMakerFees = totalFees
        //     } else if(side === 'taker') {
        //       totalTakerFees = totalFees
        //     }
        //   })
        //   newStats.totalMakerFees = totalMakerFees
        //   newStats.totalTakerFees = totalTakerFees
        //   newStats.totalProtocolFees = (+totalTakerFees - +totalMakerFees).toString()
        // }
        if(runningTotal.status === 'fulfilled') {
          newStats.runningTotal = runningTotal.value
        }
        // if(feesByDays.status === 'fulfilled') {
        //   newStats.feesByDays = feesByDays.value
        // }
        // if(currentOpenInterest.status === 'fulfilled') {
        //   newStats.currentOpenInterest = currentOpenInterest.value
        // }
        if(openInterestDaily.status === 'fulfilled') {
          newStats.openInterestDaily = openInterestDaily.value
        }
        if(volumeByMarket.status === 'fulfilled') {
          newStats.volumeByMarket = volumeByMarket.value
        }
        if(openInterestByMarket.status === 'fulfilled') {
          newStats.openInterestByMarket = openInterestByMarket.value
        }
        if(marginUsage.status === 'fulfilled') {
          newStats.marginUsage = marginUsage.value
        }

        setProtocolStats(newStats)
        setLastUpdateTimestamp(Date.now())
      } catch (e) {
        console.error('Failed to load stats', e);
        let message = (e as Error).message
        toast.error(<Typography.Text copyable={{ text: message }} style={{ color: 'white' }}>
          {'Failed to load stats: ' + message}
        </Typography.Text>, {
          closeOnClick: false
        })
      } finally {
        setIsLoading(false)
      }
    }

    loadData();
  }, [selectedPeriod]);

  const widgetProps: StatsWidgetProps = {
    isLoading,
    stats: protocolStats
  }

  const totalOpenInterest = useMemo(() => {
    return protocolStats.openInterestByMarket.reduce((acc, item) => {
      return acc + item.openInterest
    }, 0)
  }, [protocolStats.openInterestByMarket])

  const currentMarginUsage = useMemo(() => {
    const sumIMR = protocolStats.marginUsage.reduce((acc, item) => {
      return acc + +item.totalIMR
    }, 0)
    const sumCollateral = protocolStats.marginUsage.reduce((acc, item) => {
      return acc + +item.totalCollateral
    }, 0)
    return sumCollateral !== 0 ? sumIMR / sumCollateral * 100 : 0
  }, [protocolStats.marginUsage])

  return <Box width={'calc(100% - 128px)'} margin={'48px 32px'}>
    <Box justify={'between'} direction={'row'}>
      <Box gap={'4px'}>
        <Text size={'32px'} color={'accentWhite'}>Overview stats</Text>
        <Box
          data-tooltip-id={'stats_last_updated'}
          data-tooltip-content={lastUpdateTimestamp
            ? moment(lastUpdateTimestamp).format('DD/MM/YYYY HH:mm:ss')
            : `N/A`}
        >
          <Tooltip
            id={'stats_last_updated'}
            place={'top-start'}
            border={'1px solid #454A55'}
            opacity={1}
            style={{
              fontSize: '14px',
              fontWeight: 'normal',
              textAlign: 'left',
            }}
          />
          <Text size={'16px'}>Last updated: {lastUpdateTimestamp ? moment(lastUpdateTimestamp).fromNow() : '-'}</Text>
        </Box>
      </Box>
      <Box width={'140px'} justify={'end'}>
        <Select
          value={selectedPeriod}
          defaultValue={selectedPeriod}
          size={'large'}
          onChange={(value) => {
            setSelectedPeriod(value)
          }}
        >
          {AllStatPeriods.map(value => {
            return <Select.Option value={value} key={value}>
              {StatsPeriodAlias[value]}
            </Select.Option>
          })}
        </Select>
      </Box>
    </Box>
    <Box margin={{ top: '32px' }} direction={'row'} gap={'24px'}>
      <StatsBadge
        isLoading={isLoading}
        lastUpdateTimestamp={lastUpdateTimestamp}
        text={`$${abbreviateNumber(protocolStats.totalVolume)}`}
        description={'Total Volume'}
        tooltipId={'stats_total_volume_badge'}
        tooltipText={
          `Total Volume: ${
            formatNumber(protocolStats.totalVolume, 'USD', false, 2)
          }
          ${protocolStats.volumeByMarket.map(item => {
            let marketName = item.marketName + ' ' + item.instrumentName
            if(fullMarketNameMap[marketName]) {
              marketName = fullMarketNameMap[marketName]
            }
            return `
                    <div style="margin-top: 8px;">
                        ${marketName}:<br/>${formatNumber(item.usdVolume, 'USD', false, 2)}
                    </div>
                `
          })
          .join('')}
        `}
      />
      <StatsBadge
        isLoading={isLoading}
        lastUpdateTimestamp={lastUpdateTimestamp}
        text={`$${abbreviateNumber(totalOpenInterest)}`}
        description={'Current Open Interest'}
        tooltipId={'stats_current_oi'}
        tooltipText={`
        Total Open Interest:
        </br>
        ${formatNumber(totalOpenInterest, 'USD', false, 2)}
        
        ${protocolStats.openInterestByMarket.map(item => {
          let marketName = item.marketName
          if(fullMarketNameMap[marketName]) {
            marketName = fullMarketNameMap[marketName]
          }
          return `
                    <div style="margin-top: 8px;">
                        ${marketName}:<br/>${formatNumber(item.openInterest, 'USD', false, 2)}
                    </div>
                `
        })
          .join('')}
        `}
      />
      {/*<StatsBadge*/}
      {/*  isLoading={isLoading}*/}
      {/*  text={protocolStats.totalTraders}*/}
      {/*  description={'Total traders'}*/}
      {/*/>*/}
      {/*<StatsBadge*/}
      {/*  isLoading={isLoading}*/}
      {/*  text={`$${abbreviateNumber(+protocolStats.totalTakerFees)}`}*/}
      {/*  description={'Total Fees'}*/}
      {/*  tooltipId={'stats_total_fees'}*/}
      {/*  tooltipText={`Total taker fees:</br>${formatNumber(protocolStats.totalTakerFees, 'USD', false, 2)}</br></br>Total maker fees:</br>${formatNumber(protocolStats.totalMakerFees, 'USD', false, 2)}</br></br>Total protocol fees:</br>${formatNumber(protocolStats.totalProtocolFees, 'USD', false, 2)}`}*/}
      {/*/>*/}
      <StatsBadge
        isLoading={isLoading}
        lastUpdateTimestamp={lastUpdateTimestamp}
        text={`${currentMarginUsage.toFixed(2)}%`}
        description={'Margin Usage'}
        tooltipId={'stats_margin_usage'}
        tooltipText={`Margin Usage:</br>${currentMarginUsage.toFixed(2)}%`}
      />
    </Box>
    <Box margin={{ top: '24px' }} gap={'24px'}>
      <Box direction={'row'} gap={'24px'}>
        <TradingVolumeWidget {...widgetProps} />
        {/*<TradesNumber {...widgetProps} />*/}
      </Box>
      {/*<Box direction={'row'} gap={'24px'}>*/}
      {/*  <TotalFeesPaid {...widgetProps} />*/}
      {/*  /!*<MmTakerFees />*!/*/}
      {/*</Box>*/}
      <Box>
        <OpenInterestDaily {...widgetProps} />
      </Box>
    </Box>
  </Box>
}
