import React from 'react'
import styled from "styled-components";

const Container = styled.div`
    svg {
        width: 250px;
        display: block;
    }

    path {
        stroke-linecap: round;
        stroke-width: 10;
    }

    path.grey {
        stroke: #3D3E43;
    }

    path.blue {
        stroke: url(#gradient);
        stroke-dasharray: 198;
        stroke-dashoffset: 198;
        animation: dash 3s ease-out forwards;
    }

    path.red {
        stroke: #ED5F5F;
        stroke-dasharray: 198;
        stroke-dashoffset: 198;
        animation: dash 3s ease-out forwards;
    }
    
    path.line {
        stroke-dasharray: 0;
        //stroke-dashoffset: 198;
        animation: dash 3s ease-out forwards;
    }
`

interface HalfPieChartItem {
  start: number
  end: number
  color: string
}

export interface HalfPieChartProps {
  items: HalfPieChartItem[]
}

const HalfPieLine = (props: { data: HalfPieChartItem; className?: string }) => {
  const { className = 'line', data: { start, end, color } } = props
  const base = 330

  const delta = end - start
  const strokeDasharray = `${base * (delta / 100)} ${base}`
  const strokeDashoffset = -(start  / 100) * base

  return <path
    fill='none'
    className={className}
    stroke={color}
    d="M10,90 A40,40 0 1,1 220,90"
    style={{
      strokeDasharray,
      strokeDashoffset
    }}
  />
}

export const HalfPieChart = (props: HalfPieChartProps) => {
  const { items } = props

  return <Container>
    <svg viewBox="0 -20 230 120">
      <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="100%">
        <stop offset="0%" stopColor="#56c4fb" />
        <stop offset="100%" stopColor="#707FC2" />
      </linearGradient>
      <HalfPieLine data={{ start: 0, end: 100, color: '#3D3E43' }} />
      {items
        .filter(item => item.end > 0 && (item.end - item.start > 0))
        .map((item, index) => {
          return <HalfPieLine key={item.start + index} data={item} />
        })
      }
    </svg>
  </Container>
}
