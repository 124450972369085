import React from 'react'
import {Anchor, Box, Text} from "grommet";
import {WidgetContainer} from "../widgets";
import {ReactComponent as RhoLogo} from '../../assets/images/rho_logo.svg';

const CountriesList = [
  'Belarus',
  'Cuba',
  'Iran',
  'North Korea',
  'Russia',
  'Syria',
  'Ukraine',
  'United Kingdom',
  'United States of America'
]

export const RestrictedAccessModal = () => {
  return <Box>
    <WidgetContainer style={{ position: 'relative', padding: '24px' }}>
      <Text size={'20px'} weight={500}>Restricted Jurisdiction</Text>
      <Box margin={{ top: '32px' }} gap={'32px'}>
        <Text>
          It appears that you are accessing from a Restricted Jurisdiction.
        </Text>
        <Text>
          Unfortunately, at this moment we are unable to offer services to users in the following Restricted Jurisdictions:
        </Text>
      </Box>
      <Box>
        <ul>
          {CountriesList.map(countryName => {
            return <li key={countryName}>{countryName}</li>
          })}
        </ul>
      </Box>
      <Box>
        <Text>Please refer to our <a href={'https://www.rho.trading/terms'} target={'_blank'}>Terms of Use</a> for additional information.</Text>
      </Box>
    </WidgetContainer>
  </Box>
}
