import React from 'react'
import {WidgetContainer} from "../../../components";
import {TradeProps} from "../common";
import {AdvancedTradingView} from "../../../components";

export const TradingViewChart = (props: TradeProps) => {
  const { future, market } = props

  const timestampFrom = future
    ? future.termStart.toNumber()
    : 0

  return <WidgetContainer style={{ height: 'inherit', padding: '3px' }}>
    {(future && market) &&
        <AdvancedTradingView
            market={market}
            future={future}
            timestampFrom={timestampFrom}
        />
    }
  </WidgetContainer>
}
