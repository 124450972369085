import { Box, Table, TableBody, TableCell, TableHeader, TableRow, Text } from "grommet";
import React from "react";
import styled from "styled-components";
import { LiquidityProvisionProps } from "./index";
import { fromBn } from "evm-bn";
import { BigNumber } from "ethers";
import bn from "bignumber.js";
import {QuestionMark} from "../../components/QuestionMark";
import {Number} from "../../components/Number";
import tooltips from "../../utils/tooltips";

const TableContainer = styled(Table)`
  thead {
    th {
      border: none;
    }
    th .table_column_title {
      color: #858CAD;
      font-weight: 600;
      border: none;
    }
  }
  
  tbody {
    tr:not(.summary-row) {
      cursor: pointer;
      &:hover {
        background-color: #2C2F3F;
      }
    }
    tr.summary-row {
      font-weight: 500;
    }
  }

  th:first-child, td:first-child {
    padding-left: 24px;
  }
`

const formatValueDp = (value: string, decimalPlaces = 1) => bn(value || '0')
  .toFormat(decimalPlaces).toString()

const formatValue = (value: string) => bn(value || '0').toString()

export const CurrentProvision = (props: LiquidityProvisionProps) => {
  const {
    futureId,
    marketPortfolio,
    market: { descriptor: { underlyingDecimals, underlyingName } },
    onChangeFormValue
  } = props

  const items = marketPortfolio
    ? marketPortfolio.futureMakerProvisions.filter((pos) => pos.futureId === futureId)
    : []

  const makerProvisionsFlat = items
    .map(item => item.provisions)
    .flat()
    .filter(item => item.notional.total.gt(BigNumber.from(0)))

  const totalNotional = makerProvisionsFlat.reduce((acc, item) => acc.add(item.notional.total), BigNumber.from(0))
  const totalPayer = makerProvisionsFlat.reduce((acc, item) => acc.add(item.notional.payer), BigNumber.from(0))
  const totalReceiver = makerProvisionsFlat.reduce((acc, item) => acc.add(item.notional.receiver), BigNumber.from(0))

  return <Box border={{ size: '1px', color: 'borderListItem' }} round={'3px'}>
    <Box pad={'18px 20px'}>
      <Text size={'16px'} weight={500} color={'textSecondary'}>Current liquidity provision</Text>
    </Box>
    <Box
      border={{ size: '1px', color: 'borderListItem', side: 'top' }}
      width={'100%'}
      direction={'row'}
      justify={'between'}
    >
      <Box width={'100%'}>
        <TableContainer>
          <TableHeader>
            <TableRow>
              <TableCell scope="col" align={'left'}>
                <Box
                  direction={'row'}
                  align={'center'}
                  gap={'2px'}
                >
                  <Box align={'start'} className={'table_column_title'}>
                    <Text size={'12px'}>RATE</Text>
                    <Text size={'12px'}>RANGE</Text>
                  </Box>
                  <QuestionMark tooltipId={'rate_range_table'} tooltipText={tooltips.rateRange} />
                </Box>
              </TableCell>
              <TableCell scope="col" align={'left'}>
                <Box
                  direction={'row'}
                  align={'center'}
                  gap={'2px'}
                >
                  <Text size={'10px'} color={'textSecondary'}>
                    <Box align={'start'} className={'table_column_title'}>
                      <Text size={'12px'}>TOTAL</Text>
                      <Text size={'12px'}>PROVISION</Text>
                    </Box>
                  </Text>
                  <QuestionMark tooltipId={'total_prov'} tooltipText={tooltips.totalProvision}/>
                </Box>
              </TableCell>
              <TableCell scope="col" align={'right'}>
                <Box
                  direction={'row'}
                  align={'center'}
                  gap={'2px'}
                >
                  <Text size={'10px'} color={'textSecondary'}>
                    <Box align={'start'} className={'table_column_title'}>
                      <Text size={'12px'}>RECEIVER</Text>
                      <Text size={'12px'}>PROVISION</Text>
                    </Box>
                  </Text>
                  <Box>
                    <QuestionMark tooltipId={'receiver_prov'} tooltipText={tooltips.receiverProvision} />
                  </Box>
                </Box>
              </TableCell>
              <TableCell scope="col">
                <Box
                  direction={'row'}
                  align={'center'}
                  gap={'2px'}
                >
                  <Text size={'10px'} color={'textSecondary'}>
                    <Box align={'start'} className={'table_column_title'}>
                      <Text size={'12px'}>PAYER</Text>
                      <Text size={'12px'}>PROVISION</Text>
                    </Box>
                  </Text>
                  <QuestionMark tooltipId={'payer_prov'} tooltipText={tooltips.payerProvision} />
                </Box>
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {makerProvisionsFlat.map((item, index) => {
              const { bounds, notional } = item

              const onRowClick = () => {
                onChangeFormValue({
                  'notional': formatValue(fromBn(notional.total, underlyingDecimals)),
                  'swapRateMin': formatValueDp(fromBn(bounds.lower, 16), 1),
                  'swapRateMax': formatValueDp(fromBn(bounds.upper, 16), 1)
                })
              }

              return <TableRow
                key={index}
                style={{ borderTop: '1px solid #383D57' }}
                onClick={onRowClick}
              >
                <TableCell>
                  <Box direction={'row'} align={'center'} justify={'end'} gap={'4px'}>
                    <Box>
                      {fromBn(bounds.lower, 16)}%
                    </Box>
                    <Box>
                      <Text color={'arrow'} size={'20px'}>-</Text>
                    </Box>
                    <Box>
                      {fromBn(bounds.upper, 16)}%
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Number
                      value={notional.total}
                      decimals={underlyingDecimals}
                      name={underlyingName}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Number
                      value={notional.receiver}
                      decimals={underlyingDecimals}
                      name={underlyingName}
                    />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Number
                      value={notional.payer}
                      decimals={underlyingDecimals}
                      name={underlyingName}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            })}
            <TableRow className={'summary-row'} style={{ borderTop: '1px dashed #383D57' }}>
              <TableCell>
                <Box align={'end'}>
                  <Text>Total</Text>
                </Box>
              </TableCell>
              <TableCell>
                <Number
                  value={totalNotional}
                  decimals={underlyingDecimals}
                  name={underlyingName}
                />
              </TableCell>
              <TableCell>
                <Number
                  value={totalReceiver}
                  decimals={underlyingDecimals}
                  name={underlyingName}
                />
              </TableCell>
              <TableCell>
                <Number
                  value={totalPayer}
                  decimals={underlyingDecimals}
                  name={underlyingName}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </TableContainer>
      </Box>
    </Box>
  </Box>
}
