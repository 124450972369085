import {useParams} from "react-router-dom";
import {useMemo} from "react";
import {FutureInfo, MarketInfo} from "../types";
import {getFutureByAlias} from "../utils";

export interface CurrentFutureData {
  market: MarketInfo
  future: FutureInfo
}

export const useFutureAlias = (
  markets: MarketInfo[]
): CurrentFutureData | undefined => {
  const routeParams = useParams()
  const routeFutureAlias = routeParams.futureAlias || ''

  return useMemo(() => {
    if(routeFutureAlias) {
      return getFutureByAlias(routeFutureAlias, markets)
    }

    const allFutures = markets
      .map(item => item.futures)
      .flat()

    if(allFutures.length > 0) {
      const future = allFutures[0]
      const market = markets.find(market => market.descriptor.id === future.marketId) as MarketInfo
      return {
        market,
        future
      }
    }
  }, [markets.length, routeFutureAlias])
}
