const createCsv = (items: object[]) => {
  const csvRows = [];
  const headers = Object.keys(items[0]);
  csvRows.push(headers.join(','));
  items.forEach(item => {
    const valuesString = Object.values(item).join(',');
    csvRows.push(valuesString)
  })
  return csvRows.join('\n')
}

export const downloadCsv = (items: object[], filename = 'export.csv') => {
  const csvData = createCsv(items)
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', filename);
  a.click()
}
