import {LiquidityDistribution, VAMMParams} from "../../../types";
import {Box} from "grommet";
import { Bar } from 'react-chartjs-2';
import {ChartData, ChartDataset, ChartOptions, TooltipItem} from 'chart.js';
import {BigNumber} from "ethers";
import {fromBn} from "evm-bn";
import {abbreviateNumber} from "../../../utils";

export interface LiquidityDistributionProps {
  data: LiquidityDistribution
  underlyingName: string
  underlyingDecimals: number
  userRatesBounds?: {
    lower: BigNumber
    upper: BigNumber
  }
  vAMMParams?: VAMMParams
  displayLegend?: boolean
}

export const LiquidityDistributionChart = (props: LiquidityDistributionProps) => {
  const {
    data,
    underlyingName,
    underlyingDecimals,
    userRatesBounds,
    vAMMParams,
    displayLegend = true
  } = props

  if(!vAMMParams) {
    return <Box>
      Loading...
    </Box>
  }

  const currentRate = +fromBn(vAMMParams.currentFutureRate, 16)
  const swapRateRounded = vAMMParams.currentFutureRate.sub(vAMMParams.currentFutureRate.mod(vAMMParams.intervalLength))
  const boundsOffset = BigNumber.from(Math.pow(10, 16).toString()).mul(10)

  const intervalLiquidityWithNotional = data.intervalLiquidity.filter(item => item.notional.gt(0))
  // Min and Max bounds from data
  const liquidityBoundsLower = intervalLiquidityWithNotional.length > 0
    ? intervalLiquidityWithNotional[0].bounds.lower
    : null
  const liquidityBoundsUpper = intervalLiquidityWithNotional.length > 0
    ? intervalLiquidityWithNotional[intervalLiquidityWithNotional.length - 1].bounds.upper
    : null

  // Bignumber doesn't support number < 1. The result is a number divided by 10**16.
  const intervalLengthNumber = (+vAMMParams.intervalLength
    .div(BigNumber.from(10).pow(14))
    .toString()) / 100

  let leftBound = liquidityBoundsLower
    ? liquidityBoundsLower
    : swapRateRounded.sub(boundsOffset)
  if(userRatesBounds && leftBound.gte(userRatesBounds.lower)) {
    leftBound = userRatesBounds.lower.sub(vAMMParams.intervalLength)
  }
  let rightBound = liquidityBoundsUpper
    ? liquidityBoundsUpper
    : swapRateRounded.add(boundsOffset)
  if(userRatesBounds && rightBound.lte(userRatesBounds.upper)) {
    rightBound = userRatesBounds.upper.add(vAMMParams.intervalLength)
  }

  let maxNotional = 0
  const items = data.intervalLiquidity
    .filter(item => {
      const { bounds } = item
      return bounds.lower.gt(leftBound) && bounds.lower.lt(rightBound)
    })
    .map((item) => {
      const { bounds } = item
      let notional = item.notional
        .div(BigNumber.from(10).pow(underlyingDecimals))
        .toNumber()

      if(item.notional.gt(0) && notional === 0) {
        notional = +item.notional.toString() / 10**18
      }
      if(notional > maxNotional) {
        maxNotional = notional
      }

      return {
        notional,
        bounds: {
          lower: +bounds.lower.toString() / 10**16,
          upper: +bounds.upper.toString() / 10**16
        }
      }
    })

  const labels = items.map(item => {
    const { bounds: { lower } } = item
    return lower
  })

  const datasetData = items.map(item => {
    const { notional, bounds } = item
    return {
      x: bounds.lower,
      y: notional
    }
  })

  const datasets: ChartDataset<any>[] = [{
    id: 'current_rate',
    type: 'line',
    label: 'Current rate',
    borderDash: [8, 3],
    backgroundColor: '#FFFFFFE5',
    borderColor: '#FFFFFFE5',
    color: '#FFFFFFE5',
    borderWidth: 1,
    pointStyle: 'triangle',
    data: [{
      x: Math.round(currentRate),
      y: 0,
    }, {
      x: Math.round(currentRate),
      y: maxNotional || 1
    }].filter(currentRateItem => {
      return items.find(item =>
        item.bounds.lower === currentRateItem.x
      )
    }),
  }]

  if(userRatesBounds) {
    datasets.push({
      id: 'user_rate_lower',
      type: 'line',
      label: 'User rate lower',
      borderDash: [8, 3],
      backgroundColor: '#5ABF7D',
      borderColor: '#5ABF7D',
      color: '#5ABF7D',
      borderWidth: 1,
      spanGaps: false,
      pointStyle: 'rectRot',
      data: [{
        x: +fromBn(userRatesBounds.lower, 16),
        y: 0
      }, {
        x: +fromBn(userRatesBounds.lower, 16),
        y: maxNotional || 1
      }].filter(rateItem => {
        return items.find(item =>
          item.bounds.lower === rateItem.x
        )
      }),
    }, {
      id: 'user_rate_upper',
      type: 'line',
      label: 'User rate upper',
      borderDash: [8, 3],
      backgroundColor: '#5ABF7D',
      borderColor: '#5ABF7D',
      color: '#5ABF7D',
      borderWidth: 1,
      spanGaps: false,
      pointStyle: 'rectRot',
      data: [{
        x: +fromBn(userRatesBounds.upper, 16),
        y: 0
      }, {
        x: +fromBn(userRatesBounds.upper, 16),
        y: maxNotional || 1
      }].filter(rateItem => {
        return items.find(item =>
          item.bounds.lower === rateItem.x
        )
      }),
    })
  }

  datasets.push({
    id: 'distribution',
    type: 'bar',
    label: 'Provision',
    data: datasetData,
    // backgroundColor: '#177CCF',
    backgroundColor: datasetData.map(item => {
      if(item.x > currentRate) {
        return '#177CCF'
      }
      return '#3FA66E'
    }),
    borderRadius: 2,
    barThickness: 4,
  })

  const chartData: ChartData<any> = {
    labels,
    datasets,
  };

  const chartOptions: ChartOptions<'bar'> = {
    responsive: false,
    animation: {
      duration: 0 // general animation time
    },
    plugins: {
      legend: {
        display: displayLegend,
        position: 'top' as const,
        labels: {
          boxWidth: 0,
          padding: 0,
          color: 'rgba(156, 164, 201, 1)',
          filter: (legendItem, data) => {
            return legendItem.datasetIndex === 0
          },
          generateLabels: (chart) => {
            const [ currentRateDataset ] = chart.data.datasets
            return [{
              datasetIndex: 0,
              text: `${currentRateDataset?.label}: ${(currentRate).toFixed(1)}%`,
              fontColor: '#9CA4C9'
            }]
          }
        }
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title(tooltipItems: TooltipItem<'bar'>[]): string | string[] | void {
            const [{ label: value }] = tooltipItems
            const nextValue = Math.round((+value * 100 + intervalLengthNumber * 100)) / 100
            return `${value} — ${nextValue} %`
          },
          label(tooltipItem: TooltipItem<'bar'>): string | string[] | void {
            const { label, formattedValue, dataset } = tooltipItem
            // @ts-ignore
            if(['user_rate_lower', 'user_rate_upper', 'current_rate'].includes(dataset.id)) {
              return `${dataset.label}: ${label} ${underlyingName}`
            }
            return `${dataset.label}: ${formattedValue} ${underlyingName}`
          }
        }
      }
    },
    scales: {
      x: {
        // grid:{
        //   color: '#5ABF7D',
        // },
        border: {
          dash: [2, 4]
        },
        ticks: {
          color: '#9CA4C9',
          callback: (value, index, ticks) => {
            if(
              index === 0
              || index === ticks.length - 1
              || index === Math.round(ticks.length / 2) - 1
            ) {
              return `${datasetData[index].x}%`
            }
          }
        }
      },
      y: {
        grid:{
          color: '#383D57',
        },
        border: {
          dash: [2, 4]
        },
        ticks: {
          color: '#9CA4C9',
          callback: (value, index, ticks) => {
            if(
              index === 0
              || index === ticks.length - 1
              || index === Math.round(ticks.length / 2 - 1)
            ) {
              return abbreviateNumber(value)
            }
          }
        }
      }
    }
  };

  return <Bar
    options={chartOptions}
    data={chartData}
  />
}
