import React, {useEffect, useState} from 'react'
import {Box, Spinner} from "grommet";
import {useAccount, useBalance, useWaitForTransaction} from "wagmi";
import {Decimal} from "decimal.js";
import useDebounce from "../../hooks/useDebounce";
import {IRateMathType, RiskDirection, RiskDirectionType, TradeQuote} from "../../types";
import {BigNumber} from "ethers";
import config, {configMode} from "../../config";
import {getAllowance, getBalanceOf} from "../../api/erc20Contract";
import {toast} from "react-toastify";
import {
  bnToDecimal,
  getDirectionAlias,
  getFutureAlias,
  getFutureByAlias,
  getOraclePackage,
  prepareFormNumber
} from "../../utils";
import {getTradeQuote} from "../../api/quoterContract";
import {defaultValuesState, FormValuesState, TradeParams, TradeProps} from "./common";
import {getFormError} from "./validator";
import {useMediaQuery} from "react-responsive";
import {IPadProView} from "./responsive/iPadPro";
import {DesktopView} from "./responsive/desktop";
import {useNavigate, useParams} from "react-router-dom";
import {CompoundingRateMath, LinearRateMath} from "../../utils/leverage";
import {marginTotal} from "../../utils/mappers";
import {fromBn} from "evm-bn";
import {getLastUsedMarketAlias} from "../../utils/localstorage";
import {tradeSuccessNotification} from "../../components/notifications";
import {useProtocolData} from "../../providers/ProtocolDataProvider";
import {breakpoints} from "../../utils/breakpoints";
import {MobileView} from "./responsive/mobile";
import {useActiveModal} from "../../providers/ModalsProvider";
import {useTokenPrice} from "../../providers/PriceProvider";

const defaultLeverageRange = { min: -100, max: 100 }

export const Trade = () => {
  const isMobile = useMediaQuery({ query: `(max-width: ${breakpoints.mobile})` })
  const isIpadPro = useMediaQuery({ query: `(max-width: ${breakpoints.ipad})` })
  const navigate = useNavigate()
  const { address: userAddress } = useAccount()
  const params = useParams()
  const paramsFutureAlias = params.futureAlias || ''

  const { setActiveModal } = useActiveModal()
  const { ethereum: ethPrice } = useTokenPrice()

  const {
    markets,
    portfolio: portfolios,
    refetchPortfolio,
    history,
    tradeLimiterParams
  } = useProtocolData()

  const [formValues, setValues] = useState<FormValuesState>(defaultValuesState)
  const debouncedNotional = useDebounce(formValues.notional, 250)
  const [executeTxHash, setExecuteTxHash] = useState('')
  const [approveTxHash, setApproveTxHash] = useState('')
  const [txError, setTxError] = useState<Error | null>(null)
  const [modalId, setModalId] = useState('')

  const [marketId, setMarketId] = useState('')
  const [futureId, setFutureId] = useState('')
  const [isQuoteSwapFetching, setQuoteFetching] = useState(false)
  const [allowance, setAllowance] = useState<BigNumber>(BigNumber.from(0))
  const [underlyingBalance, setUnderlyingBalance] = useState<BigNumber>(BigNumber.from(0))
  const [tradeQuote, setTradeQuote] = useState<TradeQuote | null>(null)
  const [quoteError, setQuoteError] = useState<Error | null>(null)

  const [leverage, setLeverage] = useState(0)
  const [leverageRange, setLeverageRange] = useState(defaultLeverageRange)

  const portfolio = portfolios.find(p => p.descriptor.id === marketId)
  const market = markets.find((item) => item.descriptor.id === marketId)
  const future = market ? market.futures.find(future => future.id === futureId) : undefined
  const underlyingDecimals = market ? market.descriptor.underlyingDecimals : 18
  const underlying = market ? market.descriptor.underlying : ''
  const underlyingName = market ? market.descriptor.underlyingName : ''

  const { data: nativeTokenBalanceData } = useBalance({
    address: userAddress,
    enabled: !!userAddress,
    watch: true,
    chainId: config.chainId,
  })

  const { data: underlyingTokenBalance } = useBalance({
    address: userAddress,
    token: underlying as `0x${string}`,
    enabled: !!userAddress && !!underlying,
    watch: true,
    chainId: config.chainId,
  })

  const {
    data: executeTradeReceipt,
    error: executeTradeError,
    isSuccess: isExecuteSwapSuccess,
    isError: isExecuteTradeError,
    isLoading: isExecuteTradeLoading
  } = useWaitForTransaction({
    hash: executeTxHash as `0x${string}`,
    confirmations: config.txConfirmations
  })

  const {
    data: approveTxReceipt,
    isLoading: isApproveTxLoading,
    isSuccess: isApproveSuccess
  } = useWaitForTransaction({
    hash: approveTxHash as `0x${string}`,
    confirmations: config.txConfirmations
  })

  const updateLeverageRange = () => {
    if(!market) {
      return
    }

    const rateMath = market.descriptor.rateMathType === IRateMathType.LINEAR
      ? new LinearRateMath()
      : new CompoundingRateMath()

    const totalMargin = portfolio ? marginTotal(portfolio.marginState.margin) : BigNumber.from(0)
    const openPosition = portfolio ? portfolio.futureOpenPositions.find(pos => pos.futureId === futureId) : undefined
    const openPositionNotional = openPosition ? openPosition.notional : BigNumber.from(0)
    const openPositionNotionalDecimal = bnToDecimal(openPositionNotional,  market.descriptor.underlyingDecimals)
    const currentRate = future
      ? new Decimal(bnToDecimal(future.vAMMParams.currentFutureRate, 18))
      : new Decimal(0)
    const delta = new Decimal(bnToDecimal(market.riskParameters.marginThresholdDelta, 18))
    const marginRequirementSecondsFloor = market.riskParameters.marginRequirementSecondsFloor

    const secondsToMaturity = future
      ? future.termStart.add(future.termLength).toNumber() - Math.round(Date.now() / 1000)
      : 0

    const shortMaxLeverageDecimal = rateMath.calcFutureMaxLeverage(
        currentRate,
        delta,
        secondsToMaturity,
        marginRequirementSecondsFloor,
        RiskDirectionType.RECEIVER
    )

    const longMaxLeverageDecimal = rateMath.calcFutureMaxLeverage(
        currentRate,
        delta,
        secondsToMaturity,
        marginRequirementSecondsFloor,
        RiskDirectionType.RECEIVER
    )

    let currentLeverage = 0
    let leftValue = shortMaxLeverageDecimal * -1
    let rightValue = longMaxLeverageDecimal

    if(openPosition && openPositionNotional.gt(0)) {
      currentLeverage = +openPositionNotionalDecimal.div(bnToDecimal(totalMargin, underlyingDecimals)).toNumber()
      if(openPosition.riskDirection === RiskDirectionType.RECEIVER) {
        currentLeverage = currentLeverage * -1
      }
    }

    // When the current leverage goes out of bounds, we need to expand the bounds so that the user can reduce the
    // position
    leftValue = Math.min(leftValue, currentLeverage)
    rightValue = Math.max(rightValue, currentLeverage)

    setLeverageRange({
      min: leftValue,
      max: rightValue
    })

    setLeverage(currentLeverage)
  }

  useEffect(() => {
    setLeverage(0)
    setLeverageRange(defaultLeverageRange)
    setValues(defaultValuesState)
    setTradeQuote(null)
  }, [futureId]);

  useEffect(() => {
    if(market?.descriptor.id) {
      updateLeverageRange()
    }
  }, [market?.descriptor.id, futureId, portfolio?.descriptor.id, underlyingTokenBalance?.value]);

  useEffect(() => {
    const totalMargin = portfolio
      ? marginTotal(portfolio.marginState.margin)
      : BigNumber.from(0)
    const openPosition = portfolio ? portfolio.futureOpenPositions.find(pos => pos.futureId === futureId) : undefined
    let openPositionNotional = openPosition ? openPosition.notional : BigNumber.from(0)
    if(openPosition && openPosition.riskDirection === RiskDirectionType.RECEIVER) {
      openPositionNotional = openPositionNotional.mul(-1)
    }
    let formNotional = +formValues.notional
    if(formValues.riskDirection === RiskDirection.receiver) {
      formNotional = formNotional * -1
    }

    let resultPositionNotional = (bnToDecimal(openPositionNotional, underlyingDecimals)
      .add(formNotional))

    const newLeverage = totalMargin.gt(0) ? resultPositionNotional
        .div(bnToDecimal(totalMargin, underlyingDecimals))
      : new Decimal(0)

    setLeverage(+newLeverage)
  }, [formValues.riskDirection, formValues.notional]);

  // Set initial selected market and future
  useEffect(() => {
    const setSelectedMarket = () => {
      try {
        const lastUsedAlias = getLastUsedMarketAlias()
        if(paramsFutureAlias) {
          console.log('Found market alias from params:', paramsFutureAlias)
          const data = getFutureByAlias(paramsFutureAlias, markets)
          if(data) {
            console.log(`Found by alias futureId: ${data.future.id}, marketId: ${data.market.descriptor.id}`)
            setMarketId(data.market.descriptor.id)
            setFutureId(data.future.id)
            return
          }
        } else if(lastUsedAlias) {
          console.log('Found market alias from localStorage:', lastUsedAlias)
          const data = getFutureByAlias(lastUsedAlias, markets)
          if(data) {
            setMarketId(data.market.descriptor.id)
            setFutureId(data.future.id)
            navigate(`/trade/${lastUsedAlias}?network=${configMode}`)
            return
          }
        }
        const [market] = markets
        if(market) {
          setMarketId(market.descriptor.id)
          if(market.futures.length > 0) {
            setFutureId(market.futures[0].id)
            const futureAlias = getFutureAlias(market, market.futures[0])
            if(futureAlias) {
              navigate(`/trade/${futureAlias}?network=${configMode}`)
            }
          }
        }
      } catch (e) {

      }
    }

    setSelectedMarket()
  }, [paramsFutureAlias, markets.length]);

  useEffect(() => {
    const loadData = async () => {
      try {
        if(underlying && userAddress) {
          const allowanceData = await getAllowance(underlying, userAddress)
          const balanceData = await getBalanceOf(underlying, userAddress)
          console.log('Allowance:', allowanceData.toString())
          console.log('Balance:', balanceData.toString())
          setAllowance(allowanceData)
          setUnderlyingBalance(balanceData)
        }
      } catch (e) {
        console.error(`Cannot load ERC20 data:`, e)
      }
    }
    loadData()
  }, [underlying, userAddress, isApproveSuccess, isExecuteSwapSuccess]);

  useEffect(() => {
    refetchPortfolio().then(() => {
      // We cannot immediately reset the form notional, as this will cause a change in leverage with the old portfolio.
      // Therefore, we reset the form fields only after updating the portfolio.
      const nextState = {
        ...defaultValuesState,
        riskDirection: formValues.riskDirection,
        isMaxRateLimitAuto: formValues.isMaxRateLimitAuto
      }
      setValues(nextState)
    })

    if(isExecuteSwapSuccess && executeTradeReceipt) {
      const tx = history.find(tx => tx.transactionHash === executeTradeReceipt.transactionHash)
      const market = markets.find(m => m.descriptor.id === tx?.marketId)
      if(tx) {
        const text = `${getDirectionAlias(tx.direction)} ${tx.amount} ${tx.underlyingName} of ${market?.descriptor.sourceName} ${market?.descriptor.instrumentName} ${tx.rate}%`
        tradeSuccessNotification({
          transactionHash: executeTradeReceipt.transactionHash,
          text
        })
      }
    }
  }, [isExecuteSwapSuccess]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setTradeQuote(null)
        setQuoteFetching(true)
        const notional = prepareFormNumber(debouncedNotional, underlyingDecimals)
        if(marketId && userAddress && notional.gt(0)) {
          const oracleData = await getOraclePackage(marketId)
          const swapQuoteData = await getTradeQuote(futureId, notional, userAddress, [oracleData])
          console.log('tradeQuote:', swapQuoteData)
          setTradeQuote(swapQuoteData)
          setQuoteError(null)
        }
      } catch (e) {
        console.error(`Trade quote error:`, e)
        setQuoteError(e as Error)
      } finally {
        setQuoteFetching(false)
      }
    }
    loadData()
  }, [userAddress, debouncedNotional]);

  useEffect(() => {
    if(isExecuteSwapSuccess && executeTradeReceipt) {
      const activeQuote = formValues.riskDirection === RiskDirection.receiver ? tradeQuote?.receiverQuote : tradeQuote?.payerQuote
      const tradeRate = activeQuote ? `${(+fromBn(activeQuote?.tradeInfo.tradeRate, 16)).toFixed(2)}%` : ''
      const text = `${getDirectionAlias(formValues.riskDirection)} ${formValues.notional} ${underlyingName} of ${market?.descriptor.sourceName} ${market?.descriptor.instrumentName} ${tradeRate}`
      tradeSuccessNotification({ text, transactionHash: executeTradeReceipt.transactionHash })
    }
  }, [isExecuteSwapSuccess, executeTradeReceipt, tradeQuote])

  useEffect(() => {
    if(executeTradeReceipt) {
      setExecuteTxHash('')
    }
    if(approveTxReceipt) {
      setApproveTxHash('')
    }
  }, [userAddress, approveTxReceipt, executeTradeReceipt])

  useEffect(() => {
    setTxError(null)
  }, [formValues.notional, formValues.collateral])

  useEffect(() => {
    if(isExecuteTradeError && executeTradeError) {
      // TODO: add to Sentry
      toast.error(<Box>
        Execute trade error
      </Box>)
    }
  }, [isExecuteTradeError, executeTradeError]);

  const nativeTokenBalance = nativeTokenBalanceData
    ? BigNumber.from(nativeTokenBalanceData?.value.toString())
    : BigNumber.from(0)

  const onChangeFormValue = (newValues: Record<string, string | boolean | number | RiskDirection>) => {
    setValues((currentValues) => {
      return {
        ...currentValues,
        ...newValues
      }
    })
    setExecuteTxHash('')
  }

  const tradeParams: TradeParams = {
    futureId, marketId,
    markets, market, future,
    formValues,
    isQuoteSwapFetching,
    tradeQuote,
    quoteError,
    portfolio,
    allowance,
    nativeTokenBalance,
    underlying,
    underlyingBalance,
    underlyingDecimals,
    underlyingName,
    isApproveTxLoading,
    isExecuteTradeLoading,
    approveTxReceipt,
    executeTradeReceipt,
    modalId,
    leverage,
    leverageRange,
    setModalId,
    onSelectFuture: (marketId, futureId) => {},
    setExecuteTxHash,
    setApproveTxHash,
    setLeverage
  }
  let formError = getFormError(tradeParams, tradeLimiterParams, { ethPrice })

  const formProps: TradeProps = {
    ...tradeParams,
    formError,
    txError,
    onChangeFormValue
  }

  if(!market) {
    return <Box height={'200px'} align={'center'} justify={'center'}>
      <Spinner color={'spinner'} size={'small'} />
    </Box>
  }

  let content = null

  if(isMobile) {
    content = <MobileView {...formProps} />
  } else if(isIpadPro) {
    content = <IPadProView {...formProps} />
  } else {
    content = <DesktopView {...formProps} />
  }

  return content
}
