import {Box, Table, TableBody, TableCell, TableRow, Text} from "grommet";
import {prepareFormNumber} from "../../utils";
import React from "react";
import {LiquidityProvisionProps} from "./index";
import {BigNumber} from "ethers";
import {ValuesChangeBadge} from "../../components";
import {marginTotal} from "../../utils/mappers";
import {QuestionMark} from "../../components/QuestionMark";
import {CoinName} from "../../components/Coin";
import TreeView from "../../components/TreeView";
import tooltips from "../../utils/tooltips";

interface UpdateTableRowProps {
  from: BigNumber
  to: BigNumber | null
  fromDv01?: BigNumber
  toDv01?: BigNumber | null
  underlyingName: string
  underlyingDecimals: number
  height?: string
}

const UpdateTableRow = (props: UpdateTableRowProps) => {
  const {
    from,
    to,
    underlyingName,
    underlyingDecimals,
    height = '53px',
  } = props

  return <TableRow>
    <TableCell pad={'0x'} height={height}>
      <Box direction={'row'} justify={'end'} align={'center'} gap={'8px'}>
        <ValuesChangeBadge
          from={from}
          to={to}
          decimals={underlyingDecimals}
          name={underlyingName}
        />
        <CoinName name={underlyingName} />
      </Box>
    </TableCell>
  </TableRow>
}

export const Margin = (props: LiquidityProvisionProps) => {
  const { futureId, market, marketPortfolio, formValues, liquidityQuote } = props
  const { underlyingName, underlyingDecimals } = market.descriptor

  const tableRowProps = {
    underlyingName,
    underlyingDecimals
  }

  const deposit = prepareFormNumber(formValues.collateral, underlyingDecimals)

  const makerProvisionsFlat = marketPortfolio
      ? marketPortfolio.futureMakerProvisions
          .map(item => item.provisions)
          .flat()
          .filter(item => item.notional.total.gt(BigNumber.from(0)))
      : []

  let totalMarginRequiredFrom = marketPortfolio ? marketPortfolio.marginState.lpMarginThreshold : BigNumber.from(0)
  if(makerProvisionsFlat.length === 0) {
    totalMarginRequiredFrom = marketPortfolio ? marketPortfolio.marginState.initialMarginThreshold : BigNumber.from(0)
  }
  const totalMarginRequiredTo = liquidityQuote ? liquidityQuote.newMarginThreshold : null

  let dueToLiquidityProvisionFrom = marketPortfolio
    ? marketPortfolio.marginState.lpMarginThreshold.sub(marketPortfolio.marginState.initialMarginThreshold)
    : BigNumber.from(0)
  if(makerProvisionsFlat.length === 0) {
    dueToLiquidityProvisionFrom = BigNumber.from(0)
  }
  const dueToLiquidityProvisionTo = (liquidityQuote && marketPortfolio)
    ? liquidityQuote.newMarginThreshold.sub(marketPortfolio.marginState.initialMarginThreshold)
    : null

  return <Box border={{ size: '1px', color: 'borderListItem' }} round={'3px'}>
    <Box pad={'18px 20px'}>
      <Text size={'16px'} weight={500} color={'textSecondary'}>Market cross-margin</Text>
    </Box>
    <Box
      border={{ size: '1px', color: 'borderListItem', side: 'top' }}
      pad={'22px 22px 0'}
      width={'100%'}
    >
      <Box
        direction={'row'}
        pad={{ bottom: '18px' }}
        border={{ size: '1px', color: 'borderListItem', side: 'bottom', style: 'dashed' }}
      >
        <Box width={'50%'} direction={'row'} align={'center'} gap={'4px'}>
          <Text weight={500} color={'textTitle'}>Current margin</Text>
          <QuestionMark tooltipId={'current_margin'} tooltipText={tooltips.currentMargin} />
        </Box>
        <Box width={'50%'} direction={'row'} justify={'end'} align={'center'} gap={'8px'}>
          <ValuesChangeBadge
            from={marketPortfolio ? marginTotal(marketPortfolio?.marginState.margin) : BigNumber.from(0)}
            to={marketPortfolio ? marginTotal(marketPortfolio.marginState?.margin).add(deposit) : BigNumber.from(0)}
            decimals={underlyingDecimals}
            name={underlyingName}
          />
          <CoinName name={underlyingName} />
        </Box>
      </Box>
      <Box direction={'row'} margin={{ top: '22px' }}>
        <Box width={'50%'}>
          <TreeView
            title={{
              text: 'Total margin required',
              tooltipText: tooltips.totalMarginRequired
          }}
            items={[{
              text: 'Due to open position',
              tooltipText: tooltips.dueToOpenPosition
            }, {
              text: 'Due to liquidity provision',
              tooltipText: tooltips.dueToLiquidityProvision
            }]}
          />
        </Box>
        <Box width={'50%'}>
          <Table style={{ width: '100%' }}>
            <TableBody>
              <UpdateTableRow
                {...tableRowProps}
                from={totalMarginRequiredFrom}
                to={totalMarginRequiredTo}
              />
              <UpdateTableRow
                {...tableRowProps}
                from={marketPortfolio ? marketPortfolio.marginState.initialMarginThreshold : BigNumber.from(0)}
                to={marketPortfolio ? marketPortfolio.marginState.initialMarginThreshold : null}
              />
              <UpdateTableRow
                {...tableRowProps}
                from={dueToLiquidityProvisionFrom}
                to={dueToLiquidityProvisionTo}
              />
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Box>
  </Box>
}
