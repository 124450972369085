import {useCallback, useEffect, useMemo, useState} from "react";
import {useProtocolData} from "../providers/ProtocolDataProvider";
import {getTradesInfo, Trade} from "../api/dataService";
import {BigNumber} from "ethers";

const zero = BigNumber.from(0)

export const useCurrentRate = (futureId?: string) => {
  const { markets } = useProtocolData()

  const [lastTrade, setLastTrade] = useState<Trade>()
  const [isLastTradeLoaded, setLastTradeLoaded] = useState(false)
  const [currentRate, setCurrentRate] = useState(zero)
  const [rateDelta, setRateDelta] = useState(zero)

  useEffect(() => {
    const loadData = async () => {
      try {
        if(futureId) {
          const [lastTrade] = await getTradesInfo({ futureId })
          if(lastTrade) {
            setLastTrade(lastTrade)
          }
        }
      } catch (e) {
        console.error('useCurrentRate: failed to get trades from backend', e)
      } finally {
        setLastTradeLoaded(true)
      }
    }
    loadData()
  }, [futureId])

  useEffect(() => {
    if(isLastTradeLoaded) {
      const market = markets.find((item) => item.futures.find(future => future.id === futureId))
      const future = market ? market.futures.find(future => future.id === futureId) : undefined

      if(future) {
        setCurrentRate(future.vAMMParams.currentFutureRate)
      }

      let nextDelta

      // New future rate comes from the Protocol
      if(future && !currentRate.eq(0) && !currentRate.eq(future.vAMMParams.currentFutureRate)) {
        nextDelta = BigNumber.from(future.vAMMParams.currentFutureRate).sub(currentRate)
        // console.log('Next delta: from PR', nextDelta.toString())
      } else if(future && lastTrade && lastTrade.marketRateBefore && lastTrade.marketRateAfter) {
        const futureRate = future.vAMMParams.currentFutureRate
        const tradeRateBefore = BigNumber.from(lastTrade.marketRateBefore)
        const tradeRateAfter = BigNumber.from(lastTrade.marketRateAfter)
        if(futureRate.eq(tradeRateAfter)) {
          nextDelta = BigNumber.from(tradeRateAfter).sub(BigNumber.from(tradeRateBefore))
        } else {
          nextDelta = BigNumber.from(futureRate).sub(BigNumber.from(tradeRateAfter))
        }
        // console.log('Next delta: from DS', nextDelta.toString())
      }

      if(nextDelta) {
        setRateDelta(nextDelta)
      }
    }
  }, [markets, lastTrade, isLastTradeLoaded])

  return useMemo(() => {
    return {
      currentRate,
      rateDelta
    }
  }, [currentRate])
}
